import { useCallback, useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { Box, Button, Typography } from "sensis-material-ui";
import {
  Checkbox,
  CircularProgress,
  Fade,
  FormControlLabel,
  InputAdornment,
  FormControl,
  RadioGroup,
  Radio,
  TextField as TextFieldMUI,
  Link as MuiLink,
} from "@material-ui/core";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import {
  Alert,
  AlertTitle,
  Autocomplete as AutocompleteMUI,
} from "@material-ui/lab";
import ValidIcon from "@material-ui/icons/CheckCircle";
import InvalidIcon from "@material-ui/icons/Error";
import { withRouter, useHistory } from "react-router-dom";
import { apiWithLoader } from "react-common-api";
import ReCAPTCHA from "react-google-recaptcha";
import { v4 as uuidv4 } from "uuid";
import { matchSorter } from "match-sorter";
import debounce from "lodash.debounce";
import MainContent from "./MainContent";
import FormContainer from "./FormContainer";
import payload from "../../data/payload";
import { dtmSatelliteTrack } from "./AdobeDTMUtils";
import { ADDRESS_STREET_TYPES } from "../../constants";

const ERROR_MESSAGES = {
  abn: {
    minlength: "The ABN you entered is the wrong length. It must be 11 digits.",
    invalid:
      "The ABN you entered is not valid according to the ABN registry. Please check again.",
    exists:
      "This ABN already exists in our system. Please try again or call us on 132 489.",
  },
  businessName: {
    required:
      "The Business name is mandatory. Please fill in the legal trading name of your business.",
  },
  businessCategory: {
    required: "Start typing and then select your category",
    unknown: "Hmmm, sorry we don't have that in our list, please try again.",
  },
  businessAddress: {
    required: "Enter your business address. Minimum 3 characters.",
    invalid: "Please enter a valid address.",
  },
  businessPhone: {
    required:
      "The Business phone number is mandatory. Please fill in the phone number of your business.",
    minlength: "Use one of the following formats - 02 2324 5678, 04 13864567",
    inActive:
      "The phone number does not seem to be active. Please enter a valid phone number.",
    exists:
      "This phone number already exists in our system. Please try again or call us on 132 489.",
  },
  businessEmail: {
    invalid: "Please enter a valid email address",
    exists:
      "This email already exists in our system. Please try again or call us on 132 489.",
    required: "Email is mandatory",
  },

  website: {
    invalid: "Please enter a valid website address",
    invalidDomain:
      "It looks like this web address doesn't exist. Please try again.",
  },
  firstName: {
    required: "First name is mandatory",
    invalid: "Special characters are not permitted, please amend.",
  },
  lastName: {
    required: "Last name is mandatory",
    invalid: "Special characters are not permitted, please amend.",
  },
  contactPhone: {
    required:
      "The contact phone number is mandatory. Please fill in the contact phone number of your business.",
    minlength:
      "Please enter a valid phone number. Standard landline and 13 numbers require an area code.",
    inActive:
      "The contact phone number does not seem to be active. Please enter a valid contact phone number.",
    exists:
      "This phone number already exists in our system. Please try again or call us on 132 489.",
    virtual:
      "Premium 190 numbers are not accepted during sign up. Please try another valid contact number.",
  },
  contactEmail: {
    invalid: "Please enter a valid email address",
    exists:
      "This email already exists in our system. Please try again or call us on 132 489.",
    required: "Email is mandatory",
  },
};

const DEFAULT_CATEGORY_STATE = {
  id: "",
  name: "",
  count: "",
};

const StyledTextField = withStyles({
  root: {
    border: "transparent",
    "& .MuiInputBase-root": {
      paddingRight: 0,

      "& .MuiInputAdornment-root": {
        right: 12,
        position: "absolute",
      },
      "& .MuiCircularProgress-root": {
        right: 15,
        position: "absolute",
      },
    },
  },
})(TextFieldMUI);

const StyledCheckbox = withStyles({
  disabled: {
    border: "none !important",
  },
})(Checkbox);

const TextField = ({
  type,
  label,
  value,
  name,
  onBlur,
  onChange,
  onFocus,
  helperText,
  errorText,
  maxLength,
  icon: Icon,
  iconColor,
  showIcon,
  loading,
}) => {
  return (
    <Box p={2}>
      <StyledTextField
        type={type}
        error={!!errorText}
        fullWidth
        variant="outlined"
        label={label}
        name={name}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        helperText={errorText || helperText}
        InputProps={{
          inputProps: { maxLength },
          ...(loading && {
            endAdornment: <CircularProgress color="inherit" size={20} />,
          }),
          ...(!loading &&
            value &&
            Icon &&
            showIcon && {
              endAdornment: (
                <InputAdornment>
                  <Icon style={{ color: iconColor }} />
                </InputAdornment>
              ),
            }),
        }}
      />
    </Box>
  );
};

const StyledAlert = withStyles({
  action: {
    alignItems: "flex-start",
  },
})(Alert);

const Autocomplete = ({
  id,
  autoComplete,
  value,
  options,
  optionLabelProperty,
  getOptionSelected,
  open,
  onOpen,
  onClose,
  onBlur,
  onFocus,
  freeSolo = true,
  blurOnSelect = false,
  disableClearable = false,
  filterOptions,
  loading,
  inputLoading,
  label,
  name,
  inputValue,
  onKeyUp,
  onChange,
  onInputChange,
  maxLength,
  errorText,
  helperText,
  displayLoader = false,
  inputAutoComplete,
  icon: Icon,
  iconColor,
  showIcon,
}) => {
  return (
    <AutocompleteMUI
      id={id}
      autoComplete={autoComplete}
      value={value}
      options={options}
      getOptionLabel={(option) => option[optionLabelProperty]}
      getOptionSelected={getOptionSelected}
      freeSolo={freeSolo}
      blurOnSelect={blurOnSelect}
      disableClearable={disableClearable}
      filterOptions={filterOptions}
      loading={loading}
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      inputValue={inputValue}
      onChange={onChange}
      onInputChange={onInputChange}
      renderInput={(params) => (
        <StyledTextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          error={!!errorText}
          onKeyUp={onKeyUp}
          onBlur={onBlur}
          onFocus={onFocus}
          variant="outlined"
          label={label}
          name={name}
          maxLength={maxLength}
          helperText={errorText || helperText}
          autoComplete={inputAutoComplete}
          InputProps={{
            ...params.InputProps,
            ...((loading || inputLoading) &&
              displayLoader && {
                endAdornment: <CircularProgress color="inherit" size={20} />,
              }),
            ...(!inputLoading &&
              inputValue &&
              Icon &&
              showIcon && {
                endAdornment: (
                  <InputAdornment>
                    <Icon style={{ color: iconColor }} />
                  </InputAdornment>
                ),
              }),
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{
            ...params.inputProps,
            maxLength,
          }}
        />
      )}
    />
  );
};

const thryvTerms = () => {
  return (
    <>
      {`${"I agree to the"} `}
      <MuiLink
        underline="always"
        color="textPrimary"
        href="https://corporate.thryv.com.au/terms-and-conditions"
        target="_blank"
      >
        Thryv Australia Customer Terms.
      </MuiLink>
    </>
  );
};

const optInText = () => {
  return (
    <>
      {`${"By entering my contact information, I consent to receiving telephone calls, text messages, and/or electronic promotional and marketing messages from or on behalf of Thryv about its products and services. I understand consent is not required to purchase goods or services and I may withdraw my consent at any time by contacting Thryv at "} `}
      <MuiLink
        underline="always"
        color="textPrimary"
        rel="noopener noreferrer"
        href="mailto:marketing@thryv.com"
      >
        marketing@thryv.com
      </MuiLink>
      {`${", or at Thryv, Locked Bag 2910, Melbourne, Vic, 3001. I further understand that I can opt out of receiving email marketing directly on Thryv’s "}`}
      <MuiLink
        underline="always"
        color="textPrimary"
        href="https://e.yellow.com.au/global_unsubscribe"
        target="_blank"
      >
        unsubscribe page
      </MuiLink>
      {`${" and can opt out of receiving text messages by replying “STOP.”For more information on how Thryv handles your personal information, please see our "}`}
      <MuiLink
        underline="always"
        color="textPrimary"
        href="https://www.thryv.com/privacy/"
        target="_blank"
      >
        privacy policy
      </MuiLink>
      .
    </>
  );
};

const VirtualBusinessPhoneErrorText = () => (
  <>
    {`Whoops, Free Ads doesn't support 13, 1300, 1800 or 1900 numbers. 
      Please contact our sales team to learn more about listing your number. `}
    <MuiLink
      underline="always"
      color="textPrimary"
      href="https://www.yellow.com.au/contact"
      target="_blank"
      rel="noopener noreferrer"
    >
      Contact us online
    </MuiLink>
    {` or talk to us on 1800 006 910.`}
  </>
);

const OnlineSignup = () => {
  const { grecaptchaBadge, submitButtonHoverDisabled } = makeStyles(() => ({
    grecaptchaBadge: {
      visibility: "hidden",
      position: "absolute",
    },
    submitButtonHoverDisabled: {
      "&:hover": {
        backgroundColor: "#97A3AA !important",
      },
    },
  }))();
  const history = useHistory();
  const [isSubmitError, setIsSubmitError] = useState(false);
  const config = useSelector((state) => state.config);
  const [businessAddressOptions, setBusinessAddressOptions] = useState([]);
  const [contactAddressOptions, setContactAddressOptions] = useState([]);
  const [businessCategoryOptions, setBusinessCategoryOptions] = useState([]);
  const [businessNameOptions, setBusinessNameOptions] = useState([]);
  const [open, setOpen] = useState({
    businessAddress: false,
    contactAddress: false,
    businessCategory: false,
    businessName: false,
  });
  const [loading, setLoading] = useState({
    businessAddress: false,
    contactAddress: false,
    businessName: false,
  });

  const [selectedContactAddress, setSelectedContactAddress] = useState(null);
  const [selectedAddressType, setSelectedAddressType] = useState("fullAddress");
  const [selectedBusinessCategory, setSelectedBusinessCategory] = useState(
    DEFAULT_CATEGORY_STATE
  );
  const [selectedBusinessName, setSelectedBusinessName] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [selectedBussinessAddress, setSelectedBussinessAddress] = useState({});

  const [selectedAbn, setSelectedAbn] = useState(null);

  const [inputs, setInputs] = useState({
    abn: "",
    businessName: "",
    businessCategory: "",
    businessAddress: "",
    businessPhone: "",
    businessEmail: "",
    contactAddress: "",
    contactPhone: "",
    contactEmail: "",
    firstName: "",
    lastName: "",
    website: "",
  });

  const [trackInputs, setTrackInputs] = useState({
    abn: "",
    businessName: "",
    businessCategory: "",
    businessAddress: "",
    businessPhone: "",
    businessEmail: "",
    contactAddress: "",
    contactPhone: "",
    contactEmail: "",
    firstName: "",
    lastName: "",
    website: "",
  });

  const [errors, setErrors] = useState({
    abn: null,
    businessName: null,
    businessCategory: null,
    businessAddress: null,
    businessPhone: null,
    businessEmail: null,
    contactAddress: null,
    contactPhone: null,
    contactEmail: null,
    firstName: null,
    lastName: null,
    website: null,
  });

  const [isCheck, setIsCheck] = useState({
    accountDetails: true,
    terms: false,
    addWebsite: false,
    optIn: false,
  });

  const [abnValidation, setAbnValidation] = useState({
    isAbnValid: false,
    isAbnFieldFocused: false,
    validatingAbn: false,
  });

  const [phoneValidation, setPhoneValidation] = useState({
    isBusinessPhoneValid: false,
    isBusinessPhoneFieldFocused: false,
    validatingBusinessPhone: false,
    isContactPhoneValid: false,
    isContactPhoneFieldFocused: false,
    validatingContactPhone: false,
  });

  const [emailValidation, setEmailValidation] = useState({
    isBusinessEmailValid: false,
    isBusinessEmailFieldFocused: false,
    validatingBusinessEmail: false,
    isContactEmailValid: false,
    isContactEmailFieldFocused: false,
    validatingContactEmail: false,
  });

  const [websiteValidation, setWebsiteValidation] = useState({
    isWebsiteValid: false,
    isWebsiteFieldFocused: false,
    validatingWebsite: false,
  });

  const [businessNameValidation, setBusinessNameValidation] = useState({
    isBusinessNameValid: false,
    validatingBusinessName: false,
  });

  const [detailedAddressValidation, setDetailedAddressValidation] = useState({
    isDetailedAddressValid: false,
  });

  const [isAutocompleteFocused, setIsAutocompleteFocused] = useState({
    businessAddress: false,
    businessCategory: false,
    businessName: false,
    contactAddress: false,
  });

  const { red100 } = useTheme().custom.colour;

  const [sessionId, createSessionId] = useState("");

  const titleObject = {
    freeAd: {
      header: "Get your Free Yellow Pages Online listing.",
      description:
        "Fill out your business details below to sign up today. It’s fast, free and will help your business be found online.",
    },
  };

  const loadBusinessCategories = useCallback(async () => {
    const response = await apiWithLoader({
      config: {
        method: "post",
        url: config.mapiPullUrl,
        data: `req=sapi_get&sapi_req=metadata/categories&sapi_payload=''`,
        headers: {
          key: config.mapiPullKey,
          accept: "*/*",
        },
      },
    });

    if (response?.data?.sapi_response?.categories) {
      setBusinessCategoryOptions(response.data.sapi_response.categories);
    }
  }, [config.mapiPullKey, config.mapiPullUrl]);

  const generateUniqueId = () => {
    const firstUniqueId = uuidv4().split("-").join("");
    const secondUniqueId = uuidv4().split("-").join("");
    const thirdUniquedId = uuidv4().split("-").join("");
    const generatedId = `${firstUniqueId}${secondUniqueId}${thirdUniquedId}${new Date().getUTCMilliseconds()}`;
    let sliceGeneratedId = "";
    if (generatedId.length > 96) {
      sliceGeneratedId = generatedId.slice(0, 96);
    } else if (generatedId.length < 96) {
      sliceGeneratedId = `${generatedId}${generatedId.slice(
        0,
        96 - generatedId.length
      )}`;
    } else {
      sliceGeneratedId = generatedId;
    }
    return sliceGeneratedId;
  };
  useEffect(() => {
    dtmSatelliteTrack("start");
    loadBusinessCategories();
    createSessionId(generateUniqueId());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setEventObject = (name, value) => ({
    target: {
      name,
      value,
    },
  });

  const setFieldError = (fieldName, error) => {
    setErrors((state) => ({
      ...state,
      [fieldName]: error,
    }));
  };

  const dtmSatelliteTrackField = (fieldName, type = "input") =>
    dtmSatelliteTrack(`osu-${type}-${fieldName}`);

  const trackOnChange = (event, type = "input") => {
    const { name, value } = event.target;

    if (value !== trackInputs[name]) {
      dtmSatelliteTrackField(name, type);
      setTrackInputs((state) => ({
        ...state,
        [name]: value,
      }));
    }
  };

  const getProfanityCheckError = (
    { expletive, found },
    identifier = "listing"
  ) => {
    const foundNum = Number(found);

    if (!expletive || foundNum === 0) {
      return null;
    }

    const expletiveWords = Array.isArray(expletive)
      ? expletive.join(", ")
      : expletive;

    return `Something’s not quite right.You’ve used the word ${expletiveWords} in your ${identifier}. 
      Our online filter will not allow this word to be used in your ad.`;
  };

  const validateBusinessName = async (event) => {
    const { name, value } = event.target;
    let error = null;
    let isBusinessNameValid = false;

    setBusinessNameValidation((state) => ({
      ...state,
      isBusinessNameValid,
    }));

    if (!value) {
      error = ERROR_MESSAGES.businessName.required;
    } else {
      const response = await apiWithLoader({
        config: {
          method: "post",
          url: config.mapiPullUrl,
          data: `req=chk_bnm&bnm=${value}`,
          headers: {
            key: config.mapiPullKey,
            accept: "*/*",
          },
        },
        isStateObject: true,
        loadingProperty: "validatingBusinessName",
        loadingCallback: setBusinessNameValidation,
      });

      const profanityCheck = response?.data?.pf_check?.rsp || {};
      const profanityCheckError = getProfanityCheckError(profanityCheck);

      if (profanityCheckError) {
        error = profanityCheckError;
      } else {
        isBusinessNameValid = true;
      }
    }

    setBusinessNameValidation((state) => ({
      ...state,
      isBusinessNameValid,
    }));

    setFieldError(name, error);
  };

  const lookupBusinessName = async (event) => {
    if (selectedAbn && abnValidation.isAbnValid) {
      return;
    }
    const response = await apiWithLoader({
      config: {
        method: "post",
        url: config.mapiPullUrl,
        data: `req=chk_bnm&bnm=${event.target.value}`,
        headers: {
          key: config.mapiPullKey,
          accept: "*/*",
        },
      },
      isStateObject: true,
      loadingProperty: "businessName",
      loadingCallback: setLoading,
    });

    if (response?.data?.abn_lookup?.Names) {
      setBusinessNameOptions(response.data.abn_lookup.Names || []);
    }
  };

  const validateAbn = async (event) => {
    let abnError = null;
    let businessName = "";

    setAbnValidation((state) => ({
      ...state,
      isAbnValid: false,
    }));

    if (event.target.value.length < 11) {
      abnError = ERROR_MESSAGES.abn.minlength;
      setErrors((state) => ({
        ...state,
        abn: abnError,
      }));

      setAbnValidation((state) => ({
        ...state,
        isAbnValid: false,
      }));

      return;
    }

    const response = await apiWithLoader({
      config: {
        method: "post",
        url: config.mapiPullUrl,
        data: `req=chk_abn&abn=${event.target.value}`,
        headers: {
          accept: "*/*",
          key: config.mapiPullKey,
        },
      },
      isStateObject: true,
      loadingProperty: "validatingAbn",
      loadingCallback: setAbnValidation,
    });

    const abnData = response?.data?.abn_lookup || {};
    const sfData = response?.data?.sf_check || {};

    if (abnData.AbnStatus?.toLowerCase() === "active") {
      if (sfData.exists) {
        abnError = ERROR_MESSAGES.abn.exists;
      } else {
        businessName = abnData.BusinessName[0] || abnData.EntityName || "";
      }

      setAbnValidation((state) => ({
        ...state,
        isAbnValid: !sfData.exists,
      }));
    } else {
      abnError = ERROR_MESSAGES.abn.invalid;

      setAbnValidation((state) => ({
        ...state,
        isAbnValid: false,
      }));
    }

    if (
      !abnError &&
      !selectedBusinessName.Name &&
      (!inputs.businessName || (inputs.businessName && selectedAbn))
    ) {
      setInputs((state) => ({
        ...state,
        businessName,
      }));
      trackOnChange(
        setEventObject("businessName", businessName),
        "prepopulate"
      );
      validateBusinessName(setEventObject("businessName", businessName));
    }

    setErrors((state) => ({
      ...state,
      abn: abnError,
    }));
  };

  const getModifiedStreetAddress = (streetAddress = "") => {
    const streetAddressParts = streetAddress.split(" ");
    const lastIndex = streetAddressParts.length - 1;
    const streetTypeAbbreviation = streetAddressParts[lastIndex].toLowerCase();
    const streetType = ADDRESS_STREET_TYPES[streetTypeAbbreviation];

    if (!streetType) {
      return streetAddress;
    }

    streetAddressParts[lastIndex] = streetType;

    return streetAddressParts.join(" ");
  };

  const customFilterOptions = (
    options = [],
    inputValue,
    sortKey,
    isAddress = false
  ) => {
    const filteredOptions = matchSorter(options, inputValue, {
      keys: [sortKey],
    });

    if (!isAddress) {
      return filteredOptions;
    }

    let finalOptions = [...filteredOptions];

    if (finalOptions.length === 0) {
      let mappedOptions = [...options];
      mappedOptions = mappedOptions.map((option) => {
        const {
          street_address: streetAddress,
          suburb,
          state,
          postcode,
        } = option;

        const modifiedStreetAddress = getModifiedStreetAddress(streetAddress);
        const modifiedAddress = `${modifiedStreetAddress}, ${suburb} ${state} ${postcode}`;

        return {
          ...option,
          modifiedAddress,
        };
      });

      const filteredMappedOptions = matchSorter(mappedOptions, inputValue, {
        keys: ["modifiedAddress"],
      });

      finalOptions = [...finalOptions, ...filteredMappedOptions];
    }

    return finalOptions;
  };

  const autoSelectFieldValue = (
    event,
    options,
    sortKey,
    setSelectionFn,
    selectedValue = {},
    isAddress = false
  ) => {
    const { name, value } = event.target;
    const matchedOptions = customFilterOptions(
      options,
      value,
      sortKey,
      isAddress
    );
    let searchIndex = -1;

    if (
      matchedOptions.length === 0 ||
      !value ||
      value === selectedValue[sortKey]
    ) {
      return;
    }

    if (matchedOptions.length === 1) {
      searchIndex = 0;
    } else if (matchedOptions.length > 1) {
      searchIndex = matchedOptions
        .map((option) => option[sortKey].toLowerCase())
        .indexOf(value.toLowerCase());

      if (isAddress && searchIndex < 0) {
        searchIndex = matchedOptions
          .map((option) => option.modifiedAddress?.toLowerCase())
          .indexOf(value.toLowerCase());
      }
    }

    if (searchIndex > -1) {
      const selection = matchedOptions[searchIndex];
      setSelectionFn(selection);
      setInputs((state) => ({
        ...state,
        [name]: selection[sortKey],
      }));

      if (name === "businessName") {
        setInputs((state) => ({
          ...state,
          abn: selection.Abn,
        }));

        trackOnChange(setEventObject("abn", selection.Abn), "prepopulate");
        validateAbn(setEventObject("abn", selection.Abn));
      }
    }
  };

  const validateAddress = async (event) => {
    const { name, value } = event.target;
    const setSelectionFn =
      name === "contactAddress"
        ? setSelectedContactAddress
        : setSelectedBussinessAddress;
    const addressOptions =
      name === "contactAddress"
        ? contactAddressOptions
        : businessAddressOptions;
    const matchedOptions = customFilterOptions(
      addressOptions,
      value,
      "formatted_address",
      true
    );
    let error = null;

    if (!value || value.length < 3) {
      setSelectionFn({});
      error = ERROR_MESSAGES.businessAddress.required;
    } else if (
      matchedOptions.length !== 1 &&
      (matchedOptions.length === 0 ||
        (!matchedOptions
          .map((option) => option.formatted_address)
          .some((address) => address.toLowerCase() === value.toLowerCase()) &&
          !matchedOptions
            .map((option) => option.modifiedAddress)
            .some((address) => address?.toLowerCase() === value.toLowerCase())))
    ) {
      setSelectionFn({});
      error = ERROR_MESSAGES.businessAddress.invalid;
    }

    setFieldError(name, error);

    if (name === "businessAddress" && isCheck.accountDetails) {
      setInputs((state) => ({
        ...state,
        contactAddress: value,
      }));
      setSelectedContactAddress(error ? {} : selectedBussinessAddress);
      setFieldError("contactAddress", error);
    }
  };

  const lookupAddress = async (event) => {
    const response = await apiWithLoader({
      config: {
        method: "post",
        url: config.mapiPullUrl,
        data: `req=chk_adr&adr=${event.target.value}`,
        headers: {
          key: config.mapiPullKey,
          accept: "*/*",
        },
      },
      isStateObject: true,
      loadingProperty: event.target.name,
      loadingCallback: setLoading,
    });

    if (response?.data?.tc_check?.results) {
      if (event.target.name === "businessAddress") {
        setBusinessAddressOptions(response?.data?.tc_check?.results || []);
      }

      if (event.target.name === "contactAddress") {
        setContactAddressOptions(response?.data?.tc_check?.results || []);
      }
    }
  };

  const debouncedBusinessNameLookup = debounce(lookupBusinessName, 1000);
  const debouncedBusinessAddressLookup = debounce(lookupAddress, 1000);

  const handleInputChange = (event) => {
    event.persist();
    setInputs((inputss) => ({
      ...inputss,
      [event.target.name]: event.target.value,
    }));
  };

  const handleAbnInputBlur = (event) => {
    setAbnValidation((state) => ({
      ...state,
      isAbnFieldFocused: false,
    }));

    if (!selectedBusinessName?.Name) {
      setSelectedAbn(event.target.value);
    }

    validateAbn(event);
  };

  const isValidNumericInput = (input) => {
    const ALLOWABLE_CHARACTERS = /^[0-9]*$/;
    return ALLOWABLE_CHARACTERS.test(input);
  };

  const handleNumericInputChange = (event) => {
    const { name, value } = event.target;

    if (isValidNumericInput(value) || value === "") {
      setInputs((state) => ({
        ...state,
        [name]: value,
      }));
    }
  };

  const validateBusinessCategory = (event) => {
    const { name, value } = event.target;
    const matchedOptions = customFilterOptions(
      businessCategoryOptions,
      value,
      "name"
    );
    let error = null;

    if (!value) {
      error = ERROR_MESSAGES.businessCategory.required;
    } else if (
      matchedOptions.length !== 1 &&
      (matchedOptions.length === 0 ||
        !matchedOptions
          .map((option) => option.name)
          .some((category) => category.toLowerCase() === value.toLowerCase()))
    ) {
      setSelectedBusinessCategory(DEFAULT_CATEGORY_STATE);
      error = ERROR_MESSAGES.businessCategory.unknown;
    }

    setFieldError(name, error);
  };

  const isValidPhonePrefix = (phoneNumber) => {
    const phoneTrimmed = phoneNumber.replace(/\s/g, "");
    return !/^0[1569]/.test(phoneTrimmed);
  };

  const areValidPhoneCharacters = (phone) => {
    const ALLOWABLE_CHARACTERS = /^[0-9()+\-( )]+$/;
    return ALLOWABLE_CHARACTERS.test(phone);
  };

  const isValidPhone = (phone) => {
    const VALID_PHONE_REGEX = /(^1300(| )[0-9]{3}(| )[0-9]{3}$)|(^1[800|900|902]{3}(| )[0-9]{3}(| )[0-9]{3}$)|(^0[2|3|7|8]{1}(| )[0-9]{4}(| )[0-9]{4}$)|(^0[2|3|7|8]{1}(| )[13]{2}(| )[0-9]{4}$)|(^04[0-9]{2}(| )[0-9]{3}(| )[0-9]{3}$)/;
    return VALID_PHONE_REGEX.test(phone);
  };

  const stripCountryCodeAndWhitespaces = (phone) => {
    return phone.replace(/[()+\-( )]/g, "").replace(/^61/, "0");
  };

  const handlePhoneInputChange = (event) => {
    const { name, value } = event.target;

    if (areValidPhoneCharacters(value) || value === "") {
      setInputs((state) => ({
        ...state,
        [name]: value,
      }));
    }
  };

  const invalidVirtualNumber = (value, invalidVirtualNumberPrefixes) =>
    invalidVirtualNumberPrefixes.some((prefix) => value.indexOf(prefix) === 0);

  const validatePhone = async (event) => {
    const { name, value } = event.target;
    const formattedPhoneNumber = stripCountryCodeAndWhitespaces(value);
    let error = null;
    let isPhoneValid = false;

    setPhoneValidation((state) => ({
      ...state,
      [name === "businessPhone"
        ? "isBusinessPhoneValid"
        : "isContactPhoneValid"]: isPhoneValid,
    }));

    setInputs((state) => ({
      ...state,
      [name]: formattedPhoneNumber,
    }));

    if (!value) {
      error = ERROR_MESSAGES[name].required;
    } else if (
      value.length < 6 ||
      !isValidPhonePrefix(value) ||
      !isValidPhone(formattedPhoneNumber)
    ) {
      error = ERROR_MESSAGES[name].minlength;
    } else if (
      name === "businessPhone" &&
      invalidVirtualNumber(formattedPhoneNumber, ["13", "190", "1800"])
    ) {
      error = <VirtualBusinessPhoneErrorText />;
    } else if (
      name === "contactPhone" &&
      invalidVirtualNumber(formattedPhoneNumber, ["19"])
    ) {
      error = ERROR_MESSAGES.contactPhone.virtual;
    } else {
      const response = await apiWithLoader({
        config: {
          method: "post",
          url: config.mapiPullUrl,
          data: `req=chk_phn&phn=${value}`,
          headers: {
            accept: "*/*",
            key: config.mapiPullKey,
          },
        },
        isStateObject: true,
        loadingProperty:
          name === "businessPhone"
            ? "validatingBusinessPhone"
            : "validatingContactPhone",
        loadingCallback: setPhoneValidation,
      });

      const sfCheck = response?.data?.sf_check || {};
      const totalCheck = response?.data?.tc_check?.result || {};

      if (sfCheck.exists) {
        error = ERROR_MESSAGES[name].exists;
      } else if (totalCheck.phone_status === "Inactive") {
        error = ERROR_MESSAGES[name].inActive;
      } else if (
        totalCheck.phone_status === "Active" ||
        totalCheck.phone_status === "VirtualNumber"
      ) {
        isPhoneValid = true;
      } else {
        error = ERROR_MESSAGES[name].minlength;
      }

      setPhoneValidation((state) => ({
        ...state,
        [name === "businessPhone"
          ? "isBusinessPhoneValid"
          : "isContactPhoneValid"]: isPhoneValid,
      }));
    }

    setFieldError(name, error);

    if (name === "businessPhone" && isCheck.accountDetails) {
      setInputs((state) => ({
        ...state,
        contactPhone: formattedPhoneNumber,
      }));
      setFieldError("contactPhone", error);
      setPhoneValidation((state) => ({
        ...state,
        isContactPhoneValid: isPhoneValid,
      }));
    }
  };

  const isValidEmail = (email) => {
    const EMAIL_REGEX = RegExp(
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
    );
    return EMAIL_REGEX.test(email);
  };

  const validateEmail = async (event) => {
    let error = null;
    const { name, value } = event.target;
    let isEmailValid = false;

    setEmailValidation((state) => ({
      ...state,
      [name === "businessEmail"
        ? "isBusinessEmailValid"
        : "isContactEmailValid"]: isEmailValid,
    }));

    if (value) {
      if (!isValidEmail(value)) {
        error = ERROR_MESSAGES[name].invalid;
      } else {
        const response = await apiWithLoader({
          config: {
            method: "post",
            url: config.mapiPullUrl,
            data: `req=chk_eml&eml=${value}`,
            headers: {
              accept: "*/*",
              key: config.mapiPullKey,
            },
          },
          isStateObject: true,
          loadingProperty:
            name === "businessEmail"
              ? "validatingBusinessEmail"
              : "validatingContactEmail",
          loadingCallback: setEmailValidation,
        });

        const sfCheck = response?.data?.sf_check || {};
        const totalCheck = response?.data?.tc_check?.result || {};
        const profanityCheck = response?.data?.pf_check?.rsp || {};

        const profanityCheckError = getProfanityCheckError(
          profanityCheck,
          "email"
        );

        if (sfCheck.exists) {
          error = ERROR_MESSAGES[name].exists;
        } else if (name === "businessEmail" && profanityCheckError) {
          error = profanityCheckError;
        } else if (
          totalCheck.email_valid !== "VALID" ||
          totalCheck.email_exists === "INVALID"
        ) {
          error = ERROR_MESSAGES[name].invalid;
        } else {
          isEmailValid = true;
        }
        setEmailValidation((state) => ({
          ...state,
          [name === "businessEmail"
            ? "isBusinessEmailValid"
            : "isContactEmailValid"]: isEmailValid,
        }));
      }
    } else {
      error = ERROR_MESSAGES.businessEmail.required;
    }

    setFieldError(name, error);

    if (name === "businessEmail" && isCheck.accountDetails) {
      setInputs((state) => ({
        ...state,
        contactEmail: value,
      }));
      setFieldError("contactEmail", error);
      setEmailValidation((state) => ({
        ...state,
        isContactEmailValid: isEmailValid,
      }));
    }
  };

  const validateWebsite = async (event) => {
    let error = null;
    const { name, value } = event.target;
    let isWebsiteValid = false;
    setWebsiteValidation((state) => ({
      ...state,
      isWebsiteValid,
    }));

    if (value) {
      const response = await apiWithLoader({
        config: {
          method: "post",
          url: config.mapiPullUrl,
          data: `req=chk_url&url=${value}`,
          headers: {
            accept: "*/*",
            key: config.mapiPullKey,
          },
        },
        isStateObject: true,
        loadingProperty: "validatingWebsite",
        loadingCallback: setWebsiteValidation,
      });

      const totalCheck = response?.data?.tc_check?.result || {};
      const profanityCheck = response?.data?.pf_check?.rsp || {};
      const profanityCheckError = getProfanityCheckError(profanityCheck);

      if (profanityCheckError) {
        error = profanityCheckError;
      } else if (totalCheck.url_syntax_valid === "INVALID") {
        error = ERROR_MESSAGES.website.invalid;
      } else if (totalCheck.domain_exists === "INVALID") {
        error = ERROR_MESSAGES.website.invalidDomain;
      } else {
        isWebsiteValid = true;
      }
      setWebsiteValidation((state) => ({
        ...state,
        isWebsiteValid,
      }));
    }
    setFieldError(name, error);
  };

  const isValidAlphanumericValue = (name) => {
    const NAME_REGEX = /^[-sa-zA-Z]+$/;
    return NAME_REGEX.test(name.replace(/\s/g, ""));
  };

  const handleAlphanumericInputChange = (event) => {
    const { name, value } = event.target;

    if (isValidAlphanumericValue(value) || value === "") {
      setInputs((state) => ({
        ...state,
        [name]: value,
      }));
    }
  };

  const validateName = (event) => {
    const { name, value } = event.target;
    let error = null;

    if (!value) {
      error = ERROR_MESSAGES[name].required;
    } else if (!isValidAlphanumericValue(value)) {
      error = ERROR_MESSAGES[name].invalid;
    }

    setFieldError(name, error);
  };

  const getSelectedAddress = async (setSelectionFn, name, val) => {
    setInputs((state) => ({
      ...state,
      [name]: val?.formatted_address || "",
    }));

    const response = await apiWithLoader({
      config: {
        method: "post",
        url: config.mapiPullUrl,
        data: `req=chk_adr_id&adr_id=${val.search_result_id}`,
        headers: {
          key: config.mapiPullKey,
          accept: "*/*",
        },
      },
      isStateObject: true,
      loadingProperty: name,
      loadingCallback: setLoading,
    });
    const result = response?.data?.tc_check?.result || {};
    setSelectionFn(result);

    if (isCheck.accountDetails && name === "businessAddress") {
      setSelectedContactAddress(result);
    }

    if (result.formatted_address) {
      setDetailedAddressValidation((state) => ({
        ...state,
        isDetailedAddressValid: true,
      }));
    }
  };

  const enableCreateListing = () => {
    let enabled = false;

    const requiredFields = [
      "abn",
      "businessName",
      "businessCategory",
      "businessAddress",
      "businessPhone",
      "businessEmail",
      "firstName",
      "lastName",
      "contactPhone",
      "contactAddress",
      "contactEmail",
    ];

    const requiredValues = requiredFields.map((field) => inputs[field]);
    const errorValues = Object.keys(errors).map((key) => errors[key]);
    const allChecksValid =
      abnValidation.isAbnValid &&
      phoneValidation.isBusinessPhoneValid &&
      phoneValidation.isContactPhoneValid &&
      emailValidation.isBusinessEmailValid &&
      emailValidation.isContactEmailValid &&
      businessNameValidation.isBusinessNameValid &&
      detailedAddressValidation.isDetailedAddressValid &&
      (!inputs.website || websiteValidation.isWebsiteValid);
    const areRequiredSelectionsPopulated =
      !!selectedBussinessAddress?.formatted_address &&
      !!selectedContactAddress?.formatted_address &&
      !!selectedBusinessCategory?.name;
    const autocompleteValuesCommitted =
      !isAutocompleteFocused.businessName &&
      (!isAutocompleteFocused.businessAddress ||
        inputs.businessAddress ===
          selectedBussinessAddress?.formatted_address) &&
      (!isAutocompleteFocused.businessCategory ||
        inputs.businessCategory === selectedBusinessCategory?.name) &&
      (!isAutocompleteFocused.contactAddress ||
        inputs.contactAddress === selectedContactAddress?.formatted_address);

    if (
      requiredValues.every((value) => value) &&
      errorValues.every((value) => !value) &&
      areRequiredSelectionsPopulated &&
      autocompleteValuesCommitted &&
      allChecksValid &&
      isCheck.terms &&
      isCheck.optIn
    ) {
      enabled = true;
    }

    return enabled;
  };

  const createPayload = () => {
    const {
      abn,
      businessPhone,
      businessName,
      businessEmail,
      contactPhone,
      contactEmail,
      firstName,
      lastName,
      website,
    } = inputs;

    const { id, name: businessCategory } = selectedBusinessCategory;

    const {
      state,
      postcode,
      suburb,
      subpremise,
      country,
      building_name: buildingName,
      street_address: street,
      formatted_address: businessAddress,
      street_name: streetName,
      street_number: streetNumber,
      street_suffix: streetSuffix,
      street_type: streetType,
      geo_lat: latitude,
      geo_lon: longitude,
    } = selectedBussinessAddress;

    return {
      ...payload,
      sessionId,
      listingDetails: {
        Name: businessName,
        listings: [
          {
            websiteEntered: website,
            website,
            suburb,
            subpremise,
            streetType,
            streetSuffix,
            streetNumber,
            streetName,
            street,
            state,
            searchResultId: null,
            publishAddress: selectedAddressType,
            postcode,
            phone: businessPhone,
            longitude,
            latitude,
            isPrimary: true,
            formattedAddress: businessAddress,
            email: businessEmail,
            country,
            buildingName,
          },
        ],
        categories: [
          {
            isPrimary: true,
            categoryName: businessCategory,
            categoryCode: id,
            additionalAreas: true,
          },
        ],
        areas: [],
        additionalListingRequested: false,
        additionalCategoryRequested: false,
        additionalAreaRequested: false,
      },
      contactDetails: {
        phone: contactPhone,
        email: contactEmail,
        firstName,
        lastName,
        signatory: null,
        preferredContactMethod: null,
        mobile: null,
        dataChanged: null,
        activated: null,
      },
      businessDetails: {
        suburb,
        subpremise,
        streetType,
        streetSuffix,
        streetNumber,
        streetName,
        street,
        state,
        searchResultId: null,
        postcode,
        name: businessName,
        longitude,
        latitude,
        formattedAddress: businessAddress,
        country,
        buildingName: "",
        abn,
      },
    };
  };

  const recaptchaRef = useRef();

  const submitForm = async () => {
    dtmSatelliteTrack("osu-submit");
    const sessionData = createPayload();

    const token = await recaptchaRef.current.executeAsync();

    const body = new FormData();
    body.append("req", "create_session");
    body.append("session_state", "sale-complete");
    body.append("session_id", sessionId);
    body.append("session_data", JSON.stringify(sessionData));
    body.append("recaptchaClientToken", token);

    if (token) {
      setIsLoading(true);
      const response = await apiWithLoader({
        config: {
          method: "post",
          url: config.freeAdFormURL,
          data: body,
          headers: {
            key: config.mapiPullKey,
            "content-type": "application/x-www-form-urlencoded",
          },
        },
        loadingCallback: () => {},
      });

      const { status, data } = response;

      if (data?.session?.is_created && status === 200) {
        window.scroll({ top: 0, behavior: "smooth" });
        await recaptchaRef.current.reset();
        history.push({
          pathname: "/online-signup/success",
          state: { isCreated: true },
        });
      } else {
        await recaptchaRef.current.reset();
        setIsSubmitError(true);
      }
      setIsLoading(false);
    }
  };

  return (
    <MainContent
      header={titleObject.freeAd.header}
      description={titleObject.freeAd.description}
      loading={false}
    >
      <FormContainer>
        <Box px={2}>
          <Typography variant="h3">
            Business details to appear on your listing.
          </Typography>
          <Typography variant="body2">
            Let’s start with adding your business details that you’d like your
            customers to see.
          </Typography>
        </Box>
        <Box px={2} py={2}>
          <Autocomplete
            id="business-name"
            options={businessNameOptions}
            optionLabelProperty="Name"
            filterOptions={(options, { inputValue }) => {
              return customFilterOptions(options, inputValue, "Name");
            }}
            disableClearable
            displayLoader
            open={open.businessName}
            inputAutoComplete="new-password"
            onOpen={() => {
              setOpen((state) => ({
                ...state,
                businessName: true,
              }));
            }}
            onClose={() => {
              setOpen((state) => ({
                ...state,
                businessName: false,
              }));
            }}
            loading={loading.businessName && open.businessName}
            onKeyUp={debouncedBusinessNameLookup}
            label="Business name"
            name="businessName"
            inputValue={inputs.businessName || ""}
            onChange={(event, newValue) => {
              setSelectedBusinessName(newValue || {});
              setInputs((state) => ({
                ...state,
                businessName: newValue?.Name,
              }));

              if (newValue?.Abn) {
                setInputs((state) => ({
                  ...state,
                  abn: newValue?.Abn || "",
                }));

                trackOnChange(
                  setEventObject("abn", newValue?.Abn || ""),
                  "prepopulate"
                );
                validateAbn(setEventObject("abn", newValue?.Abn || ""));
              }
            }}
            onInputChange={(event, newValue, reason) => {
              if (event?.target && reason === "input") {
                setInputs((state) => ({
                  ...state,
                  [event.target.name]: event.target.value,
                }));

                if (!event.target.value) {
                  setSelectedBusinessName({});
                }
              }
            }}
            onBlur={(event) => {
              setIsAutocompleteFocused((state) => ({
                ...state,
                businessName: false,
              }));

              trackOnChange(event);
              validateBusinessName(event);

              if (event.target.value) {
                autoSelectFieldValue(
                  event,
                  businessNameOptions,
                  "Name",
                  setSelectedBusinessName,
                  selectedBusinessName
                );
              }
            }}
            onFocus={(event) => {
              setFieldError(event.target.name, null);
              setIsAutocompleteFocused((state) => ({
                ...state,
                businessName: true,
              }));
              setBusinessNameValidation((state) => ({
                ...state,
                isBusinessNameValid: false,
              }));
            }}
            errorText={errors.businessName}
            maxLength={80}
            inputLoading={businessNameValidation.validatingBusinessName}
            showIcon={
              !isAutocompleteFocused.businessName &&
              !(
                errors.businessName &&
                businessNameValidation.isBusinessNameValid
              )
            }
            icon={
              businessNameValidation.isBusinessNameValid
                ? ValidIcon
                : InvalidIcon
            }
            iconColor={
              businessNameValidation.isBusinessNameValid ? "green" : red100
            }
          />
        </Box>
        <TextField
          label="ABN"
          name="abn"
          value={inputs.abn || ""}
          maxLength={11}
          errorText={errors.abn}
          onChange={handleNumericInputChange}
          onBlur={(event) => {
            trackOnChange(event);
            handleAbnInputBlur(event);
          }}
          onFocus={() => {
            setAbnValidation((state) => ({
              ...state,
              isAbnValid: false,
              isAbnFieldFocused: true,
            }));
          }}
          loading={abnValidation.validatingAbn}
          showIcon={
            !abnValidation.isAbnFieldFocused &&
            !(errors.abn && abnValidation.isAbnValid)
          }
          icon={abnValidation.isAbnValid ? ValidIcon : InvalidIcon}
          iconColor={abnValidation.isAbnValid ? "green" : red100}
        />

        <Box px={2}>
          <Box py={2}>
            <Autocomplete
              id="business-address"
              disableClearable
              options={businessAddressOptions}
              optionLabelProperty="formatted_address"
              filterOptions={(options, { inputValue }) => {
                return customFilterOptions(
                  options,
                  inputValue,
                  "formatted_address",
                  true
                );
              }}
              freeSolo={false}
              displayLoader
              open={open.businessAddress}
              inputAutoComplete="new-password"
              onOpen={() => {
                setOpen((state) => ({
                  ...state,
                  businessAddress: true,
                }));
              }}
              onClose={() => {
                setOpen((state) => ({
                  ...state,
                  businessAddress: false,
                }));
              }}
              loading={loading.businessAddress && open.businessAddress}
              onKeyUp={debouncedBusinessAddressLookup}
              label="Business Address"
              name="businessAddress"
              inputValue={inputs.businessAddress || ""}
              onChange={(event, newValue) => {
                getSelectedAddress(
                  setSelectedBussinessAddress,
                  "businessAddress",
                  newValue
                );
              }}
              onInputChange={(event, newValue, reason) => {
                if (event?.target && reason === "input") {
                  setInputs((state) => ({
                    ...state,
                    [event.target.name]: event.target.value,
                  }));

                  if (!event.target.value) {
                    setSelectedBussinessAddress({});
                  }
                }

                if (reason === "clear") {
                  setSelectedBussinessAddress({});
                }
              }}
              onBlur={(event) => {
                setIsAutocompleteFocused((state) => ({
                  ...state,
                  businessAddress: false,
                }));

                trackOnChange(event);

                if (event.target.value) {
                  autoSelectFieldValue(
                    event,
                    businessAddressOptions,
                    "formatted_address",
                    setSelectedBussinessAddress,
                    selectedBussinessAddress,
                    true
                  );
                }

                validateAddress(event);
              }}
              onFocus={(event) => {
                setFieldError(event.target.name, null);
                setIsAutocompleteFocused((state) => ({
                  ...state,
                  businessAddress: true,
                }));
              }}
              errorText={errors.businessAddress}
              maxLength={255}
            />
          </Box>
        </Box>

        <Box px={2} mb={1}>
          <Typography variant="body2">Display your location as:</Typography>
          <FormControl component="fieldset">
            <RadioGroup
              row
              name="radio-buttons-group"
              value={selectedAddressType}
              onChange={(event) => {
                setSelectedAddressType(event.target.value);
                dtmSatelliteTrackField(
                  `displayLocationAs-${event.target.value}`
                );
              }}
            >
              <FormControlLabel
                value="fullAddress"
                control={<Radio color="default" />}
                label="Full address"
              />
              <FormControlLabel
                value="suburbOnly"
                control={<Radio color="default" />}
                label="Suburb only"
              />
              <FormControlLabel
                value="doNotDisplay"
                control={<Radio color="default" />}
                label="Do not display"
              />
            </RadioGroup>
          </FormControl>
        </Box>

        <TextField
          label="Phone"
          name="businessPhone"
          value={inputs.businessPhone || ""}
          maxLength={14}
          errorText={errors.businessPhone}
          onChange={handlePhoneInputChange}
          onBlur={(event) => {
            trackOnChange(event);
            validatePhone(event);
            setPhoneValidation((state) => ({
              ...state,
              isBusinessPhoneFieldFocused: false,
            }));
          }}
          onFocus={() => {
            setPhoneValidation((state) => ({
              ...state,
              isBusinessPhoneValid: false,
              isBusinessPhoneFieldFocused: true,
            }));
          }}
          loading={phoneValidation.validatingBusinessPhone}
          showIcon={
            !phoneValidation.isBusinessPhoneFieldFocused &&
            !(errors.businessPhone && phoneValidation.isBusinessPhoneValid)
          }
          icon={phoneValidation.isBusinessPhoneValid ? ValidIcon : InvalidIcon}
          iconColor={phoneValidation.isBusinessPhoneValid ? "green" : red100}
        />
        <TextField
          label="Email"
          name="businessEmail"
          value={inputs.businessEmail || ""}
          errorText={errors.businessEmail}
          onChange={handleInputChange}
          onBlur={(event) => {
            trackOnChange(event);
            validateEmail(event);
            setEmailValidation((state) => ({
              ...state,
              isBusinessEmailFieldFocused: false,
            }));
          }}
          onFocus={() => {
            setEmailValidation((state) => ({
              ...state,
              isBusinessEmailValid: false,
              isBusinessEmailFieldFocused: true,
            }));
          }}
          loading={emailValidation.validatingBusinessEmail}
          showIcon={
            !emailValidation.isBusinessEmailFieldFocused &&
            !(errors.businessEmail && emailValidation.isBusinessEmailValid)
          }
          icon={emailValidation.isBusinessEmailValid ? ValidIcon : InvalidIcon}
          iconColor={emailValidation.isBusinessEmailValid ? "green" : red100}
        />
        <Box px={2}>
          <MuiLink
            underline="always"
            color="textPrimary"
            onClick={() => {
              setIsCheck({
                ...isCheck,
                addWebsite: !isCheck.addWebsite,
              });
              dtmSatelliteTrack("osu-link-add-website");
            }}
          >
            {!isCheck.addWebsite ? "Add a website" : "Website (optional)"}
          </MuiLink>
        </Box>

        {isCheck.addWebsite && (
          <TextField
            label="Website"
            name="website"
            value={inputs.website || ""}
            errorText={errors.website}
            onChange={handleInputChange}
            onBlur={(event) => {
              trackOnChange(event);
              validateWebsite(event);
              setWebsiteValidation((state) => ({
                ...state,
                isWebsiteFieldFocused: false,
              }));
            }}
            onFocus={() => {
              setWebsiteValidation((state) => ({
                ...state,
                isWebsiteValid: false,
                isWebsiteFieldFocused: true,
              }));
            }}
            loading={websiteValidation.validatingWebsite}
            showIcon={
              !websiteValidation.isWebsiteFieldFocused &&
              !(errors.website && websiteValidation.isWebsiteValid)
            }
            icon={websiteValidation.isWebsiteValid ? ValidIcon : InvalidIcon}
            iconColor={websiteValidation.isWebsiteValid ? "green" : red100}
          />
        )}

        <Box px={2} py={2}>
          <Typography variant="h3">
            Business category for your listing
          </Typography>
          <Typography variant="body2">
            Your listing will show up for searches under this category
          </Typography>
          <Box pt={2}>
            <Autocomplete
              id="business-category"
              disableClearable
              options={businessCategoryOptions}
              optionLabelProperty="name"
              filterOptions={(options, { inputValue }) => {
                return customFilterOptions(options, inputValue, "name");
              }}
              freeSolo={false}
              open={open.businessCategory}
              inputAutoComplete="new-password"
              onOpen={() => {
                setOpen((state) => ({
                  ...state,
                  businessCategory: true,
                }));
              }}
              onClose={() => {
                setOpen((state) => ({
                  ...state,
                  businessCategory: false,
                }));
              }}
              loading={
                open.businessCategory && businessCategoryOptions.length === 0
              }
              label="Business category"
              name="businessCategory"
              inputValue={inputs.businessCategory || ""}
              onChange={(event, newValue) => {
                setSelectedBusinessCategory(newValue || DEFAULT_CATEGORY_STATE);
                setInputs((state) => ({
                  ...state,
                  businessCategory: newValue?.name || "",
                }));
              }}
              onInputChange={(event, newValue, reason) => {
                if (event?.target && reason === "input") {
                  setInputs((state) => ({
                    ...state,
                    [event.target.name]: event.target.value,
                  }));

                  if (!event.target.value) {
                    setSelectedBusinessCategory(DEFAULT_CATEGORY_STATE);
                  } else if (
                    selectedBusinessCategory?.name &&
                    event.target.value !== selectedBusinessCategory.name
                  ) {
                    setSelectedBusinessCategory(DEFAULT_CATEGORY_STATE);
                  }
                }

                if (reason === "clear") {
                  setSelectedBusinessCategory(DEFAULT_CATEGORY_STATE);
                }
              }}
              onBlur={(event) => {
                setIsAutocompleteFocused((state) => ({
                  ...state,
                  businessCategory: false,
                }));

                trackOnChange(event);
                validateBusinessCategory(event);

                if (event.target.value) {
                  autoSelectFieldValue(
                    event,
                    businessCategoryOptions,
                    "name",
                    setSelectedBusinessCategory,
                    selectedBusinessCategory
                  );
                }
              }}
              onFocus={(event) => {
                setFieldError(event.target.name, null);
                setIsAutocompleteFocused((state) => ({
                  ...state,
                  businessCategory: true,
                }));
              }}
              errorText={errors.businessCategory}
              value={selectedBusinessCategory}
            />
          </Box>
        </Box>

        <Box px={2}>
          <Typography variant="h3">Your account details.</Typography>
          <Typography variant="body2">
            Now a few more details to set up your account information. (This
            part won’t be seen on your listing.)
          </Typography>
        </Box>

        <Box display="flex">
          <Box flexGrow={1}>
            <TextField
              label="First name"
              name="firstName"
              value={inputs.firstName || ""}
              maxLength={40}
              errorText={errors.firstName}
              onChange={handleAlphanumericInputChange}
              onBlur={(event) => {
                trackOnChange(event);
                validateName(event);
              }}
            />
          </Box>
          <Box flexGrow={1}>
            <TextField
              label="Last name"
              name="lastName"
              value={inputs.lastName || ""}
              maxLength={80}
              errorText={errors.lastName}
              onChange={handleAlphanumericInputChange}
              onBlur={(event) => {
                trackOnChange(event);
                validateName(event);
              }}
            />
          </Box>
        </Box>

        <Box px={2}>
          <FormControlLabel
            control={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <StyledCheckbox
                color="default"
                checked={isCheck.accountDetails}
                name="contactDetailsCheckbox"
                disabled={
                  phoneValidation.validatingBusinessPhone ||
                  emailValidation.validatingBusinessEmail
                }
                onChange={(event) => {
                  setIsCheck({
                    ...isCheck,
                    accountDetails: !isCheck.accountDetails,
                  });
                  dtmSatelliteTrackField(event.target.name);

                  if (isCheck.accountDetails) {
                    setInputs((state) => ({
                      ...state,
                      contactAddress: "",
                      contactPhone: "",
                      contactEmail: "",
                    }));

                    setErrors((state) => ({
                      ...state,
                      contactAddress: null,
                      contactPhone: null,
                      contactEmail: null,
                    }));
                  } else if (!isCheck.accountDetails) {
                    setInputs((state) => ({
                      ...state,
                      contactAddress: inputs.businessAddress,
                      contactPhone: inputs.businessPhone,
                      contactEmail: inputs.businessEmail,
                    }));

                    setSelectedContactAddress(selectedBussinessAddress);

                    if (phoneValidation.isBusinessPhoneValid) {
                      setPhoneValidation((state) => ({
                        ...state,
                        isContactPhoneValid: true,
                      }));
                      setFieldError("contactPhone", null);
                    } else {
                      validatePhone(
                        setEventObject("contactPhone", inputs.businessPhone)
                      );
                    }

                    if (emailValidation.isBusinessEmailValid) {
                      setEmailValidation((state) => ({
                        ...state,
                        isContactEmailValid: true,
                      }));
                      setFieldError("contactEmail", null);
                    } else {
                      validateEmail(
                        setEventObject("contactEmail", inputs.businessEmail)
                      );
                    }

                    if (selectedBussinessAddress?.formatted_address) {
                      setFieldError("contactAddress", null);
                    }
                  }
                }}
              />
            }
            label="Account contact details are the same as listing"
          />
        </Box>

        {!isCheck.accountDetails && (
          <>
            <Box p={2}>
              <Autocomplete
                id="contact-address"
                disableClearable
                options={contactAddressOptions}
                optionLabelProperty="formatted_address"
                filterOptions={(options, { inputValue }) => {
                  return customFilterOptions(
                    options,
                    inputValue,
                    "formatted_address",
                    true
                  );
                }}
                freeSolo={false}
                displayLoader
                open={open.contactAddress}
                inputAutoComplete="new-password"
                onOpen={() => {
                  setOpen((state) => ({
                    ...state,
                    contactAddress: true,
                  }));
                }}
                onClose={() => {
                  setOpen((state) => ({
                    ...state,
                    contactAddress: false,
                  }));
                }}
                loading={loading.contactAddress && open.contactAddress}
                onKeyUp={debouncedBusinessAddressLookup}
                label="Contact Address"
                name="contactAddress"
                inputValue={inputs.contactAddress || ""}
                onChange={(event, newValue) => {
                  getSelectedAddress(
                    setSelectedContactAddress,
                    "contactAddress",
                    newValue
                  );
                }}
                onInputChange={(event, newValue, reason) => {
                  if (event?.target && reason === "input") {
                    setInputs((state) => ({
                      ...state,
                      [event.target.name]: event.target.value,
                    }));

                    if (!event.target.value) {
                      setSelectedContactAddress({});
                    }
                  }

                  if (reason === "clear") {
                    setSelectedContactAddress({});
                  }
                }}
                onBlur={(event) => {
                  setIsAutocompleteFocused((state) => ({
                    ...state,
                    contactAddress: false,
                  }));
                  trackOnChange(event);

                  if (event.target.value) {
                    autoSelectFieldValue(
                      event,
                      contactAddressOptions,
                      "formatted_address",
                      setSelectedContactAddress,
                      selectedContactAddress,
                      true
                    );
                  }

                  validateAddress(event);
                }}
                onFocus={(event) => {
                  setFieldError(event.target.name, null);
                  setIsAutocompleteFocused((state) => ({
                    ...state,
                    contactAddress: true,
                  }));
                }}
                errorText={errors.contactAddress}
                maxLength={255}
              />
            </Box>
            <TextField
              label="Phone"
              name="contactPhone"
              value={inputs.contactPhone || ""}
              maxLength={14}
              errorText={errors.contactPhone}
              onChange={handlePhoneInputChange}
              onBlur={(event) => {
                trackOnChange(event);
                validatePhone(event);
                setPhoneValidation((state) => ({
                  ...state,
                  isContactPhoneFieldFocused: false,
                }));
              }}
              onFocus={() => {
                setPhoneValidation((state) => ({
                  ...state,
                  isContactPhoneValid: false,
                  isContactPhoneFieldFocused: true,
                }));
              }}
              loading={phoneValidation.validatingContactPhone}
              showIcon={
                !phoneValidation.isContactPhoneFieldFocused &&
                !(errors.contactPhone && phoneValidation.isContactPhoneValid)
              }
              icon={
                phoneValidation.isContactPhoneValid ? ValidIcon : InvalidIcon
              }
              iconColor={phoneValidation.isContactPhoneValid ? "green" : red100}
            />
            <TextField
              label="Email"
              name="contactEmail"
              value={inputs.contactEmail || ""}
              errorText={errors.contactEmail}
              onChange={handleInputChange}
              onBlur={(event) => {
                trackOnChange(event);
                validateEmail(event);
                setEmailValidation((state) => ({
                  ...state,
                  isContactEmailFieldFocused: false,
                }));
              }}
              onFocus={() => {
                setEmailValidation((state) => ({
                  ...state,
                  isContactEmailValid: false,
                  isContactEmailFieldFocused: true,
                }));
              }}
              loading={emailValidation.validatingContactEmail}
              showIcon={
                !emailValidation.isContactEmailFieldFocused &&
                !(errors.contactEmail && emailValidation.isContactEmailValid)
              }
              icon={
                emailValidation.isContactEmailValid ? ValidIcon : InvalidIcon
              }
              iconColor={emailValidation.isContactEmailValid ? "green" : red100}
            />
          </>
        )}

        <Box px={2} mb={2}>
          <FormControlLabel
            control={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Checkbox
                color="default"
                checked={isCheck.terms}
                name="thryvTermsAgreementCheckbox"
                onChange={(event) => {
                  setIsCheck({ ...isCheck, terms: !isCheck.terms });
                  dtmSatelliteTrackField(event.target.name);
                }}
              />
            }
            label={thryvTerms()}
          />
        </Box>

        <Box px={2} className={grecaptchaBadge}>
          <ReCAPTCHA
            badge="inline"
            ref={recaptchaRef}
            size="invisible"
            sitekey={config.recaptchaKey}
          />
        </Box>

        <Box px={2} mb={2}>
          <FormControlLabel
            style={{ alignItems: "flex-start" }}
            control={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Checkbox
                style={{ paddingTop: 0 }}
                color="default"
                checked={isCheck.optIn}
                name="OptInCheckbox"
                onChange={(event) => {
                  setIsCheck({ ...isCheck, optIn: !isCheck.optIn });
                  dtmSatelliteTrackField(event.target.name);
                }}
              />
            }
            label={optInText()}
          />
        </Box>

        <Box display="flex" justifyContent="flex-end" p={2} pt={3}>
          <Button
            component={Box}
            className={submitButtonHoverDisabled}
            fullWidth
            name="createListingBtn"
            color="primary"
            onClick={() => submitForm()}
            disabled={isLoading || !enableCreateListing()}
          >
            {isLoading ? (
              <CircularProgress color="inherit" size={24} />
            ) : (
              "Create listing"
            )}
          </Button>
        </Box>

        <Box px={2}>
          <Fade in={isSubmitError} unmountOnExit>
            <StyledAlert
              severity="error"
              onClose={() => setIsSubmitError(!isSubmitError)}
            >
              <AlertTitle component={Box} pb={0}>
                Error
              </AlertTitle>
              <Typography variant="body2">
                Oops! Something went wrong. Please try again later or call{` `}
                <MuiLink
                  underline="always"
                  color="textPrimary"
                  href="tel:1800670722"
                >
                  1800 670 722
                </MuiLink>
              </Typography>
            </StyledAlert>
          </Fade>
        </Box>

        <Box px={2}>
          <Typography variant="h3">How we use your information</Typography>
          <Typography variant="body2">
            Your personal information will be collected when you press
            &quot;Create Listing&quot;. Your personal information will be used
            to process your request, verify your identity and to promote and
            market our services to you. For more information on how Thryv
            Australia will handle your personal information, please see our{" "}
            {` `}
            <MuiLink
              underline="always"
              color="textPrimary"
              href={`${config.thryvUrlPrivacy}`}
              target="_blank"
            >
              Privacy Policy
            </MuiLink>
          </Typography>
          <Typography variant="body2">
            This site is protected by reCAPTCHA and the Google{" "}
            <MuiLink
              underline="always"
              color="textPrimary"
              href={`${config.googlePolicyUrl}/privacy`}
              target="_blank"
            >
              Privacy Policy
            </MuiLink>{" "}
            and{" "}
            <MuiLink
              underline="always"
              color="textPrimary"
              href={`${config.googlePolicyUrl}/terms`}
              target="_blank"
            >
              Terms of Service
            </MuiLink>{" "}
            apply.
          </Typography>
          <Typography variant="h3">Key terms</Typography>
          <Typography variant="body2">
            1. A minimum period of 6 months applies to these products.
          </Typography>
          <Typography variant="body2">
            2. After the minimum period, your product will continue to be
            supplied at the latest price until cancelled.
          </Typography>
          <Typography variant="body2">
            3. You can cancel at any time in writing or by phoning us and we’ll
            action it within 14 days. Cancellation fees apply if you cancel
            during the minimum period.
          </Typography>
          <Typography variant="body2">
            4. You’re responsible for notifying us if you change your contact
            details.
          </Typography>
          <Typography variant="body2">
            5. Need to make changes to the content of your ad? Changes to Yellow
            Digital products can be made anytime, just get in touch.
          </Typography>
          <Typography variant="body2">
            6. The full terms that apply to this product can be found {` `}
            <MuiLink
              underline="always"
              color="textPrimary"
              href={`${config.thryvUrl}/terms-and-conditions`}
              target="_blank"
            >
              here
            </MuiLink>
            . The terms that apply to Direct Debit & Credit Card usage can be
            found {` `}
            <MuiLink
              underline="always"
              color="textPrimary"
              href={`${config.thryvUrl}/terms-and-conditions`}
              target="_blank"
            >
              here
            </MuiLink>
            .
          </Typography>
          <Typography variant="body2">
            7. You agree that content for your Yellow listing will be supplied
            by you through myAccount. If you have purchased the Yellow Online
            Silver or Gold pack and you have not completed and published the
            content for your ad after 10 days we’ll contact you to assist. If we
            can’t get in touch with you after a few attempts, we’ll design and
            publish your online product, potentially using any content you have
            loaded. This will start your product billing.
          </Typography>
          <Typography variant="body2">
            8. By purchasing this product, I confirm that I am authorised to
            purchase advertising for the business set out in the Listing Details
            above.
          </Typography>
        </Box>
      </FormContainer>
      <Helmet>
        <script type="text/javascript" src={config.oneTrustAutoBlock} />
        <script
          src={config.oneTrustSDKStub}
          type="text/javascript"
          charset="UTF-8"
          data-domain-script={config.oneTrustKey}
        />
        <script type="text/javascript">{`function OptanonWrapper() {}`}</script>
        <script async type="text/javascript" src={config.tempBanner} />
      </Helmet>
    </MainContent>
  );
};

export default withRouter(OnlineSignup);
