export function alphaColour(hexValue, percentage) {
  // Convert Hex with alpha values to RGBA()
  const red = parseInt(hexValue.substring(1, 3), 16);
  const green = parseInt(hexValue.substring(3, 5), 16);
  const blue = parseInt(hexValue.substring(5, 8), 16);

  return `rgba(${red}, ${green}, ${blue}, ${percentage / 100})`;
}

export default alphaColour;
