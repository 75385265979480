/* eslint-disable import/no-extraneous-dependencies */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "custom-event-polyfill";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore } from "redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import * as serviceWorker from "./serviceWorker";
import Application from "./components/common/App";
import { reducers } from "./redux/Reducers";
import ErrorBoundary from "./components/common/ErrorBoundary";
import theme from "./theme";

/* eslint-disable no-underscore-dangle */
function configureStore(initialState = {}) {
  const store = createStore(
    reducers,
    initialState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
  return store;
}
/* eslint-enable */

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ErrorBoundary>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Application />
        </ThemeProvider>
      </ErrorBoundary>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
