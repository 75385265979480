const eventNames = {
  MY_message_menu_click: "MY_message_menu_click",
  MY_message_tile_click: "MY_message_tile_click",
  MY_myaccount_menu_click: "MY_myaccount_menu_click",
  MY_myaccount_tile_click: "MY_myaccount_tile_click",
  MY_quote_menu_click: "MY_quote_menu_click",
  MY_quote_tile_click: "MY_quote_tile_click",
  MY_virtual_pageview: "MY_virtual_pageview",
  MY_M_view_click: "MY_M_view_click",
  MY_PO_menu_click: "MY_PO_menu_click",
  MY_PO_tile_click: "MY_PO_tile_click",
  MY_PO_comp_tile_click: "MY_PO_comp_tile_click",
  MY_PO_modal_learn_more_click: "MY_PO_modal_learn_more_click",
  MY_PO_modal_not_now_click: "MY_PO_modal_not_now_click",
  MY_PO_modal_unlock_lite_click: "MY_PO_modal_unlock_lite_click",
  MY_PO_tnc_checkbox_click: "MY_PO_tnc_checkbox_click",
  MY_PO_tnc_modal_cancel_click: "MY_PO_tnc_modal_cancel_click",
  MY_PO_tnc_modal_unlock_click: "MY_PO_tnc_modal_unlock_click",
  MY_Q_awaiting_response_tab_click: "MY_Q_awaiting_response_tab_click",
  MY_Q_expired_tab_click: "MY_Q_expired_tab_click",
  MY_Q_interested_tab_click: "MY_Q_interested_tab_click",
  MY_Q_not_interested_tab_click: "MY_Q_not_interested_tab_click",
  MY_Q_opp_click: "MY_Q_opp_click",
  MY_Q_quote_interested_click: "MY_Q_quote_interested_click",
  MY_Q_quote_phone_click: "MY_Q_quote_phone_click",
  MY_Q_quote_email_click: "MY_Q_quote_email_click",
  MY_Q_quote_message_click: "MY_Q_quote_message_click",
  MY_Q_quote_not_interested_click: "MY_Q_quote_not_interested_click",
  TEST_event: "TEST_event",
};

const pageName = (pathname) => {
  return `MY${pathname === "/" ? ":Home" : pathname.replace(/\//g, ":")}`;
};

const dtmEvent = (customEventId, contact, data) => {
  const user = contact
    ? {
        user: {
          accountId: contact.masterAccountId,
          contactId: contact.contactId,
        },
      }
    : null;

  const event = new CustomEvent(
    customEventId,
    {
      detail: {
        myYellowData: {
          ...(user && { ...user }),
          ...data,
          date: Date(),
        },
      },
    },
    { bubbles: false }
  );
  document.body.dispatchEvent(event);
  return event.detail;
};

const dtmLocationEvent = (prevLocation, currentLocation, contact) => {
  const data = {
    content: {
      pageURL: `my.yellow.com.au${currentLocation.pathname}`,
      ...(prevLocation && {
        previousPageURL: `my.yellow.com.au${prevLocation.pathname}`,
      }),
      pageName: pageName(currentLocation.pathname),
      ...(prevLocation && {
        previousPageName: pageName(prevLocation.pathname),
      }),
      contentLevel1:
        currentLocation.pathname === "/"
          ? "Home"
          : currentLocation.pathname.split("/")[1],
      contentLevel2: currentLocation.pathname.split("/")[2],
    },
  };

  dtmEvent(eventNames.MY_virtual_pageview, contact, data);
  return data;
};

const dtmSatelliteTrack = (event) => {
  // eslint-disable-next-line no-underscore-dangle
  if (window?._satellite) {
    // eslint-disable-next-line no-underscore-dangle
    window._satellite.track(event);
  }
};

export { dtmEvent, dtmLocationEvent, dtmSatelliteTrack, eventNames };
