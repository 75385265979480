import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  IconButton,
  Link as MuiLink,
  Typography,
} from "@material-ui/core";
import { Dehaze } from "@material-ui/icons";
import { useOktaAuth } from "../../../OktaFacade";
import { openInNewTab } from "../UrlUtils";
import useLocalStorageState from "../../hooks/useLocalStorageState";
import HeaderBase from "./HeaderBase";
import HeaderDropdown from "./HeaderDropdown";
import HeaderMenuLink from "./HeaderMenuLink";
import mobileLogo from "../../../images/logo-mobile.svg";
import tabletLogo from "../../../images/logo-text.svg";
import yellowOnlineSignUpLogo from "../../../images/yellow-logo-180x84.png";

const Header = ({ location, onToggle }) => {
  const user = useSelector((state) => state.session.user);
  const config = useSelector((state) => state.config);
  const feedbackLink = `${config.feedbackLink}?accountId=${user?.contact?.accounts?.[0]?.accountId}&contactId=${user?.contact?.contactId}`;
  const isPublicRoute = useSelector((state) => state.publicRoute.isPublicRoute);
  const { authService } = useOktaAuth();
  const userFullname =
    user && `${user.contact.firstName} ${user.contact.lastName}`;
  const [uberallRedirectUrl, setUberallRedirectUrl] = useLocalStorageState(
    "uberallRedirectUrl"
  );

  if (uberallRedirectUrl) {
    setUberallRedirectUrl("");
    window.location.href = uberallRedirectUrl;
  }

  // Conditionally include StubToggle so WebPack doesn't include it in a build
  const StubButton = () => {
    if (process.env.NODE_ENV !== "production") {
      // eslint-disable-next-line global-require
      const DevComponent = require("../StubButton").default;
      return <DevComponent />;
    }
    return null;
  };

  const logout = () => {
    authService.logout("/").then(() => {
      window.location = "/login";
    });
  };

  const loginHandler = () => {
    if (user && isPublicRoute) {
      authService.logout(location.pathname).catch(() => {
        console.warn(
          "Sign in while session is active - terminating the active user session."
        );
      });
    }
    openInNewTab("/login");
  };

  const isOnlineSignupPath = [
    "/online-signup",
    "/online-signup/",
    "/online-signup/success",
  ].includes(location.pathname);

  return (
    <Box component="nav" aria-label="Top Nav Bar">
      <HeaderBase location={location}>
        <Box display="flex" alignItems="center" height={54} p={0}>
          {!isOnlineSignupPath && (
            <>
              <Box display={{ sm: "block", md: "none" }}>
                <IconButton onClick={onToggle}>
                  <Dehaze />
                </IconButton>
              </Box>
              <Box display={{ sm: "block", md: "none" }}>
                <img src={mobileLogo} width={30} alt="yellow myYellow" />
              </Box>
              <Box display={{ xs: "none", sm: "block", md: "none" }}>
                <img src={tabletLogo} width={150} alt="yellow myYellow" />
              </Box>
            </>
          )}
          {isOnlineSignupPath && (
            <Box ml={2}>
              <a href="https://www.yellow.com.au/">
                <img
                  src={yellowOnlineSignUpLogo}
                  width={90}
                  alt="yellow myYellow"
                />
              </a>
            </Box>
          )}
          <Box ml={2}>
            <StubButton />
          </Box>
        </Box>
        {user && !isPublicRoute && (
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <HeaderMenuLink
              text="Feedback"
              href={feedbackLink}
              display={{ xs: "none", sm: "block" }}
              openInNewTab
            />
            <HeaderMenuLink
              text="FAQ"
              display={{ xs: "none", sm: "block" }}
              to="/faq"
            />
            <HeaderDropdown
              handleLogout={logout}
              userFullname={userFullname}
              accountName={user.contact.accountName}
              passwordUrl={config.passwordUrl}
            />
          </Box>
        )}
        {isPublicRoute && !isOnlineSignupPath && (
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <HeaderMenuLink
              text="About myYellow"
              href="https://www.yellow.com.au/myyellow-customer-portal"
              display={{ xs: "none", sm: "block" }}
              openInNewTab
            />
            <Box mx={2}>
              <Button variant="outlined" onClick={loginHandler}>
                Sign In
              </Button>
            </Box>
          </Box>
        )}

        {isPublicRoute && isOnlineSignupPath && (
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <HeaderMenuLink
              text="Need help? Call 1800 670 722"
              href="tel:1800670722"
            />
          </Box>
        )}
      </HeaderBase>
      {isPublicRoute && !isOnlineSignupPath && (
        <Box
          pt={2}
          pb={0.5}
          textAlign="center"
          display={{ xs: "block", sm: "none" }}
        >
          <Typography variant="body1" color="textPrimary">
            {`${"Find out about myYellow "} `}
            <MuiLink
              underline="always"
              color="textPrimary"
              href="https://www.yellow.com.au/myyellow-customer-portal"
              target="_blank"
            >
              here
            </MuiLink>
          </Typography>
        </Box>
      )}
    </Box>
  );
};
export default withRouter(Header);
