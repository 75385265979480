import { useState, useEffect } from "react";
import { Box, Button, Typography } from "sensis-material-ui";
import { IconButton, TextField as TextFieldMUI } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import HelpIcon from "@material-ui/icons/HelpOutlineRounded";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import MainContent from "../common/MainContent";
import FormContainer from "../common/FormContainer";
import { withGatewayApi } from "../../API";
import FeatureToggle from "../common/FeatureToggle";

const TextField = ({
  label,
  defaultValue,
  errorText,
  maxLength,
  multiline,
  onChange,
  readOnly,
  name,
}) => {
  const { transparentBorder } = makeStyles(() => ({
    transparentBorder: {
      border: "transparent",
    },
  }))();

  return (
    <Box p={2} width="100%">
      <TextFieldMUI
        className={transparentBorder}
        defaultValue={defaultValue}
        error={!!errorText}
        fullWidth
        helperText={errorText}
        InputProps={{
          readOnly,
          inputProps: {
            "aria-label": label,
            maxLength,
          },
        }}
        label={label}
        name={name}
        multiline={multiline}
        onChange={onChange}
        variant="outlined"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(multiline && { rows: 1, rowsMax: 2 })}
      />
    </Box>
  );
};

const AlertActionButton = ({ setSuccess }) => {
  return (
    <IconButton
      aria-label="close"
      color="inherit"
      size="small"
      onClick={() => {
        setSuccess(false);
      }}
    >
      <CloseIcon fontSize="inherit" />
    </IconButton>
  );
};

const Profile = ({ gatewayApi }) => {
  const user = useSelector((state) => state.session.user);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    if (user && user.contact) {
      setFirstName(user.contact.firstName);
      setLastName(user.contact.lastName);
      setEmail(user.contact.email);
      setPhone(user.contact.phone);
    }
  }, [user]);

  const handleSave = async () => {
    setSuccess(false);
    const { customerNumber, contactId } = user.contact;
    const response = await gatewayApi.post("/update-profile", {
      contactId,
      customerNumber,
      firstName,
      lastName,
      email,
      phone,
    });

    if (response && response.data.errors) {
      setError(response.data.errors);
    } else {
      setAlertMessage(response.data.message);
      setError(null);
      setSuccess(true);
    }
  };

  const { iconWithTop } = makeStyles(() => ({
    iconWithTop: {
      top: 5,
    },
  }))();

  return (
    <MainContent
      header={
        FeatureToggle({
          name: "featureEditProfile",
          children: "Edit my Profile",
        }) ||
        FeatureToggle({
          name: "featureEditProfile",
          children: "my Profile",
          featureHide: true,
        })
      }
    >
      {user && user.contact && (
        <FormContainer
          alertAction={<AlertActionButton setSuccess={setSuccess} />}
          alertMessage={alertMessage}
          alertSeverity="success"
          header="Personal details"
          showAlert={success}
        >
          <FeatureToggle name="featureEditProfile">
            <TextField
              label="First name"
              defaultValue={user.contact.firstName}
              errorText={error && error.firstName}
              maxLength={40}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
            />
            <TextField
              label="Last name"
              defaultValue={user.contact.lastName}
              errorText={error && error.lastName}
              maxLength={80}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
            />
            <TextField
              label="Email"
              defaultValue={user.contact.email}
              errorText={error && error.email}
              maxLength={255}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <Box px={2}>
              <Typography variant="body2" color="secondary" px={1}>
                <HelpIcon
                  className={iconWithTop}
                  color="secondary"
                  fontSize="small"
                />
                {`  FYI, if you have a White Pages account your update also applies
                to this.`}
              </Typography>
            </Box>
            <TextField
              label="Phone (Optional)"
              defaultValue={user.contact.phone}
              errorText={error && error.phone}
              maxLength={18}
              onChange={(e) => {
                setPhone(e.target.value);
              }}
            />
            <TextField
              label="Address"
              defaultValue={`${user.contact.mailingStreet}, ${user.contact.mailingCity} ${user.contact.mailingState} ${user.contact.mailingPostCode}`}
              multiline
              readOnly
            />
            <Box display="flex" justifyContent="flex-end" px={2}>
              <Button
                color="primary"
                onClick={handleSave}
                component={Box}
                width="25%"
              >
                Save
              </Button>
            </Box>
          </FeatureToggle>

          <FeatureToggle name="featureEditProfile" featureHide>
            <TextField
              label="First name"
              defaultValue={user.contact.firstName}
              readOnly
            />
            <TextField
              label="Last name"
              defaultValue={user.contact.lastName}
              readOnly
            />
            <TextField
              label="Email"
              defaultValue={user.contact.email}
              readOnly
            />
            <TextField
              label="Phone"
              defaultValue={user.contact.phone}
              readOnly
            />
            <TextField
              label="Address"
              defaultValue={`${user.contact.mailingStreet}, ${user.contact.mailingCity} ${user.contact.mailingState} ${user.contact.mailingPostCode}`}
              multiline
              readOnly
            />
          </FeatureToggle>
        </FormContainer>
      )}
    </MainContent>
  );
};

export default withGatewayApi(withRouter(Profile));
