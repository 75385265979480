/* eslint-disable react/no-unescaped-entities */
import { useEffect } from "react";
import { Box, Typography } from "sensis-material-ui";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MainContent from "./MainContent";
import { dtmLocationEvent } from "./AdobeDTMUtils";

export default function TermsOfUse({ location }) {
  useEffect(() => {
    dtmLocationEvent(null, location, null);
  }, [location]);

  const { greyBackground } = makeStyles((theme) => ({
    greyBackground: {
      backgroundColor: theme.custom.colour.grey700,
    },
  }))();

  return (
    <MainContent
      header="Terms of Use"
      loading={false}
      className={greyBackground}
    >
      <Paper
        className={greyBackground}
        elevation={0}
        component={Box}
        textAlign="justify"
        px={1.5}
      >
        <Typography variant="h2">Sensis myYellow</Typography>
        <Typography component={Box} variant="body2">
          The Sensis myYellow
          <a
            href="https://my.yellow.com.au"
            rel="noopener noreferrer"
            target="_blank"
          >
            {" "}
            https://my.yellow.com.au{" "}
          </a>
          ("
          <Box display="inline-block" fontWeight="700">
            Website
          </Box>
          ") is operated by Sensis Pty Ltd ("We", "Us", "Our" or "Sensis"). Your
          access to and use of the Website, and the services made available via
          the Website (which may include account information service, product
          self-service functionality, product performance reporting and access
          to CRM functionality) ("Services") are subject to these terms, Sensis’
          Privacy Policy (available at www.sensis.com.au/about/privacy), the
          Sensis Customer Terms (available at www.sensis.com.au/terms), and any
          other terms contained on this Website from time to time ("Terms of
          Use"), whether or not You become a registered user of the Website. By
          using this Website, You agree to be bound by the Terms of Use and you
          acknowledge that the Services offered may vary from time to time and
          are offered at Sensis’ discretion.
        </Typography>
        <Typography variant="body2">
          In these Terms of Use, “You” means the account holder on the relevant
          Sensis account and persons authorised by the account holder to submit
          enquiries, instructions and requests on the Website in relation that
          account. “Product” means any product that We offer to You on a paid or
          free basis in a print directory, or online, or on other properties or
          platforms, as determined by Us from time to time.
        </Typography>

        <Box>
          <Typography variant="subtitle2">
            1. Accessing the Website and Username
          </Typography>
          <Box pl={2}>
            <Typography variant="body2">
              1.1 Access to the Website and the Services will be via a security
              credential, which could include a username and password, a digital
              certificate or other secure technology ("Username").
            </Typography>
            <Typography variant="body2">
              1.2 The Username issued to You is for the purposes of accessing
              the Website and for authorising enquiries, instructions or
              requests in relation to the Services. Any other use of Your
              Username is prohibited. You are solely responsible for all
              enquiries, instructions or requests made via the Website using
              Your Username.
            </Typography>
          </Box>
        </Box>

        <Box>
          <Typography variant="subtitle2">
            2. Who may use the Services?
          </Typography>
          <Box pl={2}>
            <Typography variant="body2">
              2.1 You may use the Services if You have purchased an eligible
              Product, or You have claimed Your free listing (and you
              acknowledge that not all functionality may be available for free
              listings) and are the:
            </Typography>
            <Box pl={2}>
              <Typography variant="body2">
                a. account holder on the relevant account; or
              </Typography>
              <Typography variant="body2">
                b. authorised by the account holder to use some or all of the
                Product Services available on the Website.
              </Typography>
            </Box>
            <Typography variant="body2">
              2.2 If You are not the account holder, You:
            </Typography>
            <Box pl={2}>
              <Typography variant="body2">
                1. must be authorised to enter into these Terms of Use on behalf
                of the account holder and must use the Username only to access
                information which relates to the account;
              </Typography>
              <Typography variant="body2">
                2. can only use the Services that the account holder has
                authorised You to use from time to time and as notified to Us;
                and
              </Typography>
              <Typography variant="body2">
                3. understand that the account holder may limit or cancel Your
                ability to use the Services at any time by notifying Us. If that
                occurs, You may not be able to submit enquiries, instructions or
                requests in relation to the account.
              </Typography>
            </Box>
            <Typography variant="body2">
              2.3 If You authorise another person to access Your account using
              Your Username, You understand that that person will be able to use
              all the Services on Your account that You are authorised to use.
            </Typography>
            <Typography variant="body2">
              2.4 We are entitled to rely on Your Username as conclusive
              evidence of Your identity and authority to act on the account
              without further investigation. In addition, We are entitled to
              assume that all use of Your Username is by You or Your authorised
              representative, unless You tell Us otherwise in accordance with
              these Terms of Use. If circumstances arise such that We believe
              that You are not the account holder or a person authorised by the
              account holder to act on the account, We will not act upon Your
              enquiry, instruction or request until We receive account holder
              approval or verification.
            </Typography>
          </Box>
        </Box>

        <Box>
          <Typography variant="subtitle2">3. Your Content </Typography>
          <Box pl={2}>
            <Typography variant="body2">
              3.1 It is Your responsibility to ensure that You are authorised to
              publish any content uploaded via the Website and that Your content
              is current, accurate, free from typographical errors, and
              consistent with Your obligations under the Sensis Customer Terms.
            </Typography>
          </Box>
        </Box>

        <Box>
          <Typography variant="subtitle2">
            4. Digital Product changes
          </Typography>
          <Box pl={2}>
            <Typography variant="body2">
              4.1 The Website and Yellow Pages® Digital sites may not always be
              synchronised. From time to time:
            </Typography>
            <Box pl={2}>
              <Typography variant="body2">
                a. the Website may show additional content fields that do not
                appear on the Yellow Pages® Digital sites; and/or
              </Typography>
              <Typography variant="body2">
                b. the Yellow Pages® Digital sites may show a more recent
                version of Your advertising than appears on the Website (or vice
                versa), due to change processing workflows and delays in data
                feeds.
              </Typography>
            </Box>
            <Typography variant="body2">
              4.2 The timeframes for processing Your change requests will vary
              according to the Product You have purchased, the relevant content
              attribute type and the heading/classification in which Your
              advertising appears. We will tell You the total maximum estimated
              timeframe for making all of Your requested changes before You
              submit them via the Website. However, changes requests for each
              content type are processed individually; this means that some of
              Your requested changes may go live or appear on the site before
              Your other changes have been processed.
            </Typography>
            <Typography variant="body2">
              4.3 You acknowledge that any advertising preview function on the
              Website provides an indicative preview of Your Digital Products
              only, and may not contain all of Your content or the functionality
              offered by Your Digital Products.
            </Typography>
          </Box>
        </Box>

        <Box>
          <Typography variant="subtitle2">5 . What You must not do</Typography>
          <Box pl={2}>
            <Typography variant="body2">5.1 You must not:</Typography>
            <Box pl={2}>
              <Typography variant="body2">
                a. use the Website or the Services in a way which interferes
                with other users or restricts any person or which inhibits any
                other user from using the Website or the Services;
              </Typography>
              <Typography variant="body2">
                b. use the Website or the Services in a way that breaches the
                terms of Your advertising contract with Us (including the Sensis
                Customer Terms) or any laws or regulations or infringes a third
                party's rights or privacy;
              </Typography>
              <Typography variant="body2">
                c. use another person's name, username or Username without
                permission or impersonate another person;
              </Typography>
              <Typography variant="body2">
                d. provide false information when registering or changing Your
                registration details;
              </Typography>
              <Typography variant="body2">
                e. tamper with, hinder the operation of or make unauthorised
                modifications to the Website or the Services;
              </Typography>
              <Typography variant="body2">
                f. knowingly transmit any virus or other disabling feature to
                the Website or the Services; and
              </Typography>
              <Typography variant="body2">
                g. attempt any of the above acts or permit another person to do
                any of the above acts.{" "}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box>
          <Typography variant="subtitle2">6. Security</Typography>
          <Box pl={2}>
            <Typography variant="body2">
              6.1 For security reasons We may require You to re-authenticate
              Yourself from time to time, for example after a period of
              inactivity on the connection between Your browser and the Website
              or the Services’ servers. We are not responsible for any
              information You may lose if the Website or the Services’ servers
              terminate Your browser session due to prolonged periods of
              inactivity between Your browser and the Website or the Services’
              servers.
            </Typography>
            <Typography variant="body2">
              6.2 You must comply with all directions issued by Us pertaining to
              use of Your Username and access to the Website or the Services.
              8.4 You must keep Your Username safe. You must not keep Your
              Username in any form, whether encoded or unencoded, in a location
              where it is capable of being copied or used by any person, or
              disclose Your Username to any other person, other than:
            </Typography>
            <Box pl={2}>
              <Typography variant="body2">
                1. Yourself - where You are acting as the account holder on Your
                own behalf; or{" "}
              </Typography>
              <Typography variant="body2">
                2. another person - where You are acting as an authorised
                representative of the account holder, provided that such other
                person agrees to be bound by these Terms of Use.
              </Typography>
            </Box>
            <Typography variant="body2">
              6.3 You must immediately notify Us if You become aware:
            </Typography>
            <Box pl={2}>
              <Typography variant="body2">
                1. that Your Username has been compromised or is known to a
                third party; or{" "}
              </Typography>
              <Typography variant="body2">
                2. of any unauthorised use of Your Username. We will then take
                steps to address the issue, for example by disabling and then
                enabling Your account with a new password, as soon as
                practicable.
              </Typography>
            </Box>
            <Typography variant="body2">
              6.4 Except as provided under clause 13 below, We do not guarantee:
            </Typography>
            <Box pl={2}>
              <Typography variant="body2">
                1. the security of the Website, the Services or any systems
                (including the Internet and Your hardware and software) used in
                accessing the Website and/or Services, or any information passed
                through such systems; or
              </Typography>
              <Typography variant="body2">
                2. access to the Website, the Services or any systems used in
                accessing the Website and the Services will be continuous or
                virus or error free.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box>
          <Typography variant="subtitle2">7. Privacy</Typography>
          <Box pl={2}>
            <Typography variant="body2">
              7.1 We collect, use and disclose personal information about You in
              accordance with Our Privacy Statement at
              www.sensis.com.au/about/privacy
            </Typography>
            <Typography variant="body2">
              7.2 We may provide and/or display personal information about You
              as part of the Services and, as a result, You must not disclose or
              permit the disclosure of Your Username to any other person that
              may result in Your privacy being breached.
            </Typography>
            <Typography variant="body2">
              7.3 You must not breach or permit a breach of any other person's
              privacy in using the Services, including without limitation using
              another person's Username.
            </Typography>
          </Box>
        </Box>

        <Box>
          <Typography variant="subtitle2">
            8. Customer Communications
          </Typography>
          <Box pl={2}>
            <Typography variant="body2">
              8.1 You acknowledge that where the Services require Us to email
              You, the following will apply:
            </Typography>
            <Box pl={2}>
              <Typography variant="body2">
                1. We will address and send emails to You to the email address
                provided by You;
              </Typography>
              <Typography variant="body2">
                2. You are solely responsible for ensuring that Your contact
                details for the Services are current (including email address),
                Your email service is operational and that You check Your emails
                regularly;
              </Typography>
              <Typography variant="body2">
                3. You must promptly notify Us of any changes to Your Services
                details using the method provided by Us as soon as possible;
              </Typography>
              <Typography variant="body2">
                4. if We send an email to Your current email address as listed
                in Your Services contact details, You are deemed to have
                received that email and We are not obliged to take any further
                action to confirm that You have received, opened and/or read the
                email unless You promptly notify Us and demonstrate that the
                email was not delivered to Your email address; and
              </Typography>
              <Typography variant="body2">
                5.if We receive an automated email non-delivery notification
                indicating You have not received the email that We have sent You
                containing or regarding an order for a Service, We will use
                reasonable endeavours to attempt to contact You using the other
                contact details that You submitted in Your Services contact
                details, including non- electronic means, but We do not
                guarantee that We will be able to contact You.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box>
          <Typography variant="subtitle2">9. Billing</Typography>
          <Box pl={2}>
            <Typography variant="body2">
              9.1 You acknowledge that any invoices or adjustment notes that can
              be viewed on the Website are copies of tax invoices or adjustment
              notes previously issued to You.
            </Typography>
          </Box>
        </Box>

        <Box>
          <Typography variant="subtitle2">10. Indemnity</Typography>
          <Box pl={2}>
            <Typography variant="body2">
              10.1 You indemnify Us and Our officers, employees, agents and
              related bodies corporate from and against all actions, claims,
              suits, demands, damages, liabilities, costs or expenses arising
              out of or in any way connected to the use of the Website or the
              Services by You or someone using Your Username.
            </Typography>
          </Box>
        </Box>

        <Box>
          <Typography variant="subtitle2">
            11. Limitation of Liability
          </Typography>
          <Box pl={2}>
            <Typography variant="body2">
              11.1 Except for the express warranties set out in these Terms of
              Use, any terms implied by law and Your rights under statutory
              guarantees provided under consumer protection laws, We provide no
              warranties or guarantees to You (including in relation to Our
              provision of the Website or Services to You, the performance of
              the Website or Services or in connection with any products,
              services or information supplied, offered to be supplied or
              advertised via the Website or the Services).
            </Typography>
            <Typography variant="body2">
              11.2 While We will use due care and skill in relation to the
              provision of the Website and Services, We do not warrant or
              guarantee that the Website or Services will be free from errors or
              omissions.
            </Typography>
            <Typography variant="body2">
              11.3 If We fail to comply with a statutory guarantee that applies
              to the Website or Services under consumer protection laws, then We
              and Our officers, employees, agents or related bodies corporate
              limit Our liability for that failure (where it is fair and
              reasonable to do so) to supply of the Services again, free of
              charge to You.
            </Typography>
            <Typography variant="body2">
              11.4 You agree that, apart from Your rights under clauses 11.1 and
              11.3 of these Terms of Use neither We nor any of Our officers,
              employees, agents or related bodies corporate will be liable in
              any way for any loss, damage, claim or demand incurred or made by
              any person (whether based in tort (including negligence),
              contract, statute or otherwise) arising out of or in connection
              with the Website, including from provision of the Services, or
              failure to provide the Services or from exercising Our rights in
              relation to the Website.
            </Typography>
            <Typography variant="body2">
              11.5 Without limiting clause 11.4 of these Terms of Use:
            </Typography>
            <Box pl={2}>
              <Typography variant="body2">
                1. We have no liability for any failure or delay in performing
                an obligation under these Terms of Use due to matters outside
                Our reasonable control or to the extent it is caused by You or
                results from Your failure to take reasonable steps to avoid or
                minimise Your loss; and
              </Typography>
              <Typography variant="body2">
                2. We are not liable for loss or damage that was not reasonably
                foreseeable.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box>
          <Typography variant="subtitle2">12. Changes </Typography>
          <Box pl={2}>
            <Typography variant="body2">
              12.1 We may vary any of these Terms of Use, the functionality of
              the Services or any systems (including the Internet and Your
              hardware and software) used in accessing the Website and/or
              Services, and any other information relating to the Services
              and/or the Website at any time and without notice to You. Your
              subsequent use of Your Username and continued use of the Website
              will be deemed acceptance of the varied Terms of Use.
            </Typography>
          </Box>
        </Box>

        <Box>
          <Typography variant="subtitle2">13. Termination</Typography>
          <Box pl={2}>
            <Typography variant="body2">
              13.1 If, in Our reasonable opinion, You breach any of these Terms
              of Use, We may suspend, terminate or limit Your access to the
              Website and/or the Services immediately
            </Typography>
            <Typography variant="body2">
              13.2 We reserve the right to discontinue one or more of the
              Services or this Website at any time for any reason and without
              notice to You.
            </Typography>
          </Box>
        </Box>

        <Box>
          <Typography variant="subtitle2">14. General matters</Typography>
          <Box pl={2}>
            <Typography variant="body2">
              14.1 These Terms of Use are governed by the law in force in the
              State of Victoria, Australia, and the parties irrevocably submit
              to the nonexclusive jurisdiction of the courts of Victoria,
              Australia and courts of appeal from them for determining any
              dispute concerning these Terms of Use.
            </Typography>
            <Typography variant="body2">
              14.2 If any of these Terms of Use are invalid or unenforceable, it
              will be struck out and the remaining terms will remain in force.
            </Typography>
            <Typography variant="body2">
              14.3 If We do not act in relation to a breach by You of these
              Terms of Use, this does not waive Our right to act with respect to
              subsequent or similar breaches.
            </Typography>
            <Typography variant="body2">
              14.4 You may not assign or transfer Your rights or benefits under
              these Terms of Use to any other person or entity without Our prior
              written consent.
            </Typography>
          </Box>
        </Box>

        <Typography variant="body2">
          SENSIS, the Sensis logo, White Pages, Yellow Pages, Whereis, and
          Truelocal, among other marks, are registered trade marks of Sensis Pty
          Ltd (ABN 30 007 423 912). © Sensis Pty Ltd, 2019. All rights reserved.
        </Typography>
      </Paper>
    </MainContent>
  );
}
