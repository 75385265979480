import { useState, useRef, useEffect } from "react";
import { ArrowDropDown, ArrowDropUp, Person } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  ClickAwayListener,
  Grow,
  MenuList,
  Paper,
  Popper,
} from "@material-ui/core";
import HeaderMenuItem from "./HeaderMenuItem";
import Avatar from "../Avatar";
import { alphaColour } from "../ColourUtils";
import FeatureToggle from "../FeatureToggle";

export default function HeaderDropdown({
  handleLogout,
  accountName,
  userFullname,
  logoutLink,
  homeLink,
  passwordUrl,
}) {
  const { dropdown, listItem } = makeStyles((theme) => ({
    dropdown: {
      paddingInlineStart: 0,
    },
    listItem: {
      cursor: "pointer",
      color: theme.custom.colour.black,
      margin: 0,
      padding: "0 20px",
      "&:hover": {
        background: `${alphaColour(theme.custom.colour.black, 3)}`,
        color: `${alphaColour(theme.custom.colour.black, 95)}`,
      },
      height: 60,
    },
  }))();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Box height="100%">
      <Box
        role="button"
        display="flex"
        flexDirection="row"
        onClick={handleToggle}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        alignItems="center"
        tabIndex="0"
        ref={anchorRef}
        className={listItem}
      >
        {!handleLogout ? <Person /> : <Avatar name={userFullname} />}

        {open ? <ArrowDropUp /> : <ArrowDropDown />}
      </Box>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                  className={dropdown}
                >
                  {!homeLink && <HeaderMenuItem disabled text={accountName} />}
                  {homeLink && (
                    <Box display={{ xs: "block", sm: "none" }}>
                      <HeaderMenuItem
                        text="Home"
                        href={homeLink}
                        onClick={handleClose}
                      />
                    </Box>
                  )}
                  {!homeLink && (
                    <HeaderMenuItem
                      text="My Profile"
                      to="/profile"
                      onClick={handleClose}
                    />
                  )}

                  <FeatureToggle name="featureChangePassword">
                    <HeaderMenuItem
                      text="Update my password"
                      to="/change-password"
                      onClick={handleClose}
                    />
                  </FeatureToggle>

                  <FeatureToggle name="featureChangePassword" featureHide>
                    <HeaderMenuItem
                      text="Update my password"
                      href={`${passwordUrl}/myaccount/#/change-password`}
                      onClick={handleClose}
                    />
                  </FeatureToggle>

                  <FeatureToggle name="featureChangeSecurityQuestion">
                    <HeaderMenuItem
                      text="Update my secret question"
                      to="/change-security-question"
                      onClick={handleClose}
                    />
                  </FeatureToggle>

                  <FeatureToggle
                    name="featureChangeSecurityQuestion"
                    featureHide
                  >
                    <HeaderMenuItem
                      text="Update my secret question"
                      href={`${passwordUrl}/myaccount/#/change-security-question`}
                      onClick={handleClose}
                    />
                  </FeatureToggle>

                  <HeaderMenuItem
                    text="Logout"
                    href={logoutLink || null}
                    onClick={handleLogout || handleClose}
                  />
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
}
