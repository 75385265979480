import { MenuItem, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const HeaderMenuItem = ({
  onClick,
  underline,
  href,
  to,
  openInNewTab,
  disabled,
  text,
  autoFocus,
}) => {
  const { menuItem } = makeStyles((theme) => ({
    menuItem: {
      paddingTop: 8,
      paddingBottom: 8,
      "&:hover": {
        color: theme.custom.colour.grey100,
      },
    },
  }))();

  const routerLink = () => {
    if (process.env.REACT_APP_IS_UBERALL_HEADER === "false") {
      // eslint-disable-next-line global-require
      const RouterLink = require("react-router-dom").Link;
      return RouterLink;
    }
    return null;
  };

  if (to) {
    return (
      <MenuItem
        className={menuItem}
        component={routerLink()}
        to={to}
        onClick={onClick}
        autoFocus={autoFocus}
      >
        {text}
      </MenuItem>
    );
  }

  if (href) {
    return (
      <MenuItem
        className={menuItem}
        component={Link}
        href={href}
        underline={underline ? "always" : "none"}
        onClick={onClick}
        target={openInNewTab ? "_blank" : null}
        disabled={disabled}
        autoFocus={autoFocus}
      >
        {text}
      </MenuItem>
    );
  }

  return (
    <MenuItem
      className={menuItem}
      onClick={onClick}
      disabled={disabled}
      autoFocus={autoFocus}
    >
      {text}
    </MenuItem>
  );
};

export default HeaderMenuItem;
