import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box } from "sensis-material-ui";
import {
  Divider,
  CircularProgress,
  Container,
  Typography,
} from "@material-ui/core";

const MainContent = withRouter(
  ({ header, description, children, className = "", loading = true }) => {
    const isLoading = loading && useSelector((state) => state.loader.isLoading);
    return (
      <Box
        component={Container}
        className={className}
        maxWidth="md"
        mt={3}
        flex="1"
        mui
        minHeight="80%"
      >
        {isLoading && (
          <Box flex="1 100%" px={1} textAlign="center" pt={20}>
            <CircularProgress />
          </Box>
        )}

        {!isLoading && (
          <>
            <Box flex="1 100%" px={1}>
              <Typography variant="h1">{header}</Typography>
              {description && (
                <Typography component={Box} variant="body1">
                  {description}
                </Typography>
              )}
            </Box>
            <Divider />
            <Box p={0} mt={1} flex="1">
              {children}
            </Box>
          </>
        )}
      </Box>
    );
  }
);

export default MainContent;
