import { useEffect } from "react";
import { Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

export default function PublicRoute({ path, component }) {
  const dispatch = useDispatch();
  const isPublicRoute = useSelector((state) => state.publicRoute.isPublicRoute);

  useEffect(() => {
    dispatch({
      type: "SET_PUBLIC_ROUTE",
      publicRoute: { isPublicRoute: true },
    });

    // Dispatch to reset the state upon leaving the page
    return () => {
      dispatch({
        type: "SET_PUBLIC_ROUTE",
        publicRoute: { isPublicRoute: false },
      });
    };
  }, [dispatch]);

  const renderWrapper = (renderProps) => {
    // While the state is not known don't render anything
    if (!isPublicRoute) {
      return null;
    }

    const Component = component;
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Component {...renderProps} />;
  };

  return <Route exact path={path} render={renderWrapper} />;
}
