import { useSelector } from "react-redux";
import { Box, Button, Typography } from "sensis-material-ui";
import Badge from "@material-ui/core/Badge";
import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import { openInNewTab } from "../common/UrlUtils";
import { dtmEvent, eventNames } from "../common/AdobeDTMUtils";
import MainContent from "../common/MainContent";
import myaccountLogo from "../../images/myaccount.png";
import quotesLogo from "../../images/quotes.svg";
import pocketOfficeLogo from "../../images/pocket-office.svg";
import yellowConnectImg from "../../images/connect-illustration.svg";
import OpenPocketOffice from "./OpenPocketOffice";

const TileContent = ({ title, description, logo, badgeCount }) => {
  const { white, blue100 } = useTheme().custom.colour;
  const { badge } = makeStyles((theme) => ({
    badge: {
      "& span": {
        backgroundColor: blue100,
        color: white,
      },
    },
    bluePaper: {
      backgroundColor: theme.palette.info.main,
      color: white,
      position: "absolute",
      right: 0,
      top: 0,
    },
  }))();

  return (
    <Box
      bgcolor={white}
      p={2}
      component={Paper}
      minHeight={220}
      display="flex"
      mui
    >
      <Box
        display="flex"
        position="relative"
        flexDirection="row-reverse"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Box maxWidth="50%">
          <img src={logo} alt={title} style={{ width: "100%" }} />
        </Box>
        <Box mr={4}>
          <Box display="flex" alignItems="center" mb={1}>
            <Box display={{ xs: "block", md: "none" }} mui>
              <Typography variant="h3">{title}</Typography>
            </Box>
            <Box display={{ xs: "none", md: "block" }} mui>
              <Typography variant="h2" pt={1} pb={0.5} fontWeight={300}>
                {title}
              </Typography>
            </Box>
            {badgeCount > 0 && (
              <Box ml={2}>
                <Badge badgeContent={badgeCount} className={badge} />
              </Box>
            )}
          </Box>
          <Box mb={1}>
            <Typography variant="body2">{description}</Typography>
          </Box>
          <Box width={140} display={{ xs: "none", sm: "block" }} mui>
            <Button color="secondary">Take me there</Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const Tile = ({
  onClickHandler,
  href = "",
  title,
  description,
  logo,
  badgeCount,
  noLink,
}) => {
  const theme = useTheme();
  const { card } = makeStyles({
    card: {
      "&:hover": {
        boxShadow: theme.shadows[4],
      },
    },
  })();

  const margin = noLink ? 0 : 1.5;
  return (
    <Box
      width={{ xs: "100%", sm: `${noLink ? "100%" : "46%"}` }}
      className={card}
      m={{ sm: margin }}
      mb={{ xs: 3, sm: margin }}
      mui
    >
      {noLink ? (
        <TileContent
          title={title}
          description={description}
          logo={logo}
          badgeCount={badgeCount}
        />
      ) : (
        <Link
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...(href && { href })}
          onClick={onClickHandler}
          underline="none"
          display="block"
          aria-label={title}
        >
          <TileContent
            title={title}
            description={description}
            logo={logo}
            badgeCount={badgeCount}
          />
        </Link>
      )}
    </Box>
  );
};

const Home = () => {
  const user = useSelector((state) => state.session.user);
  const quotes = useSelector((state) => state.quotes);
  const config = useSelector((state) => state.config);
  const pocketOffice = useSelector((state) => state.pocketOffice);
  const yellowConnect = useSelector((state) => state.yellowConnect);
  const isPocketEligible = ["pending", "live"].includes(pocketOffice.status);

  const onQuoteTileClick = () => {
    dtmEvent(eventNames.MY_quote_tile_click, user.contact);
  };

  const onMyAccountTileClick = () => {
    openInNewTab(config.myAccountUrl);
    dtmEvent(eventNames.MY_myaccount_tile_click, user.contact);
  };

  return (
    <MainContent
      header={`Welcome back ${user ? user.contact.firstName : ""},`}
      description="myYellow is the new central place where you can manage your Yellow
                                    products, respond to customers messages and reviews, view reporting,
                                    pay invoices, and much more."
    >
      <Box display="flex" flexWrap="wrap" justifyContent="flex-start" mui>
        {yellowConnect.status && (
          <Tile
            title="Yellow Connect"
            description="Schedule your social posts and monitor online reviews"
            logo={yellowConnectImg}
            href={`${config.uberall}/yc`}
          />
        )}

        {quotes.isEligible && (
          <Tile
            onClickHandler={onQuoteTileClick}
            href="/quotes/awaiting-response"
            title="Quotes"
            description="View and respond to all of your quote requests"
            logo={quotesLogo}
            badgeCount={quotes.awaiting_response}
          />
        )}

        {isPocketEligible && (
          <OpenPocketOffice
            learnMorePOTabUrl={config.learnMorePOTabUrl}
            cid={user.contact.contactId}
            dtmEventId={eventNames.MY_PO_tile_click}
          >
            <Box width={{ xs: "100%", sm: "46%" }} m={{ sm: 1.5 }} mui>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a role="button" style={{ width: "100%" }}>
                <Tile
                  title="Pocket Office"
                  description="View your calendar, bookings, customers and issue invoices"
                  logo={pocketOfficeLogo}
                  noLink
                />
              </a>
            </Box>
          </OpenPocketOffice>
        )}

        <Tile
          onClickHandler={onMyAccountTileClick}
          title="myAccount"
          description="View your reporting, invoices and manage your content"
          logo={myaccountLogo}
        />
      </Box>
    </MainContent>
  );
};

export default Home;
