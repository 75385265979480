/* eslint-disable import/no-mutable-exports */
/* eslint-disable global-require */
export let withAuth;
export let withOktaAuth;
export let useOktaAuth;
export let Security;
export let SecureRoute;
export let ImplicitCallback;
export let LoginCallback;

let StubButton;

if (process.env.NODE_ENV !== "production") {
  StubButton = require("./components/common/StubButton").default;

  if (StubButton.enabled()) {
    withAuth = require("./OktaMock").withAuth;
    withOktaAuth = require("./OktaMock").withOktaAuth;
    useOktaAuth = require("./OktaMock").useOktaAuth;
    Security = require("./OktaMock").Security;
    SecureRoute = require("./OktaMock").SecureRoute;
    ImplicitCallback = require("./OktaMock").ImplicitCallback;
    LoginCallback = require("./OktaMock").LoginCallback;
  }
}

if (process.env.NODE_ENV === "production" || !StubButton.enabled()) {
  withAuth = require("@okta/okta-react").withAuth;
  withOktaAuth = require("@okta/okta-react").withOktaAuth;
  useOktaAuth = require("@okta/okta-react").useOktaAuth;
  Security = require("@okta/okta-react").Security;
  SecureRoute = require("@okta/okta-react").SecureRoute;
  ImplicitCallback = require("@okta/okta-react").ImplicitCallback;
  LoginCallback = require("@okta/okta-react").LoginCallback;
}
