/* eslint-disable no-shadow */
import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Typography,
  CircularProgress,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { makeCustomerNumber } from "../common/CustomerNumberUtils";
import { formatDate, expiresInWords } from "../common/DateUtils";
import { formatType } from "../common/QuoteUtils";
import { withGatewayApi } from "../../API";
import { getQuotesCount, getQuotesIsEligible } from "../../redux/Actions";
import { dtmEvent, eventNames } from "../common/AdobeDTMUtils";
import noResultImg from "../../images/no-result.svg";
import Dialog from "../common/Dialog";
import { useBreakpointOnly, useBreakpointUp } from "../common/MediaQueryUtil";

const pageSize = 10;

const titles = {
  computer: {
    requestedBy: "From",
    receivedBy: "Received",
    type: "Type",
    acceptedOn: "Accepted",
    declined: "Declined",
    expired: "Expired",
    expiresIn: "Expires In",
    relatedListing: "Related Listing",
  },
  tablet: {
    requestedBy: "From",
    receivedBy: "Received",
    type: "Type",
    acceptedOn: "Accepted",
    declined: "Declined",
    expired: "Expired",
    expiresIn: "Expires In",
    relatedListing: "Related Listing",
  },
};

const QuoteListTabPane = ({ gatewayApi, history, status }) => {
  const user = useSelector((state) => state.session.user);
  const { grey600 } = useTheme().custom.colour;
  const useStyles = makeStyles({
    tableHead: {
      backgroundColor: grey600,
    },
    tableUnread: {
      fontWeight: "bold",
    },
  });

  const classes = useStyles();

  const handleRowClick = (selectedQuote) => {
    dtmEvent(eventNames.MY_Q_opp_click, user.contact, {
      leadId: selectedQuote.leadId,
    });
    history.push(`/quote/${selectedQuote.businessId}/${selectedQuote.leadId}`);
  };

  const infoModalType = () => {
    return (
      <Dialog
        header="Request types explained"
        trigger={<HelpOutlineIcon fontSize="small" />}
        closeButton
      >
        <Box px={2}>
          <Typography variant="body2">
            Quote requests on Yellow come in two forms. Those that are requested
            directly via your Listing on yellowpages.com.au <b>(Direct)</b>, an2
            those that are distributed between similar businesses{" "}
            <b>(Shared)</b>.
          </Typography>
          <b>Direct quote requests</b>
          <Typography variant="body2">
            Only you will see these quote requests. Although these direct quote
            requests do not expire, we encourage you to respond to them as soon
            as possible to ensure you don’t miss out on a potential customer.
          </Typography>
          <b>Shared quote requests</b>
          <Typography variant="body2">
            Some quote requests from yellowpages.com.au are distributed between
            multiple businesses that perform a particular service in a
            particular location. These quote requests have a 24 hour expiry
            (longer on weekends), so it’s important that you view and respond to
            these within that timeframe to ensure you don’t miss out on a
            potential customer.
          </Typography>
        </Box>
      </Dialog>
    );
  };

  const infoModalExpiresIn = () => {
    return (
      <Dialog
        header="Expires in explained"
        trigger={<HelpOutlineIcon />}
        closeButton
      >
        <Box px={2}>
          <Typography variant="body2">
            If a Quote Opportunity has been distributed across multiple
            businesses, you have a set time limit in which to respond to the
            customer. The <b>Expires in</b> field shows the amount of time you
            have left to respond, before the Quote Opportunity expires.
          </Typography>
          <Typography variant="body2">
            Once a Quote Opportunity has Expired, you won’t be able to respond
            to the customer or view their contact details.
          </Typography>
          <Typography variant="body2">
            These Shared Quote Opportunities have a 24 hour expiry (longer on
            weekends), so it’s important that you view and respond to these
            within that timeframe to ensure you don’t miss out on a potential
            customer.
          </Typography>
        </Box>
      </Dialog>
    );
  };

  const infoModalRelatedListing = () => {
    return (
      <Dialog
        header="Related listing explained"
        trigger={<HelpOutlineIcon fontSize="small" />}
        closeButton
      >
        <Box px={2}>
          <Typography variant="body2">
            This is the Business Name, Location and Postcode of your
            <b> yellowpages.com.au</b> listing that was selected for this Quote
            Opportunity.
          </Typography>
          <Typography variant="body2">
            Your listing was selected for this Quote Opportunity because the
            Category and Location is a good match for the Quote that the
            customer is requesting.
          </Typography>
        </Box>
      </Dialog>
    );
  };

  const infoModalAccepted = () => {
    return (
      <Dialog
        header="Accepted explained"
        trigger={<HelpOutlineIcon fontSize="small" />}
        closeButton
      >
        <Box px={2}>
          <Typography variant="body2">
            This is the date and time at which you indicated you were Interested
            in a Quote Opportunity. The customer has been notified by email that
            you are interested.
          </Typography>
        </Box>
      </Dialog>
    );
  };

  const [allQuotes, setAllQuotes] = useState(null);
  const [pageQuotes, setPageQuotes] = useState(null);
  const [pageNumber, setPageNo] = useState(null);
  const [pageIndex, setPageIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const isTabletOnly = useBreakpointOnly("sm");
  const isTabletUp = useBreakpointUp("sm");
  const dispatch = useDispatch();

  const loadQuotes = async () => {
    if (isLoading && user && !allQuotes) {
      const customCustomerNumber = makeCustomerNumber(
        user.contact.customerNumber,
        user.contact.apfId
      );
      const quotes = await gatewayApi.get(
        `/customer/${customCustomerNumber}/quotes?detail=list&status=${status}`,
        () => {
          console.warn("API Gateway error: unable to retrieve quotes list");
          gatewayApi.errorHandler(true);
        }
      );

      if (quotes) {
        setIsLoading(false);
        setAllQuotes(quotes.data.quoteListings);
      }
    }
  };

  const onPageChange = (newPageNo) => {
    const startIndex = (newPageNo - 1) * pageSize;
    setPageIndex(startIndex);
    const endIndex = startIndex + pageSize;
    const newQuotes = allQuotes && allQuotes.slice(startIndex, endIndex);
    setPageQuotes(newQuotes);
    setPageNo(newPageNo);
  };

  useEffect(() => {
    onPageChange(pageNumber || 1);
    loadQuotes();
    /* istanbul ignore next */
    // TODO: This is not being triggered, need to refactor to update
    // quotes count when tab click
    if (isLoading && allQuotes) {
      (async function anyNameFunction() {
        const customCustomerNumber = makeCustomerNumber(
          user.contact.customerNumber,
          user.contact.apfId
        );
        const quotesCount = await gatewayApi.get(
          `/customer/${customCustomerNumber}/quotes?detail=count`
        );
        if (quotesCount) {
          dispatch(getQuotesCount(quotesCount.data));
          dispatch(getQuotesIsEligible(quotesCount.data));
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allQuotes]);

  const showingResultsText = () => {
    const text =
      allQuotes.length === 1
        ? "Showing 1 result"
        : `Showing ${pageIndex + 1}-${pageIndex + pageQuotes.length} of ${
            allQuotes.length
          } results`;
    return allQuotes.length > 0 && text;
  };

  const renderPagination = () => {
    return (
      pageQuotes &&
      pageQuotes.length > 0 && (
        <Box display="flex">
          <Box flex="1 100%" display={{ xs: "none", sm: "flex" }}>
            <Typography variant="body1">{showingResultsText()}</Typography>
          </Box>

          {allQuotes.length > pageSize && (
            <Box
              display="flex"
              flex="1 100%"
              justifyContent={{ xs: "center", sm: "flex-end" }}
            >
              <Pagination
                variant="outlined"
                shape="rounded"
                count={Math.ceil(allQuotes.length / pageSize)}
                onChange={(e, newPageNo) => onPageChange(newPageNo)}
                page={pageNumber}
              />
            </Box>
          )}
        </Box>
      )
    );
  };

  const renderBodyRow = () => {
    if (pageQuotes) {
      if (pageQuotes.length > 0) {
        return pageQuotes.map((quote) => {
          const unread = !quote.initialView;
          return (
            <TableRow
              key={`quote-${quote.leadId}`}
              onClick={() => handleRowClick(quote)}
              classes={{
                selected: classes.tableUnread,
              }}
            >
              <TableBodyCell value={quote.consumerDetailName} unread={unread} />
              <TableBodyCell
                value={formatDate(quote.createdAt)}
                unread={unread}
              />
              <TableBodyCell
                value={formatType(quote.requestType)}
                display={status === "awaiting_response"}
                unread={unread}
              />
              <TableBodyCell
                value={formatDate(quote.showDetailsAt)}
                display={status === "interested"}
                unread={unread}
              />
              <TableBodyCell
                value={formatDate(quote.notInterestedAt)}
                display={status === "not_interested"}
                unread={unread}
              />
              <TableBodyCell
                value={expiresInWords(quote.expiredAt)}
                display={status === "awaiting_response"}
                unread={unread}
              />
              <TableBodyCell
                value={formatDate(quote.expiredAt)}
                display={status === "expired"}
                unread={unread}
              />
              <TableBodyCell
                value={formatType(quote.requestType)}
                display={status !== "awaiting_response"}
                unread
              />
              <TableBodyCell
                value={`${quote.listingAddressName} - ${quote.listingAddressSuburb}, ${quote.listingAddressPostcode}`}
                display={!isTabletOnly}
                unread={unread}
              />
              <TableCell>
                <ChevronRightIcon />
              </TableCell>
            </TableRow>
          );
        });
      }
      return <TableNoResult />;
    }
    return null;
  };

  const TableBodyCell = ({ value, display = true, unread }) => {
    return (
      display && (
        <TableCell>
          <Typography
            variant="body1"
            classes={{ body1: unread ? classes.tableUnread : "" }}
          >
            {value}
          </Typography>
        </TableCell>
      )
    );
  };

  const renderBodyRowMobile = (status) => {
    if (pageQuotes) {
      if (pageQuotes.length > 0) {
        return pageQuotes.map((quote) => (
          <TableRow
            key={`quote-${quote.leadId}`}
            onClick={() => handleRowClick(quote)}
          >
            <TableCell className={classes.tableUnread}>
              <Box flex="1" py={1}>
                {quote.consumerDetailName}
              </Box>
              <Box flex="1" py={1}>
                {status === "awaiting_response" &&
                  expiresInWords(quote.expiredAt, "Expires in")}
                {status === "interested" &&
                  `Accepted ${formatDate(quote.showDetailsAt)}`}
                {status === "not_interested" &&
                  `Declined ${formatDate(quote.notInterestedAt)}`}
                {status === "expired" &&
                  `Expired ${formatDate(quote.expiredAt)}`}
              </Box>
            </TableCell>
            <TableCell align="right">{formatType(quote.requestType)}</TableCell>
            <TableCell>
              <ChevronRightIcon />
            </TableCell>
          </TableRow>
        ));
      }
      return <TableNoResult />;
    }
    return null;
  };

  const TableNoResult = () => {
    return (
      <TableRow>
        <TableCell colSpan={isTabletUp ? 5 : 2}>
          <Box display="flex" flexDirection="row">
            <Box
              width="50%"
              pt={4}
              flexDirection="column"
              textAlign="right"
              alignItems="center"
            >
              <Box flex="1" component={Typography} variant="h3">
                Currently there are no search results to display.
              </Box>
              <Box flex="1" component={Typography} variant="body1">
                Please be sure to check back frequently.
              </Box>
            </Box>
            <Box flex="1 1 50%">
              <img width="216" src={noResultImg} alt="No results" />
            </Box>
          </Box>
        </TableCell>
      </TableRow>
    );
  };

  const TableHeader = ({ title }) => {
    return (
      <TableHead>
        <TableRow
          classes={{
            root: classes.tableHead,
          }}
        >
          <TableHeaderCell title={title.requestedBy} />
          <TableHeaderCell title={title.receivedBy} />
          <TableHeaderCell
            title={title.type}
            display={status === "awaiting_response"}
            infoModal={infoModalType()}
          />
          <TableHeaderCell
            title={title.acceptedOn}
            display={status === "interested"}
            infoModal={infoModalAccepted()}
          />
          <TableHeaderCell
            title={title.declined}
            display={status === "not_interested"}
          />
          <TableHeaderCell
            title={title.expired}
            display={status === "expired"}
          />
          <TableHeaderCell
            title={title.type}
            display={status !== "awaiting_response"}
            infoModal={infoModalType()}
            colSpan={isTabletOnly ? 2 : 1}
          />
          <TableHeaderCell
            title={title.expiresIn}
            display={status === "awaiting_response"}
            infoModal={infoModalExpiresIn()}
            colSpan={isTabletOnly ? 2 : 1}
          />
          <TableHeaderCell
            title={title.relatedListing}
            display={!isTabletOnly}
            infoModal={infoModalRelatedListing()}
            colSpan={2}
          />
        </TableRow>
      </TableHead>
    );
  };

  const TableHeaderCell = ({
    title,
    display = true,
    infoModal,
    width,
    colSpan,
  }) => {
    return (
      display && (
        <TableCell width={width} colSpan={colSpan}>
          {title} {infoModal}
        </TableCell>
      )
    );
  };

  const title = isTabletUp ? titles.computer : titles.tablet;

  return (
    <>
      {isLoading && (
        <Box flex="1 100%" px={1} textAlign="center" py={20}>
          <CircularProgress />
        </Box>
      )}

      {!isLoading && (
        <Box border={1} borderColor={grey600}>
          <Table>
            {isTabletUp && (
              <>
                <TableHeader title={title} />
                <TableBody>{renderBodyRow(status)}</TableBody>
              </>
            )}
            {!isTabletUp && (
              <TableBody>{renderBodyRowMobile(status)}</TableBody>
            )}
            <TableFooter>
              <TableRow>
                <TableCell colSpan={isTabletUp ? 6 : 2}>
                  {renderPagination()}
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </Box>
      )}
    </>
  );
};

export default withGatewayApi(withRouter(QuoteListTabPane));
