import { format } from "date-fns";
import CircleLabel from "./CircleLabel";

export { isToday } from "date-fns";

export function formatDate(date) {
  return format(new Date(date), "ddd D MMM YY - h:mma");
}

// eslint-disable-next-line consistent-return
export function expiresInWords(expiryDateString, expiryText = "") {
  if (!expiryDateString) {
    return `Doesn't Expire`;
  }
  // new Date() is relative to the user's locale so we need
  // to use UTC functions to normalize the difference values
  const now = new Date();
  const expiryDate = new Date(expiryDateString);
  if (expiryDate > now) {
    const difference = new Date(expiryDate - now);
    const days = difference.getUTCDate() - 1;
    const hours = difference.getUTCHours() + days * 24;
    const minutes = difference.getUTCMinutes();
    let color;
    if (hours >= 12) {
      color = "green";
    } else if (hours >= 6) {
      color = "yellow";
    } else {
      color = "red";
    }
    const duration = `${hours}hrs ${minutes}m`;
    return (
      <>
        <CircleLabel color={color} />
        {` ${expiryText} ${duration}`}
      </>
    );
  }
}

export function formatShortDate(date) {
  return format(new Date(date), "D MMM YY");
}

export function formatTime(date) {
  return format(new Date(date), "hh:mma");
}
