/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState, useLayoutEffect } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Link } from "sensis-material-ui";
import Paper from "@material-ui/core/Paper";
import {
  Collapse,
  IconButton,
  makeStyles,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
} from "@material-ui/core";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import { withGatewayApi } from "../../API";
import { setLoading } from "../../redux/Actions";
import MainContent from "../common/MainContent";
import { useBreakpointOnly, useBreakpointUp } from "../common/MediaQueryUtil";
import sensisLogo from "../../images/products-logo/sensis.svg";
import truelocalLogo from "../../images/products-logo/truelocal.svg";
import yellowpagesLogo from "../../images/products-logo/yellowpages.svg";

const MyAccountDashboard = ({ gatewayApi }) => {
  const [productList, setProductList] = useState(null);
  const [customerNumber, setCustomerNumber] = useState(null);
  const [eventSummary, setEventSummary] = useState(null);
  const [apfId, setApfId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const user = useSelector((state) => state.session.user);
  const dispatch = useDispatch();

  const setCustomerNumberAndAfpId = () => {
    if (
      user &&
      (user.contact.customerNumber !== customerNumber ||
        user.contact.apfId !== apfId)
    ) {
      setCustomerNumber(user.contact.customerNumber);
      setApfId(user.contact.apfId);
    }
  };

  const loadProducts = async () => {
    if (!productList && user) {
      dispatch(setLoading({ isLoading: true }));

      const { masterAccountId } = user.contact;

      const products = await gatewayApi.get(
        `/product-list?accountId=${masterAccountId}`,
        () => {
          console.warn("API Gateway error: unable to retrieve product list");
          gatewayApi.errorHandler(true);
        }
      );
      if (products) {
        setProductList(products.data);
      }

      dispatch(setLoading({ isLoading: false }));
    }
  };

  const loadEventSummary = async () => {
    if (!eventSummary && user) {
      dispatch(setLoading({ isLoading: true }));

      const { masterAccountId } = user.contact;

      const summary = await gatewayApi.get(
        `/event-summary?accountId=${masterAccountId}`,
        () => {
          console.warn("API Gateway error: unable to retrieve event summary");
          gatewayApi.errorHandler(true);
        }
      );
      if (summary) {
        setEventSummary(summary.data.event_groups);
      }

      dispatch(setLoading({ isLoading: false }));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setCustomerNumberAndAfpId();
    loadProducts();
    loadEventSummary();
  });

  const { grey600 } = useTheme().custom.colour;

  const useStyles = makeStyles({
    tableHead: {
      backgroundColor: grey600,
    },
    tableUnread: {
      fontWeight: "bold",
    },
    tableCellBtnColumn: {
      backgroundColor: "#000",
    },
    cardsHeader: {
      textTransform: "Capitalize",
    },
    textWrap: {
      wordBreak: "break-all",
      wordWrap: "break-word",
    },
    dropdownTableRow: {
      "&:hover": {
        backgroundColor: "transparent",
      },
      cursor: "default",
    },
  });

  const classes = useStyles();

  const eventSummaryChart = (data) => {
    let chart = am4core.create(data.event_group_code, am4charts.PieChart);
    am4core.options.autoDispose = true;

    chart.data = [data];

    chart.innerRadius = am4core.percent(60);
    const pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "total";
    pieSeries.dataFields.category = "group_event_name";
    pieSeries.labels.template.disabled = true;
    pieSeries.ticks.template.disabled = true;
    pieSeries.colors.list = [am4core.color("#ffce00")];

    const label = pieSeries.createChild(am4core.Label);
    label.text = data.total > 0 ? data.total : "No data";
    label.horizontalCenter = "middle";
    label.verticalCenter = "middle";
    label.fontSize = 30;
    // eslint-disable-next-line no-self-assign
    chart = chart;
    return null;
  };

  useLayoutEffect(() => {
    console.log("UI Fully loaded");
    if (!isLoading && eventSummary) {
      eventSummary.forEach((summary) => {
        eventSummaryChart(summary);
      });
    }
  });

  const ReportingCards = ({ eventSummaryGroup }) => {
    const getEventDescription = (groupId) => {
      switch (groupId) {
        case "group-1":
          return "Number of times your ad has been viewed by visitors.";
        case "group-2":
          return "Number of times people clicked on your ad, or the content in it.";
        case "group-3":
          return "Digital contacts made, including emails and web enquiry forms.";
        case "group-4":
          return "Phone calls made to the tracked number in your listings.";
        default:
          console.warn("unknown event group id");
          return null;
      }
    };
    return (
      <Box flex="1" display="flex" flexDirection="columns" flexWrap="wrap" mui>
        {eventSummaryGroup &&
          eventSummaryGroup.map((summary) => {
            const eventDescription = getEventDescription(
              summary.event_group_code
            );
            return (
              <>
                <ReportingCard
                  key={summary.event_group_code}
                  summary={summary}
                  eventDescription={eventDescription}
                  id={summary.event_group_code}
                />
              </>
            );
          })}
      </Box>
    );
  };

  const ReportingCard = ({ summary, eventDescription }) => {
    const theme = useTheme();
    const { card } = makeStyles({
      card: {
        "&:hover": {
          boxShadow: theme.shadows[4],
        },
      },
    })();
    return (
      <Box
        className={card}
        component={Paper}
        flex={{ sm: "1 100", md: "1 45%", lg: "1" }}
        m={1}
        p={1}
      >
        <h3 className={classes.cardsHeader}>{summary.event_group_name}</h3>
        <Box id={summary.event_group_code} />
        <p>{eventDescription}</p>
      </Box>
    );
  };

  const showProductName = (productTypeCode, productName, product) => {
    return (
      <>
        {productTypeCode !== "sensis_ecommerce" &&
          productTypeCode !== "sensis_digitalStarter" &&
          product.brand !== "sensis_digitalStarter" &&
          `${productName}`}

        {productTypeCode === "sensis_ecommerce" && `eCommerce Store`}
        {(productTypeCode === "sensis_digitalStarter" ||
          product.brand === "sensis_digitalStarter") &&
          `Sensis Digital Starter Website`}
      </>
    );
  };

  const showProductLogo = (productTypeCode) => {
    const sensisProducts = [
      "sensis_website",
      "sensis_ecommerce",
      "sensis_searchAds",
      "sensis_socialAds",
      "sensis_seo",
    ];
    const trueLocalProducts = ["truelocal"];
    const yellowProducts = [
      "yellowpages",
      "yellowpages_print",
      "yellowFreeMicrosite",
      "yellowonline",
    ];

    return (
      <>
        {sensisProducts.includes(productTypeCode) && (
          <img src={sensisLogo} height="50px" alt={productTypeCode} />
        )}
        {trueLocalProducts.includes(productTypeCode) && (
          <img src={truelocalLogo} height="50px" alt={productTypeCode} />
        )}
        {yellowProducts.includes(productTypeCode) && (
          <img src={yellowpagesLogo} height="50px" alt={productTypeCode} />
        )}
      </>
    );
  };

  const showProductProperties = (properties) => {
    return properties.map((property) => (
      <Box key={`${property.label}-${property.value}`}>
        {property.label} : {property.value}
      </Box>
    ));
  };

  const showMakeEnquiry = (productTypeCode, product) => {
    return (
      <>
        {(productTypeCode === "yellowpages" ||
          productTypeCode === "yellowpages_fe" ||
          productTypeCode === "yellowonline") && (
          <Box>
            <Link href="https://www.yellow.com.au/contact">Make Enquiry</Link>
          </Box>
        )}
        {(productTypeCode === "sensis_seo" ||
          productTypeCode === "sensis_searchAds") && (
          <Box>
            <Link href="https://www.yellow.com.au/contact">Make Enquiry</Link>
          </Box>
        )}
        {productTypeCode === "sensis_ecommerce" && (
          <Box>
            <Link href="/">Manage Store</Link>
          </Box>
        )}
        {(productTypeCode === "yellowFreeMicrosite" ||
          productTypeCode === "truelocal") && (
          <Box>
            <Link href="/">Make Changes</Link>
          </Box>
        )}

        {![
          "yellowpages",
          "yellowpages_fe",
          "truelocal",
          "sensis_seo",
          "sensis_searchAds",
          "sensis_website",
          "sensis_ecommerce",
          "sensis_digitalStarter",
          "yellowFreeMicrosite",
          "sensis_builderWebsite",
          "yellowonline",
          "sensis_digitalBasic",
          "sensis_digitalSpecialist",
        ].includes(productTypeCode) && (
          <Box>
            <Link href="https://www.yellow.com.au/contact">Make Enquiry</Link>
          </Box>
        )}

        {productTypeCode === "sensis_website" &&
          product.brand !== "sensis_digitalStarter" &&
          product.brand !== "sensis_builderWebsite" &&
          product.brand !== "sensis_digitalBasic" &&
          product.brand !== "sensis_digitalSpecialist" && (
            <>
              <Box>
                <Link href="/">Make Changes</Link>
              </Box>
            </>
          )}

        {(productTypeCode === "sensis_digitalStarter" ||
          product.brand === "sensis_digitalStarter" ||
          productTypeCode === "sensis_digitalBasic" ||
          product.brand === "sensis_digitalBasic" ||
          productTypeCode === "sensis_digitalSpecialist" ||
          product.brand === "sensis_digitalSpecialist") && (
          <Box>
            <Link href="/">Make Changes</Link>
          </Box>
        )}

        {productTypeCode === "sensis_builderWebsite" ||
          (product.brand === "sensis_builderWebsite" && (
            <Box>
              <Link href="/">Manage site</Link>
            </Box>
          ))}
      </>
    );
  };

  const TableHeaderCell = ({ title, display = true, colSpan }) => {
    return display && <TableCell colSpan={colSpan}>{title}</TableCell>;
  };

  const TableHeader = () => {
    return (
      <TableHead>
        <TableRow>
          <TableHeaderCell title="Product Name" colSpan="2" />
          <TableHeaderCell title="Status" />
          <TableHeaderCell title="Details" colSpan="3" />
        </TableRow>
      </TableHead>
    );
  };

  const TableHeaderMobile = () => {
    return (
      <TableHead>
        <TableRow>
          <TableHeaderCell colSpan="4" title="Product Name" />
        </TableRow>
      </TableHead>
    );
  };

  const isMobile = useBreakpointOnly("xs");
  const isTabletUp = useBreakpointUp("sm");

  const TableBodyRow = (props) => {
    const { product } = props;
    const [open, setOpen] = useState(false);

    return (
      <>
        <TableRow>
          <TableCell>{showProductLogo(product.productTypeCode)}</TableCell>
          <TableCell scope="row">
            {product.productList.length === 1
              ? product.productList[0].name
              : product.productTypeName}
          </TableCell>
          {isTabletUp && (
            <>
              <TableCell>
                {product.productList.length === 1
                  ? product.productList[0].status
                  : product.productTypeStatus}
              </TableCell>
              <TableCell className={classes.textWrap}>
                {product.productList.length !== 1
                  ? `${product.productList.length} ${product.details}`
                  : showProductProperties(product.productList[0].properties)}
              </TableCell>
            </>
          )}
          <TableCell>
            {(product.productTypeCode === "yellowpages" ||
              product.productTypeCode === "yellowpages_fe" ||
              product.productTypeCode === "sensis_searchAds" ||
              product.productTypeCode === "truelocal" ||
              product.productTypeCode === "yellowonline") && (
              <Box>
                <Link href="/">View Reporting</Link>
              </Box>
            )}
            {(product.productTypeCode === "yellowpages_print" ||
              product.productTypeCode === "yellowpages_print_fe") && (
              <Box>
                <Link href="/">View Reporting</Link>
              </Box>
            )}
            {product.productTypeCode === "sensis_seo" &&
              product.productTypeStatus === "Live" && (
                <Box>
                  <Link href="/">View Reporting</Link>
                </Box>
              )}
            {product.productList.length === 1 &&
              showMakeEnquiry(product.productTypeCode, product)}
          </TableCell>
          <TableCell align="right">
            {product.productList.length > 1 && (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            )}
          </TableCell>
        </TableRow>
        {product.productList.length > 1 && (
          <TableRow className={classes.dropdownTableRow}>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Table>
                  <TableBody>
                    {product.productList.map((historyRow, key) => (
                      <TableRow
                        className={classes.dropdownTableRow}
                        key={`${historyRow.activationDate}-${historyRow.name}-${historyRow.properties.length}`}
                      >
                        <TableCell
                          className={
                            product.productList.length === key + 1
                              ? classes.lastItemRow
                              : ``
                          }
                          width={isTabletUp ? "350px" : "100%"}
                        >
                          {showProductName(
                            product.productTypeCode,
                            historyRow.name,
                            historyRow
                          )}
                        </TableCell>
                        {isTabletUp && (
                          <>
                            <TableCell
                              className={
                                product.productList.length === key + 1
                                  ? classes.lastItemRow
                                  : ``
                              }
                              width={isTabletUp ? "200px" : "100%"}
                            >
                              <Box ml={2}>{historyRow.status}</Box>
                            </TableCell>
                            <TableCell
                              className={
                                product.productList.length === key + 1
                                  ? classes.lastItemRow
                                  : ``
                              }
                              width={isTabletUp ? "220px" : "100%"}
                            >
                              {showProductProperties(historyRow.properties)}
                            </TableCell>
                          </>
                        )}

                        <TableCell
                          className={
                            product.productList.length === key + 1
                              ? classes.lastItemRow
                              : ``
                          }
                          width={isTabletUp ? "220px" : "100%"}
                        >
                          {showMakeEnquiry(product.productTypeCode, historyRow)}
                        </TableCell>
                        <TableCell
                          className={
                            product.productList.length === key + 1
                              ? classes.lastItemRow
                              : ``
                          }
                        />
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Collapse>
            </TableCell>
          </TableRow>
        )}
      </>
    );
  };
  const ProductList = () => {
    console.log("productlist", productList);
    return (
      <Table>
        {isMobile ? <TableHeaderMobile /> : <TableHeader />}
        <TableBody>
          {productList &&
            productList.productTypeList.map((product) => (
              <TableBodyRow
                key={`${product.productTypeName}-${product.productTypeCode}-${product.productList.length}`}
                product={product}
              />
            ))}
        </TableBody>
      </Table>
    );
  };

  return (
    <MainContent
      header="Sensis Business Insights"
      description="Discover insights about your local area and learn more about your potential customers"
    >
      <Box display="flex" alignItems="flex-end">
        <Box flex="2">
          <h1>My Reporting</h1>
          <p>
            See how your products are performing. Click to see more of each
            segment. This data is accurate to the last 3 days.
          </p>
        </Box>
        <Box p={2}>
          <Select value="0">
            <option aria-label="None" value="0">
              Last 7 Days
            </option>
            <option aria-label="None" value="1">
              Last 30 Days
            </option>
            <option aria-label="None" value="2">
              Last 6 Months
            </option>
            <option aria-label="None" value="3">
              Last 12 Months
            </option>
          </Select>
        </Box>
      </Box>
      <ReportingCards eventSummaryGroup={eventSummary} />
      <h1>My Products</h1>
      <ProductList />
    </MainContent>
  );
};

export default withGatewayApi(withRouter(MyAccountDashboard));
