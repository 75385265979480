/* eslint-disable max-classes-per-file */
import { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { API } from "react-common-api";
import { withOktaAuth } from "./OktaFacade";
import loadConfig from "./Config";

export function withMarketingCloudApi(WrappedComponent) {
  class WithMarketingCloudApi extends Component {
    constructor(props) {
      super(props);

      this.state = {
        api: null,
        error: null,
      };
    }

    async componentDidMount() {
      const api = await this.makeApi();
      this.setState({ api });
    }

    errorHandler = (error) => {
      // Local environment has CORS issue accessing the endpoint that invoke ErrorBoundary
      // Set only the error state for test and production environment
      const isProduction = process.env.NODE_ENV === "production";
      this.setState({ error: isProduction ? error : null });
    };

    async makeApi() {
      const envConfig = await loadConfig();
      const baseURL = envConfig.marketingCloud;

      const axiosConfig = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };

      return new API(baseURL, this.errorHandler, axiosConfig);
    }

    render() {
      const { api, error } = this.state;
      if (error) {
        // Throwing the error will invoke an ErrorBoundary
        throw error;
      }

      return (
        api && (
          <WrappedComponent
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...this.props}
            marketingCloudApi={api}
          />
        )
      );
    }
  }

  return WithMarketingCloudApi;
}

export function withGatewayApi(WrappedComponent) {
  class WithGatewayApi extends Component {
    constructor(props) {
      super(props);

      this.state = {
        api: null,
        error: null,
      };
    }

    componentDidMount() {
      this.makeApi();
    }

    componentDidUpdate() {
      this.makeApi();
    }

    componentWillUnmount() {
      const { api } = this.state;
      if (api) {
        api.cancel("Component unmounted");
      }
    }

    errorHandler = (error) => {
      this.setState({ error });
    };

    async gatewayApi() {
      const { authService, isPublicRoute } = this.props;
      const envConfig = await loadConfig();

      const baseURL = isPublicRoute
        ? envConfig.apiGatewayPublic
        : envConfig.apiGatewayAuth;

      const axiosConfig = {};
      if (!isPublicRoute) {
        const accessToken = await authService.getAccessToken();
        axiosConfig.headers = {
          Authorization: `Bearer ${accessToken}`,
        };
      }

      function responseHandler(response) {
        if (response && response.data) {
          const status = response.data.Status;
          if (status) {
            if (status.Success) {
              // Wrap the silly data.Data back up to a standard XHR type 'data'
              return { data: response.data.Data };
            }
            if (status.Code === "MY-CM-11") {
              console.info(`API Gateway message: ${status.Message}`);
              return <Redirect to={{ pathname: "/login" }} />;
            }
            throw Error(`API Gateway error code: ${status.Code}`);
          }
        }

        throw new Error(response);
      }

      return new API(baseURL, this.errorHandler, axiosConfig, responseHandler);
    }

    async makeApi() {
      const { api } = this.state;

      if (!api) {
        const gatewayApi = await this.gatewayApi();
        this.setState({
          api: gatewayApi,
        });
      }
    }

    render() {
      const { api, error } = this.state;

      if (error) {
        // Throwing the error will invoke an ErrorBoundary
        throw error;
      }

      return (
        api && (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <WrappedComponent {...this.props} gatewayApi={api} />
        )
      );
    }
  }

  const mapStateToProps = (state) => {
    return {
      isPublicRoute: state.publicRoute.isPublicRoute,
    };
  };

  return withOktaAuth(connect(mapStateToProps)(WithGatewayApi));
}
