import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { format } from "date-fns";
import { Box, Divider } from "@material-ui/core";
import { Button } from "sensis-material-ui";
import Dialog, { withDialogState } from "../common/Dialog";
import { useOktaAuth } from "../../OktaFacade";

const IdleTimer = ({ dialogState }) => {
  const { authService } = useOktaAuth();
  const idleTimeout = 900000;
  const promptTimeout = 60000;
  const [remaining, setRemaining] = useState(idleTimeout);
  const logout = () => {
    authService.logout("/").then(() => {
      window.location = "/login";
    });
  };
  const onIdle = () => {
    logout();
  };
  const onPrompt = () => {
    dialogState.open();
    setRemaining(promptTimeout);
  };

  const { getRemainingTime, reset } = useIdleTimer({
    onIdle,
    onPrompt,
    timeout: idleTimeout,
    promptTimeout,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: true,
    name: "idle-timer",
    syncTimers: 0,
    leaderElection: false,
  });

  const modalIdleTimer = () => {
    return (
      <Dialog
        header="Session expiration warning"
        trigger={<></>}
        dialogState={dialogState}
        closeIcon={false}
      >
        <Box px={2}>
          <Divider />
          <Box fontSize="14px" p={2} pb={2}>
            <Box fontWeight={700}>
              Your session is about to expire due to inactivity
            </Box>
            {`Time remaining: ${format(remaining, "ss")} seconds`}
          </Box>
          <Divider />
          <Box display="flex" justifyContent="center" m={2}>
            <Box mr={1}>
              <Button
                size="small"
                width="100%"
                color="primary"
                onClick={() => {
                  dialogState.close();
                  reset();
                }}
              >
                Stay Logged In
              </Button>
            </Box>
            <Box mr={0.5}>
              <Button
                variant="contained"
                size="small"
                width="100%"
                color="secondary"
                onClick={() => {
                  dialogState.close();
                  logout();
                }}
              >
                Logout
              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(getRemainingTime());
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime]);
  return dialogState.isOpen ? modalIdleTimer() : null;
};

export default withDialogState(IdleTimer);
