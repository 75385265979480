import { Box } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import styled from "styled-components";

export default function CircleLabel({ color }) {
  const { green, red100, yellow100 } = useTheme().custom.colour;

  let labelColor;
  if (color === "green") {
    labelColor = green;
  } else if (color === "yellow") {
    labelColor = yellow100;
  } else {
    labelColor = red100;
  }

  const StyledCircularLabel = styled(Box)`
    background-color: ${labelColor};
    border-radius: 50%;
    height: 12px;
    width: 12px;
    display: inline-block;
  `;

  return <StyledCircularLabel component="span" />;
}
