import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withGatewayApi } from "../../API";
import {
  setSessionUser,
  getQuotesCount,
  getQuotesIsEligible,
  setPocketOfficeIsEligible,
  setYellowConnectIsEligible,
  setLoading,
} from "../../redux/Actions";
import { makeCustomerNumber } from "../common/CustomerNumberUtils";

const InitWithApi = ({ gatewayApi }) => {
  const isPublicRoute = useSelector((state) => state.publicRoute.isPublicRoute);
  const dispatch = useDispatch();

  const setYellowConnectStatus = ({ services }) => {
    let status = false;
    if (services && services.length > 0) {
      const yellowConnectService = services.filter(
        (service) =>
          service.serviceType === "Yellow Connect" &&
          service.oktaUserProvisioningStatus === "COMPLETED"
      );
      if (yellowConnectService.length >= 1) {
        status = false;
      }
    }
    dispatch(setYellowConnectIsEligible({ status }));
  };

  const setPocketOfficeStatus = async ({ services }) => {
    let status = "disabled";

    if (services && services.length > 0) {
      const livePoServices = services.filter((service) => {
        return (
          service.serviceType === "Pocket Office" && service.status === "Live"
        );
      });

      if (livePoServices.length === 1) {
        status = "live";
      }
    }
    dispatch(setPocketOfficeIsEligible({ status }));
  };

  useEffect(() => {
    const loggedInuser = async () => {
      if (!isPublicRoute) {
        dispatch(setLoading({ isLoading: true }));
        const user = await gatewayApi.get("/contacts/loggedinuser");
        if (user) {
          dispatch(setSessionUser(user.data));
          const customerNumber = makeCustomerNumber(
            user.data.contact.customerNumber,
            user.data.contact.apfId
          );

          const quotes = await gatewayApi.get(
            `/customer/${customerNumber}/quotes?detail=count`,
            () => {
              // Dispatch the qoutes action for the catching the error.
              console.warn(
                "API Gateway error: unable to check Quotes eligibility"
              );
            }
          );

          if (quotes && quotes.data) {
            dispatch(getQuotesCount(quotes.data));
            dispatch(getQuotesIsEligible(quotes.data));
          }

          await setPocketOfficeStatus(user.data);
          setYellowConnectStatus(user.data);
        }
        dispatch(setLoading({ isLoading: false }));
      }
    };
    loggedInuser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default withGatewayApi(InitWithApi);
