import { Component } from "react";
import ReactDOM from "react-dom";
import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";
import "@okta/okta-signin-widget/dist/css/okta-theme.css";
import "./OktaSignInWidget.css";
import loadConfig from "../../Config";

export default class OktaSignInWidget extends Component {
  async componentDidMount() {
    // eslint-disable-next-line react/no-find-dom-node
    const el = ReactDOM.findDOMNode(this);
    const envConfig = await loadConfig();
    const baseURL = envConfig.passwordUrl;

    // Loading this way code splits into a separate chunk instead
    // of including in the main chunk, helpful when loading unauth
    // pages whereby signin resources are not needed
    const oktaSignInWidget = await import("@okta/okta-signin-widget");
    const OktaSignIn = oktaSignInWidget.default;

    this.widget = new OktaSignIn({
      // eslint-disable-next-line react/destructuring-assignment
      baseUrl: this.props.baseUrl,
      logo: "../static/media/logo-menu.97ef1f90.svg",
      helpLinks: {
        help: "https://pub.dash.sensis.com.au/activate-myAccount",
        forgotPassword: `${baseURL}/myaccount/#/forgot-password`,
      },
      i18n: {
        en: {
          help: "Register for myYellow",
        },
      },
    });
    // eslint-disable-next-line react/destructuring-assignment
    this.widget.renderEl({ el }, this.props.onSuccess, this.props.onError);
  }

  componentWillUnmount() {
    // eslint-disable-next-line no-unused-expressions
    this.widget && this.widget.remove();
  }

  render() {
    return <div />;
  }
}
