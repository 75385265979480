import { Box, Typography } from "sensis-material-ui";
import { makeStyles } from "@material-ui/core/styles";
import MUIAvatar from "@material-ui/core/Avatar";

function Avatar({ name, hideName, customColor, inLine }) {
  function getColor(initial) {
    const nonAlphabetRegex = "[^a-zA-Z]+";

    switch (true) {
      case ["A", "F", "K", "P", "U", "Z"].indexOf(initial) > -1:
        return {
          bgColor: "#D1C4E8",
          fgColor: "#683BB5",
        };
      case ["B", "G", "L", "Q", "V"].indexOf(initial) > -1:
        return {
          bgColor: "#B8EBE4",
          fgColor: "#15BDA5",
        };
      case ["C", "H", "M", "R", "W"].indexOf(initial) > -1:
        return {
          bgColor: "#FFE0B2",
          fgColor: "#FB8C00",
        };
      case ["D", "I", "N", "S", "X"].indexOf(initial) > -1:
        return {
          bgColor: "#B3E5FC",
          fgColor: "#03A9F4",
        };
      case ["E", "J", "O", "T", "Y"].indexOf(initial) > -1:
        return {
          bgColor: "#EDBAD0",
          fgColor: "#C51B63",
        };

      case new RegExp(nonAlphabetRegex).test(initial):
        return {
          bgColor: "#D1C4E8",
          fgColor: "#683BB5",
        };

      default:
        return {
          bgColor: "#B3E5FC",
          fgColor: "#03A9f4",
        };
    }
  }

  const color = getColor(customColor ? name.substr(0, 1).toUpperCase() : "");

  const useStyles = makeStyles(() => ({
    root: {
      backgroundColor: color.bgColor,
      color: color.fgColor,
      fontSize: "14px",
    },
  }));

  const classes = useStyles();
  const acronym = name.match(/\b\w/g).join("").toUpperCase();

  return (
    <Box display={inLine ? "inline-block" : "flex"} component="span">
      <MUIAvatar size="36" component="span" className={classes.root}>
        {acronym}
      </MUIAvatar>

      {!hideName && (
        <Typography
          variant="body1"
          component={Box}
          display="inline"
          pl={1}
          pt={1}
        >
          {name}
        </Typography>
      )}
    </Box>
  );
}

export default Avatar;
