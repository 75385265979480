import { Children, cloneElement } from "react";
import { useSelector } from "react-redux";
import { openInNewTab } from "../common/UrlUtils";
import { withGatewayApi } from "../../API";
import { dtmEvent } from "../common/AdobeDTMUtils";

const OpenPocketOffice = ({ children, gatewayApi, dtmEventId }) => {
  const status = useSelector((state) => state.pocketOffice.status);
  const user = useSelector((state) => state.session.user);

  const redirectToPocketOfficeUrl = async () => {
    const { serviceId } = user.services.filter((service) => {
      return (
        service.serviceType === "Pocket Office" && service.status === "Live"
      );
    })[0];

    const pocketOfficeUrl = await gatewayApi.get(`/sso_link/${serviceId}`);
    /* istanbul ignore else */
    if (pocketOfficeUrl) {
      dtmEvent(dtmEventId, user.contact);
      openInNewTab(pocketOfficeUrl.data.sso_url);
    }
  };

  const OnClick = () => {
    return Children.map(children, (child) =>
      cloneElement(child, {
        onClick: () => redirectToPocketOfficeUrl(),
      })
    );
  };

  return status === "live" && <OnClick />;
};

export default withGatewayApi(OpenPocketOffice);
