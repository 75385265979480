import { useSelector } from "react-redux";

const FeatureToggle = ({ children, featureHide, name }) => {
  const config = useSelector((state) => state.config);
  const toggled = config[name];

  return (toggled && !featureHide) || (!toggled && featureHide)
    ? children
    : null;
};

export default FeatureToggle;
