/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/destructuring-assignment */
import { Component } from "react";
import { withRouter } from "react-router-dom";
import Error from "./Error";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({
        error: null,
      });
    }
  }

  componentDidCatch(error) {
    this.setState({
      error,
    });
  }

  render() {
    return this.state.error ? <Error /> : this.props.children;
  }
}

export default withRouter(ErrorBoundary);
