import { useSelector } from "react-redux";
// Convert the querystring to an object with booleans for the string "true"
function convertBooleanValue(value) {
  switch (value) {
    case "true":
      return true;
    case "false":
      return false;
    default:
      return value;
  }
}

const encodeValue = (value) =>
  typeof value === "string"
    ? encodeURIComponent(value).replace(/%20/g, "+")
    : encodeURIComponent(value);

const decodeValue = (value) =>
  typeof value === "string"
    ? decodeURIComponent(value).replace(/\+/g, " ")
    : decodeURIComponent(value);

export function toQueryString(urlParams, options = {}) {
  const { deleteKeys = [], deleteFalseyKeys = [] } = options;

  // Make a copy of the urlParams so we aren't reassigning a function parameter
  const newUrlParams = { ...urlParams };

  deleteKeys.forEach((key) => delete newUrlParams[key]);

  deleteFalseyKeys.forEach(
    (key) => !newUrlParams[key] && delete newUrlParams[key]
  );

  return Object.keys(newUrlParams)
    .map((key) => `${key}=${encodeValue(newUrlParams[key])}`)
    .join("&");
}

export function toObject(url, options = {}) {
  const querystring = url.split("?")[1] || null;
  const { booleanKeys = [], filterKeys = [] } = options;

  return querystring
    ? querystring
        .split("&")
        .map((param) => param.split("="))
        .filter(([key]) => filterKeys.length === 0 || filterKeys.includes(key))
        .reduce(
          (obj, [key, value]) => ({
            ...obj,
            [key]:
              booleanKeys.indexOf(key) > -1
                ? convertBooleanValue(value)
                : decodeValue(value),
          }),
          {}
        )
    : {};
}

const Url = () => useSelector((state) => state.url);

export function getLinkProperties(href, rel, target) {
  const linkProperties = {};

  if (href) {
    linkProperties.rel = rel;
    linkProperties.target = target;

    const isExternal =
      !href.startsWith("/") || href.includes(new URL(Url()).host);
    if (isExternal) {
      if (rel) {
        throw Error(`A rel has been passed in for an external link: ${rel}`);
      }
      if (target) {
        throw Error(
          `A target has been passed in for an external link: ${target}`
        );
      }

      linkProperties.rel = "noopener";
      linkProperties.target = "_blank";
    }
  }

  return linkProperties;
}

export function statusToUrl(status) {
  return status.toLowerCase().replace("_", "-");
}

export function urlToStatus(url) {
  return url.toLowerCase().replace("-", "_");
}

export function openInNewTab(href) {
  // it will create a virtual anchor element that will allow to open a newtab
  Object.assign(document.createElement("a"), {
    target: "_blank",
    href,
  }).click();
}
