export const SET_YELLOW_CONNECT_IS_ELIGIBLE = "SET_YELLOW_CONNECT_IS_ELIGIBLE";
export const setSessionAccessToken = (data) => ({
  type: "SET_SESSION_ACCESS_TOKEN",
  data,
});

export const setSessionUser = (data) => ({
  type: "SET_SESSION_USER",
  data,
});

export const getQuotesCount = (quotes) => ({
  type: "GET_QUOTES_COUNT",
  quotes,
});

export const getQuotesIsEligible = (quotes) => ({
  type: "GET_QUOTES_IS_ELIGIBLE",
  quotes,
});

export const setPocketOfficeIsEligible = (pocketOffice) => ({
  type: "SET_POCKET_OFFICE_IS_ELIGIBLE",
  pocketOffice,
});

export const setYellowConnectIsEligible = (yellowConnect) => ({
  type: SET_YELLOW_CONNECT_IS_ELIGIBLE,
  payload: yellowConnect,
});

export const setLoading = (isLoading) => ({
  type: "SET_LOADING",
  isLoading,
});

export const setPublicRoute = (publicRoute) => ({
  type: "SET_PUBLIC_ROUTE",
  publicRoute,
});

export const setConfig = (config) => ({
  type: "SET_CONFIG",
  config,
});
