import { useState, useEffect } from "react";
import { Switch, Route, Link, withRouter } from "react-router-dom";
import { Box, Paper, Tab, Tabs } from "@material-ui/core";
import { useSelector } from "react-redux";
import { urlToStatus } from "../common/UrlUtils";
import { statusText } from "../common/QuoteUtils";
import MainContent from "../common/MainContent";
import QuoteListTabPane from "./QuoteListTabPane";
import { dtmEvent, eventNames } from "../common/AdobeDTMUtils";
import { useBreakpointOnly } from "../common/MediaQueryUtil";

const QuoteList = ({ history, match }) => {
  const quotes = useSelector((state) => state.quotes);
  const user = useSelector((state) => state.session.user);
  const [activeTab, setActiveTab] = useState(0);
  const isMobile = useBreakpointOnly("xs");

  const dtmEventId = (status) => {
    switch (status) {
      case "awaiting_response":
        return eventNames.MY_Q_awaiting_response_tab_click;
      case "interested":
        return eventNames.MY_Q_interested_tab_click;
      case "not_interested":
        return eventNames.MY_Q_not_interested_tab_click;
      case "expired":
        return eventNames.MY_Q_expired_tab_click;
      /* istanbul ignore next */
      default:
        console.warn(`Invalid status: ${status}`);
        return status;
    }
  };

  const handleClickMenu = (status) => {
    dtmEvent(dtmEventId(status), user.contact);
  };

  useEffect(() => {
    switch (history.location.pathname) {
      case "/quotes/awaiting-response":
        setActiveTab(0);
        break;
      case "/quotes/interested":
        setActiveTab(1);
        break;
      case "/quotes/not-interested":
        setActiveTab(2);
        break;
      case "/quotes/expired":
        setActiveTab(3);
        break;
      /* istanbul ignore next */
      default:
        console.warn(`Invalid path: ${history.location.pathname}`);
        return history.location.pathname;
    }
    return () => setActiveTab(0);
  }, [history.location.pathname]);

  function TabPanel(props) {
    const { children, value } = props;
    return value === activeTab && <Box>{children}</Box>;
  }

  const validStatus = (status) => {
    return [
      "awaiting_response",
      "interested",
      "not_interested",
      "expired",
    ].includes(status);
  };

  const status = urlToStatus(match.params.status);
  if (!validStatus(status)) {
    /* istanbul ignore next */
    throw Error(404);
  }

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
    if (newValue === 0) {
      handleClickMenu("awaiting_response");
    } else if (newValue === 1) {
      handleClickMenu("interested");
    } else if (newValue === 2) {
      handleClickMenu("not_interested");
    } /* istanbul ignore else */ else if (newValue === 3) {
      handleClickMenu("expired");
    } /* istanbul ignore else */ else {
      console.warn(`Invalid Status $(status)`);
    }
  };

  const getTabCount = (tabName, count) => {
    return `${tabName} ${count > 0 ? `(${count})` : ``}`;
  };

  return (
    <MainContent
      header="Quotes"
      description="View and respond to all of your quote requests in one handy place."
    >
      <Paper>
        <Route
          path="/"
          render={() => (
            <>
              <Tabs
                value={activeTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChange}
                scrollButtons="off"
                variant="scrollable"
                selectionFollowsFocus
              >
                <Tab
                  label={getTabCount(
                    statusText("awaiting_response", isMobile ? "mobile" : ""),
                    quotes.awaiting_response
                  )}
                  to="/quotes/awaiting-response"
                  component={Link}
                  value={0}
                />
                <Tab
                  label={getTabCount(
                    statusText("interested", isMobile ? "mobile" : ""),
                    quotes.interested
                  )}
                  to="/quotes/interested"
                  component={Link}
                  value={1}
                />
                {!isMobile && (
                  <Tab
                    label={getTabCount(
                      statusText("not_interested"),
                      quotes.not_interested
                    )}
                    to="/quotes/not-interested"
                    component={Link}
                    value={2}
                  />
                )}
                <Tab
                  label={getTabCount(
                    statusText("expired", isMobile ? "mobile" : ""),
                    quotes.expired
                  )}
                  to="/quotes/expired"
                  component={Link}
                  value={3}
                />
              </Tabs>
              <Switch>
                <Route
                  path="/quotes/awaiting-response"
                  render={() => (
                    <TabPanel value={0}>
                      <QuoteListTabPane status="awaiting_response" />
                    </TabPanel>
                  )}
                />
                <Route
                  path="/quotes/interested"
                  render={() => (
                    <TabPanel value={1}>
                      <QuoteListTabPane status="interested" />
                    </TabPanel>
                  )}
                />
                <Route
                  path="/quotes/not-interested"
                  render={() => (
                    <TabPanel value={2}>
                      <QuoteListTabPane status="not_interested" />
                    </TabPanel>
                  )}
                />
                <Route
                  path="/quotes/expired"
                  render={() => (
                    <TabPanel value={3}>
                      <QuoteListTabPane status="expired" />
                    </TabPanel>
                  )}
                />
              </Switch>
            </>
          )}
        />
      </Paper>
    </MainContent>
  );
};

export default withRouter(QuoteList);
