import { Link } from "react-router-dom";
import { Box, Typography } from "sensis-material-ui";
import errorIllustration from "../../images/error-illustration.svg";

export default function Error() {
  return (
    <Box
      display="flex"
      flex="1 auto"
      mx="auto"
      height="100vh"
      alignItems="center"
      flexDirection="row"
      flexWrap="wrap"
    >
      <Box
        display="flex"
        mui
        flex={{ xs: "1 100%", md: "1 50%" }}
        px={2}
        justifyContent={{ xs: "center", sm: "center", md: "flex-end" }}
      >
        <Box mui width="auto">
          <Typography variant="h3">Something went wrong.</Typography>
          <Typography variant="body1">
            Unfortunately we&apos;re experiencing technical issues.
          </Typography>
          <Typography variant="body1">
            Take me back to the <Link to="/">homepage.</Link>
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        mui
        flex={{ xs: "1 100%", md: "1 1 50%" }}
        justifyContent={{ xs: "center", sm: "center", md: "flex-start" }}
      >
        <img src={errorIllustration} alt="something went wrong." />
      </Box>
    </Box>
  );
}
