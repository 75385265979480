import { Box, Typography } from "sensis-material-ui";
import { Collapse, Paper } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

const FormContainer = ({
  alertAction,
  alertMessage,
  alertSeverity,
  children,
  header,
  showAlert,
}) => (
  <Box
    mui
    display={{ xs: "block", sm: "flex" }}
    justifyContent="center"
    pt={3}
    pb={1}
  >
    <Paper component={Box} mui width={{ sm: 550 }} p={2}>
      <Collapse in={showAlert}>
        <Alert
          aria-label={showAlert ? "alert-show" : "alert-hidden"}
          severity={alertSeverity}
          action={alertAction}
        >
          {alertMessage}
        </Alert>
      </Collapse>
      {header && (
        <Typography component="h3" p={2}>
          {header}
        </Typography>
      )}
      {children}
    </Paper>
  </Box>
);

export default FormContainer;
