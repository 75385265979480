import { useState } from "react";
import { Box } from "sensis-material-ui";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MainContent from "../common/MainContent";

const StyledAccordion = withStyles({
  expanded: {
    "&$expanded": {
      margin: "auto",
    },
  },
})(Accordion);

const StyledAccordionSummary = withStyles({
  root: {
    minHeight: "10px",
    "&:hover": {
      backgroundColor: "rgba(208, 2, 27, 0.2)",
    },
  },
})(AccordionSummary);

const AccordionItem = ({ title, panel, expanded, handleChange, children }) => {
  return (
    <>
      <StyledAccordion
        expanded={expanded === panel}
        onChange={handleChange(panel)}
      >
        <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
          <h4>{title}</h4>
        </StyledAccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </StyledAccordion>
    </>
  );
};

const Help = () => {
  const [expanded, setExpanded] = useState(null);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <MainContent header="myYellow FAQs">
      <Box
        mui
        display="flex"
        flex="1 100%"
        flexDirection={{ xs: "column", md: "row" }}
      >
        <Box
          mui
          display="flex"
          flex={{ xs: "1 100%", md: "1 70%" }}
          flexDirection="column"
          pr={{ md: 2 }}
          height="max-content"
        >
          <AccordionItem
            title="What is myYellow?"
            panel="panel1"
            expanded={expanded}
            handleChange={handleChange}
          >
            <p>
              myYellow is the next generation of our Yellow Customer Portal! In
              its first release, myYellow enables single sign-on to myAccount
              and Pocket Office; plus access to the new Quotes Dashboard and
              Messages Dashboard.
            </p>
          </AccordionItem>

          <AccordionItem
            title="What is myAccount?"
            panel="panel2"
            expanded={expanded}
            handleChange={handleChange}
          >
            <Box>
              <p>
                myAccount, accessible via myYellow, is the place where you can
                manage your Yellow Products.
              </p>
              <p>
                You can view your ROI reporting; access a list of your services;
                view your invoices, billing history and billing preferences; pay
                a Yellow bill; edit some of your online content, and more.
              </p>
            </Box>
          </AccordionItem>

          <AccordionItem
            title="How do I sign in to myAccount?"
            panel="panel3"
            expanded={expanded}
            handleChange={handleChange}
          >
            <p>
              Now that you’ve signed into myYellow, you don’t need to sign in
              again. Simply click the myAccount option on the home page, or on
              the menu, and your myAccount will be opened in a new tab in your
              browser.
            </p>
          </AccordionItem>

          <AccordionItem
            title="I’m already a myAccount user – has anything changed?"
            panel="panel4"
            expanded={expanded}
            handleChange={handleChange}
          >
            <p>
              Absolutely nothing has changed, except that you will now log in
              via myYellow. This means that you can access myAccount, plus all
              the new myYellow features, with a convenient single sign on. In
              the future, we plan to improve some of the myAccount features and
              make them accessible directly from myYellow.
            </p>
          </AccordionItem>

          <AccordionItem
            title="What is Pocket Office?"
            panel="panel5"
            expanded={expanded}
            handleChange={handleChange}
          >
            <p>
              Pocket Office helps you book more clients, get paid on time,
              automate and organise. Offer 21st century service where clients
              can view your real-time availability and self-book their own
              appointments, pay and reschedule with a click. Pocket Office gives
              you an instant professional edge, with automatically sent email
              and text message confirmations, reminders and follow-ups, all
              completely customisable for your business. Interested? Learn more
              about what you’ve been missing out on with
              <a
                href="https://www.yellow.com.au/product/pocket-office"
                rel="noopener noreferrer"
                target="_blank"
              >
                Pocket Office
              </a>
            </p>
          </AccordionItem>

          <AccordionItem
            title="How do I sign in to my Pocket Office account?"
            panel="panel6"
            expanded={expanded}
            handleChange={handleChange}
          >
            <p>
              Now that you’ve signed into myYellow, you don&apos;t need to sign
              in again. Simply click the Pocket Office option on the home page,
              or on the menu, and Pocket Office dashboard will be opened in a
              new tab in your browser. You will be automatically signed in as an
              Admin user.
            </p>
          </AccordionItem>

          <AccordionItem
            title="I’m not sure how to use my Pocket Office Account – how can I get support?"
            panel="panel7"
            expanded={expanded}
            handleChange={handleChange}
          >
            <p>
              We are here to help you build and organise your business with
              Pocket Office. You can contact a real expert directly via chat
              inside your pocket Office account, or just give our experts a call
              on 1800 612 766 and they&apos;ll help you get it sorted.
            </p>
          </AccordionItem>

          <AccordionItem
            title="What is the Quotes Dashboard?"
            panel="panel8"
            expanded={expanded}
            handleChange={handleChange}
          >
            <p>
              The Quotes Dashboard provides a consolidated view of all Quote
              Opportunities for the yellowpages.com.au Listings associated to
              your Account. The Dashboard is a handy, central place to view and
              respond to all available Quote Opportunities at once, and then
              manage the ones that you are interested.
            </p>
          </AccordionItem>

          <AccordionItem
            title="Why don’t I have a Quotes Dashboard on myYellow?"
            panel="panel9"
            expanded={expanded}
            handleChange={handleChange}
          >
            <p>
              This feature is only visible to Accounts who are eligible to
              receive Quote Opportunities from yellowpages.com.au. To be
              eligible, your yellowpages.com.au Listings must be in a Category
              for which Yellow Quotes is enabled, and you must have a valid
              email address published on your Listings.
            </p>
          </AccordionItem>

          <AccordionItem
            title="Is the existing Yellow Quotes service changing?"
            panel="panel10"
            expanded={expanded}
            handleChange={handleChange}
          >
            <p>
              There is no change to the current Yellow Quotes service – all of
              the existing functions still apply, and you will still receive
              email/SMS notification when a new Opportunity is created. The
              Quotes Dashboard simply provides a centralised view of all Quote
              Opportunities for your Account, plus an easier way to view Quote
              details and contact the customer if you’re interested.
            </p>
          </AccordionItem>

          <AccordionItem
            title="What’s the difference between a Direct and a Shared Quote Opportunity?"
            panel="panel11"
            expanded={expanded}
            handleChange={handleChange}
          >
            <p>
              Quote Opportunities on Yellow come in two forms. Those that are
              requested directly via your Listing on yellowpages.com.au
              (“Direct”), and those that are distributed between similar
              businesses (“Shared”). “Direct” Quote Opportunities are only
              visible to you. Although Direct Opportunities do not expire, we
              encourage you to respond to them as soon as possible to ensure you
              don’t miss out on a potential customer. “Shared” quote
              Opportunities are distributed between multiple businesses that
              perform a particular service in a particular location. Shared
              Opportunities have a 24 hour expiry (longer on weekends and public
              holidays), so it’s important that you view and respond within that
              timeframe to ensure you don’t miss out on potential work.
            </p>
          </AccordionItem>

          <AccordionItem
            title="Does the Expiry countdown keep going after I’ve clicked “Interested” on a Quote Opportunity?"
            panel="panel12"
            expanded={expanded}
            handleChange={handleChange}
          >
            <p>
              No – the clock stops as soon as you’ve marked a Quote Opportunity
              as “Interested. However, it is still a good idea to respond to the
              customer as quickly as possible – this may increase your chances
              of winning the job.
            </p>
          </AccordionItem>

          <AccordionItem
            title="What does the “Related Listing” on a Quote Opportunity mean?"
            panel="panel13"
            expanded={expanded}
            handleChange={handleChange}
          >
            <p>
              This is the Business Name, Location and Postcode of your
              yellowpages.com.au listing that was selected for this Quote
              Opportunity. Your listing was selected because the Category and
              Location is a good match for the Quote that the customer is
              requesting.
            </p>
          </AccordionItem>

          <AccordionItem
            title="What does the “Expires in” counter on a Quote Opportunity mean?"
            panel="panel14"
            expanded={expanded}
            handleChange={handleChange}
          >
            <p>
              If a Quote Opportunity has been distributed across multiple
              businesses, you have a set time limit in which to respond to the
              customer. The Expires in field shows the amount of time you have
              left to respond, before the Quote Opportunity expires. These
              Shared Quote Opportunities have a 24 hour expiry (longer on
              weekends), so it’s important that you view and respond to these
              within that timeframe to ensure you don’t miss out on a potential
              customer.
            </p>
          </AccordionItem>

          <AccordionItem
            title="When a Quote Opportunity has Expired, can I still see the contact details for the customer?"
            panel="panel15"
            expanded={expanded}
            handleChange={handleChange}
          >
            <p>
              No – once a Quote Opportunity has Expired, you won’t be able to
              respond to the customer or view their contact details. It’s best
              to regularly check your Quotes Dashboard in myYellow, and keep an
              eye out for email and SMS notifications, so that you don’t miss
              out on new Quote Opportunities.
            </p>
          </AccordionItem>

          <AccordionItem
            title="What does the “Received” date on a Quote Opportunity mean?"
            panel="panel16"
            expanded={expanded}
            handleChange={handleChange}
          >
            <p>
              This is the date and time when the customer lodged the quote
              Opportunity, in Australian Eastern Standard Time.
            </p>
          </AccordionItem>

          <AccordionItem
            title="What does the Accepted date on a Quote Opportunity mean?"
            panel="panel17"
            expanded={expanded}
            handleChange={handleChange}
          >
            <p>
              This is the date and time when you said you were Interested in the
              quote Opportunity, in Australian Eastern Standard Time.
            </p>
          </AccordionItem>

          <AccordionItem
            title="There are some Quote Opportunities on my Dashboard that I’ve never seen before - why?"
            panel="panel18"
            expanded={expanded}
            handleChange={handleChange}
          >
            <p>
              The Quote Opportunity notification emails are sent to the email
              address and mobile number published on your yellowpages.com.au
              Listing. If you have missed seeing some of these opportunities,
              check your Listings and make sure that the contact points
              published are correct.
            </p>
          </AccordionItem>

          <AccordionItem
            title="How can I get more Quote Opportunities"
            panel="panel19"
            expanded={expanded}
            handleChange={handleChange}
          >
            <p>
              For your yellowpages.com.au Listing in an eligible Category to
              receive a Quote Opportunity, it must be published with a valid
              email address. Check your Listings to make sure they all have
              email addresses, to make them candidates for Quotes.
            </p>
          </AccordionItem>

          <AccordionItem
            title="How can I stop receiving Quote Opportunities?"
            panel="panel20"
            expanded={expanded}
            handleChange={handleChange}
          >
            <p>
              If you want to opt out of receiving Quote Opportunities, go to the
              Awaiting Response Tab of your Quotes Dashboard. Click on any row
              to open the Details page for a Quote Opportunity, and select “Not
              Interested”. In the Reason list, select “I don’t want more
              Opportunities” and click the Continue button. This will let our
              Quotes system know to remove the Quote button from your
              yellowpages.com.au listings, and not send you any more Quote
              Opportunities.
            </p>
          </AccordionItem>

          <AccordionItem
            title="What is the Quotes Dashboard?"
            panel="panel21"
            expanded={expanded}
            handleChange={handleChange}
          >
            <p>
              The Quotes Dashboard provides a consolidated view of all Quote
              Opportunities for the yellowpages.com.au Listings associated to
              your Account. The Dashboard is a handy, central place to view and
              respond to all available Quote Opportunities at once, and then
              manage the ones that you are interested.
            </p>
          </AccordionItem>

          <AccordionItem
            title="Why don’t I have a Messages Dashboard on myYellow?"
            panel="panel22"
            expanded={expanded}
            handleChange={handleChange}
          >
            <p>
              This feature is only visible to Accounts who are eligible to
              receive Messages from yellowpages.com.au. To be eligible, your
              yellowpages.com.au Listings must be in a Category and tier for
              which Messages are allowed, and you must have a valid email
              address published on your Listings.
            </p>
          </AccordionItem>

          <AccordionItem
            title="There are some Messages on my Dashboard that I’ve never seen before - why?"
            panel="panel23"
            expanded={expanded}
            handleChange={handleChange}
          >
            <p>
              The Message notification emails are sent to the email address
              published on your yellowpages.com.au Listing. If you have missed
              seeing some of these opportunities, check your Listings and make
              sure that the contact points published are correct.
            </p>
          </AccordionItem>

          <AccordionItem
            title="How can I get more Messages?"
            panel="panel24"
            expanded={expanded}
            handleChange={handleChange}
          >
            <p>
              For your yellowpages.com.au Listing in an eligible Category to
              receive a Message, it must be published with valid email address.
              Check your Listings to make sure they are all have email addresses
              to make them eligible for Messages.
            </p>
          </AccordionItem>

          <AccordionItem
            title="How can I see my User Profile details?"
            panel="panel25"
            expanded={expanded}
            handleChange={handleChange}
          >
            <p>
              Click on the drop-down arrow next to your name in the myYellow
              Header, and select “My Profile”. You’ll now be able to check your
              current details. You cannot edit any of these details from
              myYellow just yet – however, if you click through to myAccount and
              select “My Profile” from the myAccount header, you will be able to
              submit your changes.
            </p>
          </AccordionItem>

          <AccordionItem
            title="How can I change my password?"
            panel="panel26"
            expanded={expanded}
            handleChange={handleChange}
          >
            <p>
              If you would like to change the password you use to access
              myYellow, click the drop-down next to your name in the myYellow
              Header, and select “Update my password”.
            </p>
          </AccordionItem>

          <AccordionItem
            title="How can I change my secret question?"
            panel="panel27"
            expanded={expanded}
            handleChange={handleChange}
          >
            <p>
              If you would like to change the secret security question for your
              myYellow profile, click the drop-down next to your name in the
              myYellow Header, and select “Update my secret question”.
            </p>
          </AccordionItem>

          <AccordionItem
            title="How can I give feedback on myYellow?"
            panel="panel28"
            expanded={expanded}
            handleChange={handleChange}
          >
            <p>
              We would love to hear what you think of myYellow and its features,
              and welcome your input so that we can continue to expand and
              improve the platform. To give us your views, please click the
              Feedback link in the myYellow Header to provide your thoughts.
            </p>
          </AccordionItem>
        </Box>
        <Box
          mui
          display="flex"
          flex={{ xs: "1 100%", md: "1 30%" }}
          flexDirection="column"
          height="max-content"
          pl={{ md: 2 }}
          pt={{ xs: "1rem", md: "0" }}
          pb={{ xs: "1rem", md: "0" }}
          mt={{ xs: "1rem", md: "0" }}
        >
          <Card>
            <CardContent>
              <Typography variant="h3">
                Can&apos;t find what you&apos;re looking for?
              </Typography>
              <br />
              <Typography color="secondary">
                Give us a call on <b>1800 888 091</b>, and we&apos;ll get you
                sorted.
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </MainContent>
  );
};

export default Help;
