import Label from "./Label";

export function formatType(type) {
  switch (type) {
    case "direct":
      return <Label className="quote-type">DIRECT</Label>;
    case "shared":
      return (
        <Label basic className="quote-type border-grey border-1 bc-white">
          SHARED
        </Label>
      );
    default:
      throw Error(`Invalid type: ${type}`);
  }
}

export function statusText(status, responsiveSize) {
  switch (status) {
    case "awaiting_response":
      return responsiveSize === "mobile" ? "Awaiting" : "Awaiting Response";
    case "interested":
      return "Interested";
    case "not_interested":
      return "Not Interested";
    case "expired":
      return "Expired";
    default:
      throw Error(`Invalid status: ${status}`);
  }
}

export function formatStatus(status) {
  const text = statusText(status).toUpperCase();

  switch (status) {
    case "awaiting_response":
      return <Label color="blue">{text}</Label>;
    case "interested":
      return <Label color="teal">{text}</Label>;
    case "not_interested":
    case "expired":
      return <Label color="red">{text}</Label>;
    /* istanbul ignore next */
    default:
      throw Error(`Invalid status: ${status}`);
  }
}
