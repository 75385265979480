import { useState, useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";
import useLocalStorageState from "../hooks/useLocalStorageState";
import OktaSignInWidget from "./OktaSignInWidget";
import { useOktaAuth } from "../../OktaFacade";

const Login = ({ baseUrl }) => {
  const { authState, authService } = useOktaAuth();
  const [authenticated, setAuthenticated] = useState(null);
  const query = new URLSearchParams(useLocation().search);
  const redirectUrl = query.get("fromURI");
  const [, setUberallRedirectUrl] = useLocalStorageState("uberallRedirectUrl");

  useEffect(() => {
    if (authState.isAuthenticated) {
      setAuthenticated(true);
    }
  }, [authState, authService]); // Update if authState changes

  const onSuccess = (res) => {
    if (res.status === "SUCCESS") {
      const sessionToken = res.session.token;
      if (redirectUrl) {
        setUberallRedirectUrl(redirectUrl);
      }
      return authService.redirect({ sessionToken });
    }

    // The user can be in another authentication state that requires further action.
    // For more information about these states, see:
    //   https://github.com/okta/okta-signin-widget#rendereloptions-success-error
    console.error("Invalid login response status: ", res.status);
    return undefined;
  };

  const onError = (err) => {
    console.error("error logging in", err);
  };

  return authenticated ? (
    <Redirect to={{ pathname: "/" }} />
  ) : (
    <OktaSignInWidget
      baseUrl={baseUrl}
      onSuccess={onSuccess}
      onError={onError}
    />
  );
};
export default Login;
