const data = {
  totalSubscriptionsInQuote: null,
  totalContractValue: null,
  tier: null,
  termsAccepted: null,
  suppressAutoEmail: null,
  subscriptionSequenceNo: null,
  subscriptionId: null,
  state: "sale-complete",
  sessionId: null,
  serviceLevelDiscountType: null,
  serviceLevel: null,
  productDetails: {
    productType: "Yellow Online",
    productSelectionQuestions: {
      standOut: false,
      attractNewCustomers: false,
      foundOnline: false,
    },
    productSelection: [
      {
        totalRecurringPrice: 0,
        totalRackRateRecurringPrice: 0,
        totalOneoffPrice: 0,
        totalDiscountedRecurringPrice: 0,
        setupUnitPrice: 0,
        setupQuantity: 0,
        setupPrice: 0,
        serviceUnitPrice: 0,
        serviceQuantity: 0,
        servicePrice: 0,
        serviceLevel: "SL3",
        recurringPrice: 0,
        rackRateLocationPrice: 0,
        onceOffPrice: 0,
        locationUnitPrice: 0,
        locationQuantity: 0,
        locationPrice: 0,
        locationDiscount: 0,
        isSelected: false,
        isRecommended: false,
        discountedLocationPrice: 0,
      },
      {
        totalRecurringPrice: 0,
        totalRackRateRecurringPrice: 0,
        totalOneoffPrice: 0,
        totalDiscountedRecurringPrice: 0,
        setupUnitPrice: 0,
        setupQuantity: 0,
        setupPrice: 0,
        serviceUnitPrice: 0,
        serviceQuantity: 0,
        servicePrice: 0,
        serviceLevel: "SL0",
        recurringPrice: 0,
        rackRateLocationPrice: 0,
        onceOffPrice: 0,
        locationUnitPrice: 0,
        locationQuantity: 0,
        locationPrice: 0,
        locationDiscount: 0,
        isSelected: true,
        isRecommended: false,
        discountedLocationPrice: 0,
      },
      {
        totalRecurringPrice: 0,
        totalRackRateRecurringPrice: 0,
        totalOneoffPrice: 0,
        totalDiscountedRecurringPrice: 0,
        setupUnitPrice: 0,
        setupQuantity: 0,
        setupPrice: 0,
        serviceUnitPrice: 0,
        serviceQuantity: 0,
        servicePrice: 0,
        serviceLevel: "SL1",
        recurringPrice: 0,
        rackRateLocationPrice: 0,
        onceOffPrice: 0,
        locationUnitPrice: 0,
        locationQuantity: 0,
        locationPrice: 0,
        locationDiscount: 0,
        isSelected: false,
        isRecommended: false,
        discountedLocationPrice: 0,
      },
      {
        totalRecurringPrice: 0,
        totalRackRateRecurringPrice: 0,
        totalOneoffPrice: 0,
        totalDiscountedRecurringPrice: 0,
        setupUnitPrice: 0,
        setupQuantity: 0,
        setupPrice: 0,
        serviceUnitPrice: 0,
        serviceQuantity: 0,
        servicePrice: 0,
        serviceLevel: "SL2",
        recurringPrice: 0,
        rackRateLocationPrice: 0,
        onceOffPrice: 0,
        locationUnitPrice: 0,
        locationQuantity: 0,
        locationPrice: 0,
        locationDiscount: 0,
        isSelected: false,
        isRecommended: false,
        discountedLocationPrice: 0,
      },
      {
        totalRecurringPrice: 0,
        totalRackRateRecurringPrice: 0,
        totalOneoffPrice: 0,
        totalDiscountedRecurringPrice: 0,
        setupUnitPrice: 0,
        setupQuantity: 0,
        setupPrice: 0,
        serviceUnitPrice: 0,
        serviceQuantity: 0,
        servicePrice: 0,
        serviceLevel: "SL4",
        recurringPrice: 0,
        rackRateLocationPrice: 0,
        onceOffPrice: 0,
        locationUnitPrice: 0,
        locationQuantity: 0,
        locationPrice: 0,
        locationDiscount: 0,
        isSelected: false,
        isRecommended: false,
        discountedLocationPrice: 0,
      },
    ],
  },
  presenceList: null,
  pocketOfficeType: null,
  PLIs: null,
  paymentDetails: {
    paymentType: null,
    paymentMethodId: "",
    creditCardAuthorize: null,
    businessTelephoneNumbers: null,
  },
  owner: null,
  otherInstructions: null,
  optOutForContentCollection: null,
  opportunityName: null,
  nonStdCharges: null,
  mediaType: null,
  listingDetails: {
    Name: "Burger Shop",
    listings: [
      {
        websiteEntered: null,
        website: "",
        suburb: "",
        subpremise: "",
        streetType: "",
        streetSuffix: "",
        streetNumber: "",
        streetName: "",
        street: "",
        state: "",
        searchResultId: null,
        publishAddress: "",
        postcode: "",
        phone: "",
        longitude: "",
        latitude: "",
        isPrimary: true,
        formattedAddress: "",
        email: null,
        country: "",
        buildingName: "",
      },
    ],
    categories: [
      {
        isPrimary: true,
        categoryName: "",
        categoryCode: "",
        additionalAreas: "",
      },
    ],
    areas: [],
    additionalListingRequested: false,
    additionalCategoryRequested: false,
    additionalAreaRequested: false,
  },
  listedName: null,
  issue: null,
  isForGifting: null,
  fulfilmentInstructions: null,
  eligibilityDetails: {
    resultMessage: null,
    resultCode: "UNKNOWN",
    offerExpiryDate: null,
    isEligible: true,
    ineligibleReasons: {
      notpubliclyAvailable: false,
      isWrittenOff: false,
      hasPaidHistory: false,
      hasOpenOpportunity: false,
    },
    hasMatchingABN: false,
    contactId: null,
    billingAccountId: null,
    accountId: null,
  },
  dirId: null,
  directoryAreaCode: null,
  customerNumber: null,
  contentGroup: null,
  contactDetails: {
    signatory: null,
    preferredContactMethod: null,
    phone: "",
    mobile: null,
    lastName: "",
    firstName: "",
    email: "",
    dataChanged: null,
    activated: null,
  },
  contact: null,
  cerbQuoteId: null,
  cerbQuote: null,
  cerbConfiguration: null,
  campaign: null,
  businessDetails: {
    suburb: "",
    subpremise: "",
    streetType: "",
    streetSuffix: "",
    streetNumber: "",
    streetName: "",
    street: "",
    state: "",
    searchResultId: null,
    postcode: "",
    name: "",
    longitude: "",
    latitude: "",
    formattedAddress: "",
    country: "",
    buildingName: "",
    abn: "",
  },
  billingTN: null,
  billingTerms: null,
  billingFrequency: null,
  action: null,
};

export default data;
