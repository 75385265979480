import { useSelector } from "react-redux";
import { Box, Typography } from "sensis-material-ui";
import { Divider } from "@material-ui/core";
import { openInNewTab } from "./UrlUtils";

export default function Footer() {
  const config = useSelector((state) => state.config);
  return (
    <Box pt={1} mt={2}>
      <Divider />
      <Box
        display="flex"
        flexWrap="wrap"
        flexDirection="row"
        mui
        mx="auto"
        width="100%"
      >
        <Box
          mui
          p={1}
          display="flex"
          flex={{ sm: "1 50%", md: "0 auto", lg: "0 15%" }}
          onClick={() => {
            openInNewTab(`${config.thryvUrlPrivacy}`);
          }}
          textAlign="left"
        >
          <Typography variant="body1">Privacy Policy</Typography>
        </Box>

        <Box
          mui
          p={1}
          display="flex"
          flex={{ sm: "1 50%", md: "0 auto", lg: "0 15%" }}
          onClick={() => {
            openInNewTab(`${config.thryvUrl}/terms-of-use`);
          }}
        >
          <Typography variant="body1">Terms of Use</Typography>
        </Box>

        <Box mui p={1} display="flex" flex={{ sm: "2 0 100%" }}>
          <Typography
            variant="body1"
            component={Box}
            width="100%"
            mui
            textAlign={{ xs: "left", md: "right" }}
          >
            Yellow Pages and its logo are trade marks of Thryv Australia Pty
            Ltd. © 2021 Thryv Australia Pty Ltd. All rights reserved.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
