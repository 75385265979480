import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import { Box } from "sensis-material-ui";
import {
  Badge,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText as ListItemTextMUI,
} from "@material-ui/core";
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import HomeIcon from "@material-ui/icons/Home";
import MyAccountIcon from "@material-ui/icons/Equalizer";
import QuotesIcon from "@material-ui/icons/AssignmentOutlined";
import ListIcon from "@material-ui/icons/List";
import PocketOfiiceIcon from "@material-ui/icons/DateRange";
import CloseIcon from "@material-ui/icons/Close";
import BillingIcon from "@material-ui/icons/Payment";
import YellowConnectIcon from "@material-ui/icons/OfflineBolt";
import { withOktaAuth } from "../../OktaFacade";
import { openInNewTab } from "./UrlUtils";
import { dtmEvent, eventNames } from "./AdobeDTMUtils";
import logo from "../../images/logo-menu.svg";
import { withGatewayApi } from "../../API";
import { getQuotesCount, getQuotesIsEligible } from "../../redux/Actions";
import OpenPocketOffice from "../auth/OpenPocketOffice";
import FeatureToggle from "./FeatureToggle";

const Menu = ({ gatewayApi, onToggle }) => {
  const user = useSelector((state) => state.session.user);
  let quotes = useSelector((state) => state.quotes);
  const config = useSelector((state) => state.config);

  const pocketOffice = useSelector((state) => state.pocketOffice);
  const isPublicRoute = useSelector((state) => state.publicRoute.isPublicRoute);
  const yellowConnect = useSelector((state) => state.yellowConnect);
  const dispatch = useDispatch();
  const feedbackLink = `${config.feedbackLink}?accountId=${user?.contact?.accounts?.[0]?.accountId}&contactId=${user?.contact?.contactId}`;

  const { white, blue100 } = useTheme().custom.colour;
  const { badge } = makeStyles({
    badge: {
      "& span": {
        backgroundColor: blue100,
        color: white,
      },
    },
  })();

  function getPageLocation() {
    // eslint-disable-next-line no-restricted-globals
    const locationPath = location.pathname;
    if (RegExp("/quotes*").test(locationPath)) {
      return "quotes";
    }
    if (RegExp("/messages*").test(locationPath)) {
      return "messages";
    }

    return locationPath.substring(1, locationPath.length);
  }

  const [isSelected, setIsSelected] = useState(getPageLocation());

  const loadCount = async () => {
    if (user) {
      quotes = await gatewayApi.get(
        `/customer/${user.contact.customerNumber}/quotes?detail=count`,
        /* istanbul ignore next */ () => {
          console.warn("API Gateway error: unable to retrieve quotes count");
        }
      );

      if (quotes) {
        dispatch(getQuotesCount(quotes.data));
        dispatch(getQuotesIsEligible(quotes.data));
      }
    }
  };

  const changeActiveMenu = (selected) => {
    loadCount();
    setIsSelected(selected);
  };

  const StyledListItem = withStyles({
    root: {
      height: "64px",

      "&:hover": {
        fontWeight: "bold",
        color: "black",
      },
    },
  })(ListItem);

  const StyledListItemText = withStyles({
    root: {
      "&:hover": {
        fontWieght: "bold",
      },
    },
  })(ListItemTextMUI);

  const ListItemText = ({ children }) => (
    <StyledListItemText disableTypography>{children}</StyledListItemText>
  );

  const toggleSideBar = () => {
    if (onToggle) {
      onToggle();
    }
  };

  return (
    <Box component="nav" width={260} p={0} aria-label="Sidebar Menu">
      <List component={Box} p={0}>
        <ListItem
          className="yp-logo"
          component={Box}
          display={{ xs: "none", md: "flex" }}
          height={60}
          justifyContent="center"
          alignItems="center"
        >
          <Box
            mui
            position="absolute"
            left={0}
            display={{ xs: "block", md: "none" }}
          >
            <IconButton onClick={onToggle}>
              <CloseIcon />
            </IconButton>
          </Box>
          <img src={logo} alt="Yellow myYellow" width={150} />
        </ListItem>

        <Divider component={Box} mb={13} />

        <StyledListItem
          button
          component={NavLink}
          to={isPublicRoute ? "/login" : "/"}
          onClick={() => changeActiveMenu("home")}
          selected={isSelected === "home"}
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText disableTypography>Home</ListItemText>
        </StyledListItem>

        {user && !isPublicRoute && (
          <>
            {yellowConnect.status && (
              <StyledListItem
                button
                component="a"
                href={`${config.uberall}/yc`}
              >
                <ListItemIcon>
                  <YellowConnectIcon />
                </ListItemIcon>
                <ListItemText>Yellow Connect</ListItemText>
              </StyledListItem>
            )}

            {quotes.isEligible && (
              <StyledListItem
                component={NavLink}
                to="/quotes/awaiting-response"
                button
                onClick={() => {
                  dtmEvent(eventNames.MY_quote_menu_click, user.contact);
                  changeActiveMenu("quotes");
                  toggleSideBar();
                }}
                selected={isSelected === "quotes"}
              >
                <ListItemIcon>
                  <QuotesIcon />
                </ListItemIcon>
                <ListItemText>Quotes</ListItemText>
                {quotes.awaiting_response > 0 && (
                  <Badge
                    color="primary"
                    badgeContent={quotes.awaiting_response}
                    component={Box}
                    mr={1}
                    className={badge}
                  />
                )}
              </StyledListItem>
            )}

            {pocketOffice.status === "live" && (
              <OpenPocketOffice
                learnMorePOTabUrl={config.learnMorePOTabUrl}
                cid={user.contact.contactId}
                dtmEventId={eventNames.MY_PO_menu_click}
              >
                <StyledListItem button component="a">
                  <ListItemIcon>
                    <PocketOfiiceIcon />
                  </ListItemIcon>
                  <ListItemText>Pocket Office</ListItemText>
                </StyledListItem>
              </OpenPocketOffice>
            )}

            <StyledListItem
              button
              component="a"
              onClick={() => {
                dtmEvent(eventNames.MY_myaccount_menu_click, user.contact);
                changeActiveMenu("myAccount");
                openInNewTab(config.myAccountUrl);
              }}
              selected={isSelected === "myAccount"}
            >
              <ListItemIcon>
                <MyAccountIcon />
              </ListItemIcon>
              <ListItemText>myAccount</ListItemText>
            </StyledListItem>

            <FeatureToggle name="featureMyAccountDashboard">
              <StyledListItem
                button
                component={NavLink}
                to="/myAccountDashboard"
                onClick={() => {
                  changeActiveMenu("myAccountDashboard");
                }}
                selected={isSelected === "myAccountDashboard"}
              >
                <ListItemIcon>
                  <MyAccountIcon />
                </ListItemIcon>
                <ListItemText>myAccount Dashboard</ListItemText>
              </StyledListItem>
            </FeatureToggle>

            <FeatureToggle name="featureProductList">
              <StyledListItem button component={NavLink} to="/product-list">
                <ListItemIcon>
                  <ListIcon />
                </ListItemIcon>
                <ListItemText>Product List</ListItemText>
              </StyledListItem>
            </FeatureToggle>

            <FeatureToggle name="featureBilling">
              <StyledListItem button component={NavLink} to="/bills">
                <ListItemIcon>
                  <BillingIcon />
                </ListItemIcon>
                <ListItemText>Billing</ListItemText>
              </StyledListItem>
            </FeatureToggle>

            <StyledListItem
              className="divider"
              component={Box}
              display={{ md: "none" }}
            >
              <Divider />
            </StyledListItem>

            <Box mui display={{ md: "none" }}>
              <StyledListItem
                button
                component="a"
                onClick={() => {
                  openInNewTab(feedbackLink);
                  toggleSideBar();
                }}
              >
                Feedback
              </StyledListItem>

              <StyledListItem
                button
                component={NavLink}
                to="/faq"
                onClick={() => {
                  changeActiveMenu("faq");
                  toggleSideBar();
                }}
                selected={isSelected === "faq"}
              >
                FAQ
              </StyledListItem>
            </Box>
          </>
        )}
      </List>
    </Box>
  );
};

export default withOktaAuth(withGatewayApi(withRouter(Menu)));
