import { theme as commonTheme } from "sensis-material-ui";

const theme = { ...commonTheme };

// eslint-disable-next-line prettier/prettier
const {
  "&:hover": hover,
  ...buttonRootStyles
} = theme.overrides.MuiButton.root;

theme.overrides.MuiButton.root = {
  ...buttonRootStyles,
  "&:not(.MuiButton-outlined):hover": hover,
};

theme.overrides.MuiButton.contained = {
  boxShadow: "none",
  "&:hover": {
    boxShadow: "none",
    color: "initial",
  },
};

theme.overrides.MuiFormControlLabel = {
  label: {
    fontSize: 14,
    paddingBottom: 0,
  },
};

theme.overrides.MuiGrid = {
  item: {
    padding: 14,
  },
};

theme.typography = {
  ...theme.typography,
  fontFamily: "Archivo, Arial, sans-serif",
};

theme.typography.body1 = {
  ...theme.typography.body1,
  fontFamily: "Archivo, Arial, sans-serif",
};

theme.typography.body2 = {
  ...theme.typography.body2,
  fontFamily: "Archivo, Arial, sans-serif",
};

theme.typography.button = {
  ...theme.typography.button,
  fontFamily: "Archivo, Arial, sans-serif",
};

theme.typography.caption = {
  ...theme.typography.caption,
  fontFamily: "Archivo, Arial, sans-serif",
};

theme.typography.h1 = {
  ...theme.typography.h1,
  fontFamily: "Archivo, Arial, sans-serif",
};

theme.typography.h2 = {
  ...theme.typography.h2,
  fontFamily: "Archivo, Arial, sans-serif",
};

theme.typography.h3 = {
  ...theme.typography.h3,
  fontFamily: "Archivo, Arial, sans-serif",
};

theme.typography.h4 = {
  ...theme.typography.h4,
  fontFamily: "Archivo, Arial, sans-serif",
};

theme.typography.h5 = {
  ...theme.typography.h5,
  fontFamily: "Archivo, Arial, sans-serif",
};

theme.typography.h6 = {
  ...theme.typography.h6,
  fontFamily: "Archivo, Arial, sans-serif",
};

theme.typography.subtitle1 = {
  ...theme.typography.subtitle1,
  fontFamily: "Archivo, Arial, sans-serif",
};

theme.typography.subtitle2 = {
  ...theme.typography.subtitle2,
  fontFamily: "Archivo, Arial, sans-serif",
};

export const { colour } = theme.custom;

export default theme;
