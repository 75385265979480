// eslint-disable-next-line import/prefer-default-export
export const ADDRESS_STREET_TYPES = {
  accs: "access",
  al: "alley",
  ally: "alley",
  alwy: "alleyway",
  amb: "amble",
  ambl: "amble",
  app: "approach",
  appr: "approach",
  arc: "arcade",
  art: "arterial",
  artl: "arterial",
  arty: "artery",
  av: "avenue",
  ave: "avenue",
  bwlk: "boardwalk",
  bvd: "boulevard",
  bwl: "bowl",
  br: "brace",
  brk: "break",
  byp: "bypass",
  bypa: "bypass",
  bywy: "byway",
  cswy: "causeway",
  ctr: "centre",
  ch: "chase",
  cha: "chase",
  ccl: "circle",
  cir: "circle",
  cct: "circuit",
  crcs: "circus",
  ccs: "circus",
  cl: "close",
  com: "common",
  con: "concourse",
  cps: "copse",
  cnr: "corner",
  crn: "corner",
  crse: "course",
  ct: "court",
  ctyd: "courtyard",
  cv: "cove",
  cr: "crescent",
  cres: "crescent",
  crst: "crest",
  crss: "cross",
  cros: "cross",
  csac: "cul-de-sac",
  cds: "cul-de-sac",
  cutt: "cutting",
  cry: "cruiseway",
  div: "divide",
  dom: "domain",
  dr: "drive",
  dvwy: "driveway",
  edg: "edge",
  elb: "elbow",
  ent: "entrance",
  esp: "esplanade",
  exp: "expressway",
  extn: "extension",
  fawy: "fairway",
  flts: "flats",
  folw: "follow",
  ftwy: "footway",
  form: "formation",
  fwy: "freeway",
  frtg: "frontage",
  gdns: "gardens",
  gdn: "gardens",
  gte: "gate",
  gwy: "gateway",
  glde: "glade",
  gla: "glade",
  gln: "glen",
  gra: "grange",
  grn: "green",
  gr: "grove",
  gv: "grove",
  gly: "gully",
  hth: "heath",
  hts: "heights",
  hird: "highroad",
  hwy: "highway",
  hlw: "hollow",
  intg: "interchange",
  id: "island",
  jnc: "junction",
  jct: "junction",
  la: "lane",
  lnwy: "laneway",
  lwy: "laneway",
  lnk: "link",
  lkt: "lookout",
  lwr: "lower",
  mndr: "meander",
  mws: "mews",
  mtwy: "motorway",
  nth: "north",
  otlk: "outlook",
  out: "outlook",
  pd: "parade",
  pde: "parade",
  pkw: "parkway",
  pwy: "parkway",
  ps: "pass",
  psg: "passage",
  psge: "passage",
  pway: "pathway",
  piaz: "piazza",
  pl: "place",
  plz: "plaza",
  plza: "plaza",
  pkt: "pocket",
  pnt: "point",
  pt: "point",
  prom: "promenade",
  prm: "promenade",
  pur: "pursuit",
  qd: "quadrant",
  qdrt: "quadrant",
  qu: "quay",
  qys: "quays",
  rmbl: "ramble",
  rch: "reach",
  rtt: "retreat",
  ret: "retreat",
  rtn: "return",
  rdg: "ridge",
  rdge: "ridge",
  rsg: "rising",
  rd: "road",
  rty: "rotary",
  rnd: "round",
  rte: "route",
  svwy: "serviceway",
  shun: "shunt",
  slo: "slope",
  sth: "south",
  sq: "square",
  stps: "steps",
  st: "street",
  stp: "strip",
  sbwy: "subway",
  sub: "subway",
  tce: "terrace",
  thfr: "thoroughfare",
  thru: "throughway",
  tlwy: "tollway",
  twy: "tollway",
  trk: "track",
  trl: "trail",
  upas: "underpass",
  upr: "upper",
  vly: "valley",
  viad: "viaduct",
  vw: "view",
  vst: "vista",
  vsta: "vista",
  wk: "walk",
  wkwy: "walkway",
  wtrs: "waters",
  wry: "waterway",
  whrf: "wharf",
  wd: "wood",
  wyd: "wynd",
};
