import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

export function useBreakpointOnly(size) {
  return useMediaQuery(useTheme().breakpoints.only(size));
}

export function useBreakpointUp(size) {
  return useMediaQuery(useTheme().breakpoints.up(size));
}

export function useBreakpointDown(size) {
  return useMediaQuery(useTheme().breakpoints.down(size));
}
