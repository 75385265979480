import { cloneElement, useState } from "react";
import { Box, Button, Typography } from "sensis-material-ui";
import { Dialog as DailogMUI, Divider, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useBreakpointDown } from "./MediaQueryUtil";

export function DialogState() {
  const [isOpen, setOpen] = useState(false);

  const dialogState = {
    isOpen,
    open: () => setOpen(true),
    close: () => setOpen(false),
  };

  return dialogState;
}

export function withDialogState(WrappedComponent) {
  return function Wrapper(props) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <WrappedComponent {...props} dialogState={DialogState()} />;
  };
}

export default function Dialog({
  children,
  closeButton,
  closeIcon = true,
  header,
  trigger,
  updateStatus,
  onOpen = () => {},
  dialogState = DialogState(),
}) {
  const fullScreen = useBreakpointDown("xs");

  const openDialog = (e) => {
    // eslint-disable-next-line no-unused-expressions
    e && e.stopPropagation();
    // eslint-disable-next-line no-unused-expressions
    onOpen && onOpen();
    dialogState.open();
  };

  const closeDialog = (e) => {
    // eslint-disable-next-line no-unused-expressions
    /* istanbul ignore next */ e && e.stopPropagation();
    dialogState.close();
  };

  return (
    <>
      {cloneElement(trigger, { onClick: openDialog })}

      <DailogMUI
        onClick={(e) => e.stopPropagation()}
        fullScreen={fullScreen}
        open={dialogState.isOpen}
        maxWidth="sm"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent={header ? "space-between" : "flex-end"}
          height={64}
          pl={2}
        >
          {header && (
            <Box>
              <Typography variant="h2">{header}</Typography>
            </Box>
          )}
          {closeIcon && (
            <Box>
              <IconButton
                aria-label="close-icon"
                height={48}
                onClick={() => {
                  // eslint-disable-next-line no-unused-expressions
                  updateStatus && updateStatus();
                  closeDialog();
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )}
        </Box>

        {fullScreen && <Divider />}

        {children}

        {closeButton && (
          <Box textAlign="center" p={2}>
            <Button
              aria-label="close-button"
              size="small"
              variant="contained"
              width="100%"
              onClick={() => closeDialog()}
            >
              Close
            </Button>
          </Box>
        )}
      </DailogMUI>
    </>
  );
}
