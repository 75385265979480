import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Box, Toolbar } from "@material-ui/core";
import { alphaColour } from "../ColourUtils";

const HeaderBase = ({ location, children }) => {
  const isOnlineSignupPath = [
    "/online-signup",
    "/online-signup/",
    "/online-signup/success",
  ].includes(location.pathname);

  const { appBar, toolbar } = makeStyles((theme) => ({
    appBar: {
      ...(isOnlineSignupPath
        ? { backgroundColor: theme.custom.colour.yellow100 }
        : { backgroundImage: theme.custom.yellowGradient }),
      border: 0,
      boxShadow: `0 0 10px 0 ${alphaColour(theme.custom.colour.black, 0.15)}`,
      height: 60,
    },
    toolbar: {
      minHeight: 60,
    },
  }))();

  return (
    <AppBar className={appBar} position="static">
      <Toolbar
        component={Box}
        justifyContent="space-between"
        disableGutters
        className={toolbar}
      >
        {children}
      </Toolbar>
    </AppBar>
  );
};
export default HeaderBase;
