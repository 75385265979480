import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { useOktaAuth } from "../../OktaFacade";
import IdleTimer from "./IdleTimer";
import InitWithApi from "./InitWithApi";

const InitWithAuth = () => {
  const { authState, authService } = useOktaAuth();
  const isPublicRoute = useSelector((state) => state.publicRoute.isPublicRoute);
  const config = useSelector((state) => state.config);
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    if (authState.isAuthenticated) {
      setAuthenticated(true);
    }
  }, [authState, authService]);
  return !isPublicRoute && authenticated ? (
    <>
      <Helmet>
        <script type="text/javascript" src={config.oneTrustAutoBlock} />
        <script
          src={config.oneTrustSDKStub}
          type="text/javascript"
          charset="UTF-8"
          data-domain-script={config.oneTrustKey}
        />
        <script type="text/javascript">{`function OptanonWrapper() {}`}</script>
        <script async type="text/javascript" src={config.tempBanner} />
      </Helmet>
      <InitWithApi />
      <IdleTimer />
    </>
  ) : null;
};

export default InitWithAuth;
