import { combineReducers } from "redux";
import { SET_YELLOW_CONNECT_IS_ELIGIBLE } from "./Actions";

function isEligible(statusCounts) {
  return Object.entries(statusCounts).filter((item) => item[1] > 0).length > 0;
}

export const session = (state = {}, action) => {
  switch (action.type) {
    case "SET_SESSION_ACCESS_TOKEN":
      return {
        ...state,
        accessToken: action.data,
      };
    case "SET_SESSION_USER":
      return {
        ...state,
        user: action.data,
      };
    default:
      return state;
  }
};

export const quotes = (state = {}, action) => {
  switch (action.type) {
    case "GET_QUOTES_COUNT":
      return action.quotes;
    case "GET_QUOTES_IS_ELIGIBLE":
      return {
        ...state,
        isEligible: isEligible(action.quotes),
      };
    default:
      return state;
  }
};

export const pocketOffice = (state = {}, action) => {
  switch (action.type) {
    case "SET_POCKET_OFFICE_IS_ELIGIBLE":
      return action.pocketOffice;
    default:
      return state;
  }
};

export const loader = (state = { isLoading: true }, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return action.isLoading;
    default:
      return state;
  }
};

export const publicRoute = (state = {}, action) => {
  switch (action.type) {
    case "SET_PUBLIC_ROUTE":
      return action.publicRoute;
    default:
      return state;
  }
};

export const config = (state = {}, action) => {
  switch (action.type) {
    case "SET_CONFIG":
      return action.config;
    default:
      return state;
  }
};

export const yellowConnect = (state = { status: false }, action) => {
  switch (action.type) {
    case SET_YELLOW_CONNECT_IS_ELIGIBLE:
      return action.payload;
    default:
      return state;
  }
};

export const reducers = combineReducers({
  session,
  quotes,
  config,
  pocketOffice,
  loader,
  publicRoute,
  yellowConnect,
});

export default reducers;
