import { Box } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

export default function Label({ color, children, ...props }) {
  const { grey500 } = useTheme().custom.colour;

  const { basic, className } = props;

  const statusColor = () => {
    switch (color) {
      case "blue":
        return "info.main";
      case "yellow":
        return "warning.main";
      case "teal":
        return "success.main";
      case "red":
        return "secondary.main";
      default:
        return "text.secondary";
    }
  };

  const selectedColor = statusColor();

  if (className === "quote-type") {
    return (
      <Box
        border={1}
        borderRadius={4}
        borderColor={!basic ? grey500 : ""}
        bgcolor={!basic ? grey500 : "none"}
        color={selectedColor}
        px={1}
        py={0.5}
        display="inline-block"
        fontWeight={700}
        textAlign="center"
        width={78}
        component="span"
      >
        {children}
      </Box>
    );
  }

  return (
    <Box
      border={1}
      borderRadius={4}
      borderColor={selectedColor}
      bgcolor={color === "grey" ? selectedColor : "none"}
      color={selectedColor}
      px={1}
      py={0.5}
      display="inline-block"
      fontWeight={700}
      textAlign="center"
      component="span"
    >
      {children}
    </Box>
  );
}
