let config;

export default async function loadConfig() {
  if (!config) {
    // Custom chunk name to allow injecting environment config during deployment
    const module = await import(
      /* webpackChunkName: "config" */ "./config.json"
    );
    config = module.default;
  }

  return config;
}
