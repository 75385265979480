import { Box, Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { alphaColour } from "../ColourUtils";

const HeaderMenuLink = ({
  underline,
  href,
  to,
  openInNewTab,
  text,
  display,
  variant = "body2",
}) => {
  const { listItem } = makeStyles((theme) => ({
    listItem: {
      cursor: "pointer",
      color: theme.custom.colour.black,
      margin: 0,
      padding: 20,
      "&:hover": {
        background: `${alphaColour(theme.custom.colour.black, 3)}`,
        color: `${alphaColour(theme.custom.colour.black, 95)}`,
      },
      textDecorationLine: "none",
    },
  }))();

  const routerLink = () => {
    if (process.env.REACT_APP_IS_UBERALL_HEADER === "false") {
      // eslint-disable-next-line global-require
      const RouterLink = require("react-router-dom").Link;
      return RouterLink;
    }
    return null;
  };

  if (to) {
    return (
      <Box
        component={routerLink()}
        display={display}
        to={to}
        className={listItem}
      >
        <Typography component="span" variant={variant}>
          {text}
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      className={listItem}
      display={display}
      component={Link}
      href={href}
      target={openInNewTab ? "_blank" : null}
      underline={underline ? "always" : "none"}
      variant={variant}
    >
      {text}
    </Box>
  );
};

export default HeaderMenuLink;
