import { useEffect } from "react";
import { Box, Typography, Link } from "sensis-material-ui";
import { Container, Paper } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import successRegisterImg from "../../images/successImg.svg";
import setUpImg from "../../images/marketing_iqbr.png";
import { dtmSatelliteTrack } from "./AdobeDTMUtils";

export default function OnlineSignupConfirmationPage() {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    dtmSatelliteTrack("confirmation");
  }, []);

  useEffect(() => {
    if (!location?.state?.isCreated) {
      history.push("/online-signup");
    }
  }, [history, location]);

  return (
    <Container maxWidth="md">
      <Box mui component={Paper} p={4} align="center" my={4} py={8}>
        <Box>
          <img
            src={successRegisterImg}
            alt="Congratulations!"
            style={{ width: "27%" }}
          />
        </Box>
        <Typography py={1} variant="h1" maxWidth="sm" component={Box} mt={3}>
          Congratulations!
        </Typography>

        <Typography variant="subtitle1" component={Box} px={6}>
          Your free online business listing has now been submitted. You’ve made
          a great choice to join Australia’s no. 1 online business directory.
        </Typography>
        <Box mui p={2}>
          <Typography variant="body1" component={Box}>
            One more final step and your listing will be live on{" "}
            <Link href="https://www.yellow.com.au">
              <u>Yellow Pages</u>.
            </Link>
          </Typography>
          <Box mt="5">
            <img
              src={setUpImg}
              alt="Setup myYellow!"
              style={{ width: "28%" }}
            />
          </Box>
          <Typography py={1} variant="h1" maxWidth="sm" component={Box} mt={0}>
            Set up myYellow
          </Typography>
          <Box mui px={3}>
            <Typography variant="subtitle1">
              Shortly, we’ll send you an email to set up your password for
              myYellow. This is where you’ll have access to update your online
              listing including your contact details.
            </Typography>
            <Typography variant="body1" component={Box} mt={3}>
              <strong>Already have a myYellow account?</strong>
            </Typography>
            <Typography variant="body1">
              No problem, you can continue to access all your accounts with the
              same login details.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
