/* eslint-disable global-require */
/* eslint-disable react/destructuring-assignment */
import { lazy, Component, Suspense } from "react";
import { Box, Hidden } from "sensis-material-ui";
import { Drawer } from "@material-ui/core";
import { withRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import MyAccountDashBoard from "../auth/MyAccountDashboard";
import { setConfig } from "../../redux/Actions";
import { Security, SecureRoute, LoginCallback } from "../../OktaFacade";
import loadConfig from "../../Config";
import Login from "../public/Login";
import Home from "../auth/Home";
import InitWithAuth from "../auth/InitWithAuth";
import QuoteList from "../auth/QuoteList";
import Profile from "../auth/Profile";
import Header from "./Header/Header";
import Help from "../auth/Help";
import Footer from "./Footer";
import Menu from "./Menu";
import PublicRoute from "./PublicRoute";
import OnlineSignup from "./OnlineSignup";
import QuoteDetails from "./QuoteDetails";
import TermsOfUse from "./TermsOfUse";
import { dtmLocationEvent } from "./AdobeDTMUtils";
import { colour } from "../../theme";
import FeatureToggle from "./FeatureToggle";
import OnlineSignupConfirmationPage from "./OnlineSignupConfirmationPage";

const loginPath = "/login";
const callbackPath = "/implicit/callback";

function onAuthRequired() {
  window.location = "/login";
}

const TestDtmEvent = lazy(() => import("./TestDtmEvent"));

let Billing = null;
let PayBill = null;
let ChangePassword = null;
let ChangeSecurityQuestion = null;
let ProductList = null;

if (process.env.NODE_ENV !== "production") {
  Billing = require("../auth/Billing").default;
  PayBill = require("./PayBill").default;
  ChangePassword = require("../auth/ChangePassword").default;
  ChangeSecurityQuestion = require("../auth/ChangeSecurityQuestion").default;
  ProductList = require("../auth/ProductList").default;
}

function SidebarRoutes({ location }) {
  function validRoute() {
    if (![location.pathname].includes(callbackPath)) {
      throw Error(404);
    }
  }

  return (
    <>
      <Route component={InitWithAuth} />
      <Switch>
        <SecureRoute exact path="/" component={Home} />
        <SecureRoute exact path="/quotes/:status" component={QuoteList} />
        <SecureRoute
          exact
          path="/quote/:businessId/:leadId"
          component={QuoteDetails}
        />
        <SecureRoute exact path="/faq" component={Help} />
        <SecureRoute exact path="/profile" component={Profile} />
        {FeatureToggle({
          name: "featureChangePassword",
          children: (
            <SecureRoute
              exact
              path="/myAccountDashboard"
              component={MyAccountDashBoard}
            />
          ),
        })}

        {FeatureToggle({
          name: "featureBilling",
          children: <SecureRoute exact path="/bills" component={Billing} />,
        })}
        {FeatureToggle({
          name: "featureBilling",
          children: (
            <SecureRoute
              exact
              path="/bills/:action/:accountNumber/:amount"
              component={PayBill}
            />
          ),
        })}
        {FeatureToggle({
          name: "featureChangePassword",
          children: (
            <SecureRoute
              exact
              path="/change-password"
              component={ChangePassword}
            />
          ),
        })}
        {FeatureToggle({
          name: "featureChangeSecurityQuestion",
          children: (
            <SecureRoute
              exact
              path="/change-security-question"
              component={ChangeSecurityQuestion}
            />
          ),
        })}
        {FeatureToggle({
          name: "featureProductList",
          children: (
            <SecureRoute exact path="/product-list" component={ProductList} />
          ),
        })}

        <PublicRoute
          path={[
            "/quote/details/:quoteToken/:source",
            "/quote/details/:quoteToken/:source/:status",
          ]}
          component={QuoteDetails}
        />

        {FeatureToggle({
          name: "featureOnlineSignup",
          children: (
            <PublicRoute
              exact
              path="/online-signup/"
              component={OnlineSignup}
            />
          ),
        })}

        {FeatureToggle({
          name: "featureOnlineSignup",
          children: (
            <PublicRoute
              path="/online-signup/success"
              component={OnlineSignupConfirmationPage}
            />
          ),
        })}

        {FeatureToggle({
          name: "featureBilling",
          children: (
            <PublicRoute
              exact
              path="/pay-now/:accountNumber/:amount"
              component={PayBill}
            />
          ),
        })}

        <Suspense fallback={null}>
          <SecureRoute exact path="/test/dtm-event" component={TestDtmEvent} />
        </Suspense>
        <Route render={() => validRoute()} />
      </Switch>
    </>
  );
}

export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      config: null,
      isLoading: true,
      sidebarVisible: false,
    };
  }

  async componentDidMount() {
    const config = await loadConfig();
    if (config) {
      this.props.setConfig(config);

      // loads up newRelic token needs to be loaded before other scripts
      if (config.newRelic) {
        this.appendScript(config.newRelic);
      }

      this.appendScript(config.liveChatUrl);

      this.setState({
        config,
        isLoading: false,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.user && prevProps.location !== this.props.location) {
      dtmLocationEvent(
        prevProps.location,
        this.props.location,
        this.props.user.contact
      );
    }

    if (prevProps.user !== this.props.user) {
      dtmLocationEvent(null, this.props.location, this.props.user.contact);
    }
  }

  appendScript = (url) => {
    const script = document.createElement("script");
    script.type = "application/javascript";
    script.src = url;
    document.head.appendChild(script);
  };

  handleToggle = () => {
    this.setState((prevState) => ({
      sidebarVisible: !prevState.sidebarVisible,
    }));
  };

  sidebar() {
    const pathname = this.props.location;
    const isOnlineSignupPath = [
      "/online-signup",
      "/online-signup/",
      "/online-signup/success",
      "/online-signup/success/",
    ].includes(pathname.pathname);

    return (
      <Box display="flex" flex="1 1 100%" height="100vh">
        {!isOnlineSignupPath && (
          <>
            <Hidden smDown>
              <Drawer variant="permanent">
                <Menu />
              </Drawer>
            </Hidden>
            <Hidden mdUp>
              <Drawer
                open={this.state.sidebarVisible}
                anchor="left"
                onClose={this.handleToggle}
              >
                <Menu onToggle={this.handleToggle} />
              </Drawer>
            </Hidden>
          </>
        )}
        <Box
          mui
          ml={{ xs: 0, md: isOnlineSignupPath ? 0 : 32.5 }}
          flex="1 100%"
          bgcolor={colour.grey800}
          flexDirection="column"
          flexWrap="wrap"
        >
          <Header onToggle={this.handleToggle} />
          <SidebarRoutes location={pathname} />
          <Footer />
        </Box>
      </Box>
    );
  }

  render() {
    return (
      !this.state.isLoading && (
        <Security
          issuer={`${this.state.config.oktaHost}/oauth2/${this.state.config.oktaAuthServer}`}
          client_id={this.state.config.oktaClientId}
          redirect_uri={window.location.origin + callbackPath}
          onAuthRequired={onAuthRequired}
          pkce={false}
          cookies={{ secure: false }}
        >
          <Switch>
            <Route
              exact
              path={loginPath}
              render={() => <Login baseUrl={this.state.config.oktaHost} />}
            />
            <Route exact path={callbackPath} component={LoginCallback} />
            <Route exact path="/terms-of-use" component={TermsOfUse} />
            <Route render={() => this.sidebar()} />
          </Switch>
        </Security>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  config: state.config,
  user: state.session.user,
});

const mapDispatchToProps = {
  setConfig,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
